<!-- Pendiente Documentación -->

<template>
  <div class="frasPteDoc" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">
          <div class="columna">
                 
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div> 

            <v-sheet :elevation="4">
              <div class="contenedor">    

                <!-- Botoneras -->
                <div class="botonera">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>                
                </div>
          

                <!-- Controles del Mto -->                
                <div class="columna" style="width:750px;padding-top:10px">    
                  
                  <div style="width:100%">
                    <div class="cab">Expediente</div>
                    <v-sheet v-bind="$cfg.styles.marco"> 
                      <div class="columna">
                        <div class="conflex">
                          <uiText
                              style="width:45%" 
                              styleLabel="width:85px"
                              v-model="recordAux.control"
                              label="Control"
                              labelSide
                              readonly>
                          </uiText>

                          <uiText
                              style="width:55%"
                              styleLabel="width:85px"
                              v-model="recordAux.callref1"
                              label="Referencia"
                              labelSide
                              readonly>              
                          </uiText>
                        </div>

                        <uiText
                          styleLabel="width:85px"
                          v-model="recordAux.fallename"                                
                          label="Fallecido"
                          labelSide
                          readonly>              
                        </uiText>                             
                      </div>
                    </v-sheet> 
                  </div>                    
                    
                  <div style="width:100%;">
                    <div class="cab">Factura</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="columna">
                        <div class="conflex">
                          <uiText
                            style="width:20%;"
                            styleLabel="width:85px"
                            v-model="record.tipo_fra"
                            label="Tipo"
                            labelSide
                            readonly>              
                          </uiText>

                          <uiText
                            style="width:50%;"
                            styleLabel="width:85px"
                            v-model="record.fra_doc" 
                            label="Número"
                            labelSide
                            readonly>
                          </uiText>

                          <uiDate
                            style="width:30%;"
                            styleLabel="width:85px"
                            v-model="record.fra_fh"  
                            label="Fecha"
                            labelSide
                            readonly>
                          </uiDate>
                        </div>
                      </div>
                      
                      <uiText
                        style="width:100%;"
                        styleLabel="width:85px"
                        v-model="record.name_cta_id"
                        label="Empresa"
                        labelSide
                        readonly>
                      </uiText>                      
                    </v-sheet> 
                  </div> 
                                    
                  <div style="width:100%;">
                    <div class="cab">Estado / Observaciones</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <div class="columna" style="width:80%;">
                          <v-select
                            :style="!cerrado ? 'background-color: rgba(0,150,0,0.1)' : ''"
                            v-bind="$select"                            
                            v-model="ct.ptedoc[2]"                                                    
                            :label="ct.ptedoc[1]"                        
                            :items="$store.state.datos_iniciales.tipo_ptedoc"
                            @change="editamos()"
                            :disabled="cerrado"
                            dense
                            item-value="id"
                            item-text="name">
                          </v-select>
                        </div>

                        <div class="columna" style="width:20%;">
                          <v-checkbox
                            v-bind="$select"
                            v-model="ct.pago[2]"
                            :label="ct.pago[1]"
                            @change="editamos">
                          </v-checkbox>
                        </div>
                      </div>

                      <div v-if="noEdit">
                        <uiObs3                       
                          :record="record"
                          :schema="ct"
                          :schemaComponente="ct.ptedoc_obs"
                          :edicion=false>                       
                        </uiObs3>
                      </div>
                      
                      <div v-else>                           
                        <h4 style="color: red">Introduce Comentario / Observaciones y pulsa Guardar</h4>
                        <v-textarea
                          style="background-color: rgba(0,150,0,0.1)"                        
                          v-bind="$textarea"
                          v-model="ct.obs[2]"
                          :label="ct.obs[1]"
                          rows="3"
                          no-resize>                      
                        </v-textarea>
                      </div>
                    </v-sheet>
                  </div>
                </div>
              </div>
            </v-sheet> 
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";    
      
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraExtra, dualTemplate },
    props: {
      tipo: { type: [String, Number], default: "1" }, 
      show: { type: [String, Boolean], default: "1" },
    },

    data() {
      return {
        stIni: {
          api: "frasPteDocM",
          name:"frasPteDocM",
          titulo:"Pendiente Documentación",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },  

      };
    },


   
    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'guardar', btn: "guardar" }
        ];

        this.btnSet(this.btExtCfg, 'guardar', { disabled:true }); 
      },


      iniDataApi() { 
        this.apiArgs.sch= null;
        this.apiArgs.pteDocum= ["", 'pteDocum', { id:0, std:'', pag:0, txt:'' }, "facturas"];
      },
      

      // gancho al final de la acción 'ver'
      verFin() {        
        this.$set(this.ct.ptedoc, 2, this.record.ptedoc);
        this.$set(this.ct.pago, 2, (this.record.pago=='1'? true : false));
        
        // si NO hay documentos pendientes, 
        if (this.pendiente) return;
        this.record2ctrl({}, this.ct, true);
      },


      // guardo datos
      async guardar() {       
        let param = { apiArg: this.apiArgs.pteDocum, args: { 
          id:this.record.id,
          std:this.ct.ptedoc[2],
          pag:(!this.ct.pago[2]?0:1),
          txt:this.ct.obs[2] 
        }};      
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }       
 
        this.$root.$alert.open('Datos guardados correctamente', 'success', 1800);
 
        // actualizo record de la fra seleccionada
        this.masterStoreUpdate('refresh');

        // cierro ventana
        this.$emit('onEvent', { accion: 6 });
      },


      // si cambia combo o check
      editamos() {
        const edit= (this.record.ptedoc!= this.ct.ptedoc[2] || this.record.pago!= this.ct.pago[2]);

        if (edit) {
          this.stEditar();
          this.btnSet(this.btExtCfg, 'guardar', { disabled:false });

        }else {          
          this.stCancelar();
          this.btnSet(this.btExtCfg, 'guardar', { disabled:true });          
        }

        this.ct.obs[2]='';
      }

    },



    computed: {

        // schema particular
        sch() {
          return {
            ptedoc_obs: [ '', '', "", [],
              {comp: { 
                type:"obs3",
                fields:{ gestion:"ptedoc_obs" },
                valueAux:"",
                valueAux1:""
              }}
            ],
            
            ptedoc: [ 'ptedoc', 'Pendiente Documentación', "0" ],
            pago: [ 'pago', 'Pago', "1" ],
            obs: [ 'obs', 'Comentario', "" ]
          }
        },


        //   
        cerrado() {
          return this.recordAux.chkconfcierre=='1';
        },


        // 
        pendiente() {                        
          return this.record.ptedoc>'0'? true : false;
        },
        
    },

  }
</script>
