<!-- Mto expansible de Documentos -->

<template>
  <div class="expDocsMX" v-if="loaded">      
    <v-sheet class="contenedor" :elevation="0"> 

      <!-- Botonera -->
      <div class="pb-2 d-flex justify-left">        
        <baseBtraMto class="conflex" style="width:180px;"           
          :modulo="btMtoCfg" 
          :estado="estado"
          @onEvent="execAccion">        
        </baseBtraMto> 

        <!-- extra -->
        <div class="conflex" style="width: 60px; margin-left: 10px">
          <baseBtraExtra class="conflex"
            :modulo="btExtCfg"           
            @onEvent="execAccion">             
          </baseBtraExtra>        
        </div>       
      </div>

      <!-- Controles del Mto -->     
      <div style="display:flex"> 
        <uiText
          style="width:60%"
          v-model=" ct.name[2]"
          :label=" ct.name[1]"
          :disabled="noEdit">
        </uiText>

        <v-select
          style="width:40%"
          v-bind="$select"
          v-model=" ct.tpd[2]"
          :label=" ct.tpd[1]"
          :items="itemsTipoDocumentos"
          :disabled="noEdit"
          item-value="id"
          item-text="name">
        </v-select>
      </div>
        
      <v-textarea
        v-bind="$textarea"
        v-model=" ct.des[2]"
        :label=" ct.des[1]"
        rows="2"
        no-resize
        :disabled="noEdit">
      </v-textarea>

      <v-file-input
        v-model="files"
        accept="application/pdf"
        chips
        clearable
        dense
        rounded
        small-chips
        :disabled="estado != 'nuevo'">
      </v-file-input>

      <div style="display:flex"> 
        <div>Factura/Suplido (Debe indicar si corresponse a una Factura o a un Suplido específico)</div>
      </div>

      <div style="display:flex">

        <v-select
          style="width:450px;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"
          v-bind="$select"
          v-model="getFra"
          :label="ct.fra_id[1]"
          :items="itemsFactura"
          :disabled="noEdit"
          item-value="d"
          item-text="n"
          @change="changeFra">
        </v-select>

        <v-checkbox
          style="width:10%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"
          v-bind="$checkbox"
          v-model="ct.ca24[2]"
          :label="ct.ca24[1]"
          :disabled="noEdit">
        </v-checkbox>

        <v-checkbox 
          style="width:10%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"           
          v-bind="$checkbox"
          v-model="ct.apd[2]"
          :label="ct.apd[1]"
          :disabled="noEdit">
        </v-checkbox>

        <v-checkbox  
          style="width:10%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"          
          v-bind="$checkbox"
          v-model="ct.jzo[2]"
          :label="ct.jzo[1]"
          :disabled="noEdit">
        </v-checkbox>

        <v-checkbox
          style="width:10%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"            
          v-bind="$checkbox"
          v-model="ct.cia[2]"
          :label="ct.cia[1]"
          :disabled="noEdit">
        </v-checkbox>

        <v-checkbox
          style="width:10%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"            
          v-bind="$checkbox"
          v-model="ct.fun[2]"
          :label="ct.fun[1]"
          :disabled="noEdit">
        </v-checkbox>        
      </div>

      <div style="display:flex">        
        <uiDate 
          v-model="ct.fhhr[2]"
          :label="ct.fhhr[1]"          
          type="datetime-local"
          disabled>          
        </uiDate>       

        <uiText          
          v-model=" ct.usu[2]"
          :label=" ct.usu[1]"
          disabled>
        </uiText>        
      </div>
    </v-sheet>       
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js"; 
  import baseBtraMto from "@/base/baseBtraMto";  
  import baseBtraExtra from "@/base/baseBtraExtra"; 
     
  export default {
    mixins: [mixM],
    components: { baseBtraMto, baseBtraExtra },
    props: {
      // Identifica la tabla asociada al documento
      // Descripcion de todas las tablas en la tabla documentos_tip
      tipoDocumento: { type: String, default: "0" },
    },

    data() {
      return {
        stIni: {
          api: "expDocsMX",
          name:"expDocsMX",          
          titulo:"",
          recordAccess:"local",
          mView:'',
          pView:[],
          relation:'tip_id'
        },  
         
        // variable temporal para guardar imágenes que se suben al servidor
        files:null,

        // array de tipo de documentos
        itemsTipoDocumentos:[]
      }
    },
   

    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");    
        
        // defino botones extra de la cabecera
        this.btExtCfg= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));     
        this.btExtCfg.btnsAccion= this.btExtCfg.btnsAccion.concat([          
          { accion: "envioGd", btn: "subida", title:"Envio Factura MF (Gestor Documental)", show: 1 }          
        ]);
        
    
        // array de tipo de documentos posibles        
        // filtro en los tipos de documento posibles solo los que pueden corresponder a esa tabla (documentos_tip)=tipoDocumento
        // se reconoce porque hay un 1 en la posicion number(tipodocumento) del string
        this.itemsTipoDocumentos= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_documento));
        this.itemsTipoDocumentos= this.itemsTipoDocumentos.filter(elem => elem.modulos.charAt(this.tipoDocumento== 1));

        // facturas expedientes
        this.itemsFactura= JSON.parse(JSON.stringify(this.$store.state[this.masterStore].recordsRead[2]));
        this.itemsFactura.unshift({ d:"0", n:"Selecciona una Factura/Suplido" });
      },


      // actualizo apiArgs
      iniDataApi() {        
        this.apiArgs.set= ["mto", 'guardar', {}, this.stIni.api];
        this.apiArgs.del= ["mto", 'borrar', { id: 0, tip:0, tip_id:0 }, this.stIni.api];
        this.apiArgs.envio= ["", 'envio_mf', { id: 0, tp:1 }, 'documento'];
      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
      },


      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.$set(this.ct.tip, 2, this.tipoDocumento);
        this.botonerasSet();
      },
      

      // validación de datos antes de guardar
      validateParticular() {
        // compruebo si hemos seleccionado un documento
        if (!this.files && this.estado == "nuevo") {
          this.$root.$alert.open("Debe seleccionar un documento", "error");
          return false;
        }

        return true;
      },


      // guardo item fra seleccionada
      changeFra(d) {             
        let itemFra= this.itemsFactura.find(item => item.d == d);
        
        // actualizo el control de suplido con el dato de la fra
        this.$set(this.ct.sup_id, 2, itemFra.sup);
        
        // marco checkbox´s de APD y JZ
        this.$set(this.ct.apd, 2, "1");
        this.$set(this.ct.jzo, 2, "1");
        this.$set(this.ct.name, 2, itemFra.n);
      },

      
      // (override) gancho MixM para devolver el array con los datos a guardar
      getRecordChangesToSave() {
        // devuelvo record par->valor con todos los campos del ct que NO tengan 'nosave'
        return this.ctrl2record(this.ct, this.record, false);
      },


      // (override) hago un override porque para guardar una imagen hay que
      // llamar la función stGuardarFormData en vez de stGuardar
      async guardarAPI(recordChanges) {
        let apiResult = await this.stGuardarFormData(recordChanges);     
        
        // Pendiente: controlar error API
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error en grabación', 'error');
          return;
        }

        // actualizo record maestro, si corresponde, y mto
        this.guardarUpdate();
      },

      // refresco Finder
      /* guardarUpdate() {
        this.$store.commit(this.masterStore + '/disparoSet');
      }, */


      async stEliminar() {
        // apiArg: llamada API definida en el particular. 
        // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.set
        let param = { apiArg: this.apiArgs.del, args: { id: this.ID, tip: this.tipoDocumento, tip_id:this.relationID } };
        console.log("stEliminar *************************:", param);
        return await this.$store.dispatch(this.storeName + "/eliminar", param);
      },

      // gancho después de eliminar
      // refresco Finder
      /* eliminarFin() {
        this.$store.commit(this.masterStore + '/disparoSet');
      }, */

     
      


      

      // configuración botones botonera
      botonerasSet() {       
        this.btnSet(this.btExtCfg, 'envioGd', { disabled: this.ct.cia[2]==0 });      
      },


      // pregunto si enviamos factura seleccionada al Gestor Documental
      envioGd() {
        this.$root.$alert.open('ATENCION<br>Enviamos Factura Venta (pdf) a MAPFRE (Gestor Documental)?<br>Factura: ' + this.ct.name[2], 'confirm')
        .then(r=> { if (r) this.envioEmailGd(); });
      },

      // API envio email a Gestor Documental
      async envioEmailGd() {
        let param = { apiArg: this.apiArgs.envio, args: { id: this.ID } };        
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult envioEmailGd: ', apiResult);

        // 
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Factura enviada correctamente', 'success', 1800);
      },
     
    },


    computed: {

      // id relacionado. Solo para nuevos registros
      relationID() {        
        if (!this.recordAux || !Object.keys(this.recordAux).length ) return 0;
        return this.recordAux.id;
      },


      // 
      getFra: {
        get() {          
          if (this.estado== 'nuevo') return "0";
          if (Number(this.masterSyncRecord.sup_id)> "0") return this.masterSyncRecord.sup_id;         
          return this.masterSyncRecord.fra_id;
        },

        set(d) {            
          let itemFra= this.itemsFactura.find(item => item.d == d);
          this.$set(this.ct.fra_id, 2, itemFra.fra);
        }
        
      }

    }

};
</script>
