<!-- Facturas comunes -->

<template>
  <div class="commonFras" v-if="loaded">

    <v-dialog v-model="modal" content-class="modal" persistent>

        <div>

          <!-- Cabecera -->
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.finder"
              :titulo='$store.state[storeName].titulo'
              @onEvent="eventHeader">
            </baseHeader>
          </div>

          <v-sheet :elevation="4">
            <div class="contenedor" style="width:950px">

              <!-- <div v-html="info"></div> -->

              <!-- Filtro de Busqueda -->
              <div class="columna">
                <div style="display:flex">
                  <v-select
                    v-bind="$select"
                    v-model="ct.orden[2]"
                    :label="ct.orden[1]"
                    :items="itemsTipoOrden"
                    item-value="d"
                    item-text="n"
                    @change="orden">
                  </v-select>

                  <v-select
                    v-bind="$select"
                    v-model="ct.yy[2]"
                    :label="ct.yy[1]"
                    :items="$store.getters[storeName + '/recordsAdjuntos'](0)"
                    item-value="d"
                    item-text="n"
                    @change="filtrar">
                  </v-select>
                </div>
              </div>

              <!-- Grid -->
              <baseGridMD
                style="padding-top:10px"
                :store="storeName"
                :headers="headerGrid"
                :items-per-page="-1"
                dense
                @onEvent="execAccion">

                  <!-- Slot botonera ROWS -->
                  <template v-slot:acciones="{ item }">
                    <baseBtraMto
                      :perm="permMto"
                      :modulo="btRowCfg"
                      @onEvent="execAccion({ ...$event, ...{ item } })">
                    </baseBtraMto>
                  </template>

              </baseGridMD>
            </div>
          </v-sheet>
        </div>
    </v-dialog>


    <component
      syncUpdate
      :is="componenteDinamico"
      :storeRaiz="storeName"
      :masterStore="storeName"
      :filtro="filtroAux"
      tipoFiltro="exp"
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
    </component>

  </div>
</template>



<script>

    import { mixF } from "@/mixins/mixF.js";
    import baseHeader from "@/base/baseHeader";
    import baseGridMD from "@/base/baseGridMD";
    import baseBtraMto from "@/base/baseBtraMto";
    import frasExpF from "@/components/frasExpF";

    export default {
      mixins: [mixF],
      components: { baseHeader, baseGridMD, baseBtraMto, frasExpF },
      props: {
        tipoDocumento: { type: String, default: "0" },
      },

      data() {
        return {
          stIni: {
            api: "commonFrasF",
            titulo:"·Facturas",
            name:"commonFrasF",
            mView:'commonFrasMX',
            pView:[],
            expansibleMX:this.storeName + 'commonFrasMX'
          },

          // array con los tipos de ordenación
          itemsTipoOrden:[],
          filtroAux:''
        };
      },


      methods: {
        iniDataParticular() {
          console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

          this.headers = [{
            name:[],
            fields:[
              { text: "Acciones", value: "acciones", sortable: false, width: "30px", slot:true },
              { text: "T", value: "T", width:"5px" },
              { text: "Exped./Ref.", value: "expediente", width:"10px" },
              /* { text: "F", value: "F", width:"10px" }, */
              { text: "Factura", value: "factura", width:"20px" },
              { text: "Fecha", value: "fecha", filtro:'fecha', width:"15px"  },
              { text: "Importe", value: "importe", filtro:'num', width:"15px"  },
              { text: "Asiento", value: "asiento", width:"10px" }
            ]}
          ];

          // configuro cabecera Grid
          this.headerGrid= this.headerSet();

          // añado botón de ver Pdf a las rows
          this.btRowCfg.btnsAccion= [
            { accion:'verPdf', btn:'pdf' },
            { accion:'verDoc', btn:'doc' },
            { accion:'sendLiquidacion', btn: "sendEmail" },
            { accion:'facturacion', icono: "mdi-alpha-f-circle-outline", title:'Facturación' },
          ];

          // le indico que el finder tiene un autoload con la función 'buscar'
          // al cargar el componente hago un recordGet
          this.autoLoad= 'buscar';

          // actualizo control tip
          this.$set(this.ct.tip, 2, this.tipoDocumento);

          // array de ordenación filas Grid
          this.itemsTipoOrden=[
            { d: "0", n: "Nº Factura", c: "factura" },
            { d: "1", n: "Importe", c: "importe" },
            { d: "2", n: "Fecha Ascendente", c: "fecha" },
            { d: "3", n: "Fecha Descendente", c: "fecha" }
          ];

        },


        //
        iniDataApi() {
          // añado a apiArgs la llamada a pdf y filtro
          this.apiArgs.pdf= ["", "fichero", { id:0, tp:0, Ftp:0  }, "facturas"];
          this.apiArgs.filtro= ["", "lista", { yy:"", tip:0, tip_id:0 }, "facturas"];
        },


        // gancho después de cargar los records
        recordsGetAfter() {
          // actualizo el elemento seleccionado del select de años, según records filtrados
          if (this.records[0]) this.$set(this.ct.yy, 2, this.records[0].yy);

          // ordeno los registros por orden de fecha descendiente
          this.orden(3);

          // configuro botones
          this.botonerasSet();
        },


        // ordenar registros
        orden(orden) {
          // filtro:
          //    0: Nº Factura ASCENDENTE
          //    1: Importe ASCENDENTE
          //    2: fecha ASCENDENTE
          //    3: fecha DESCENDENTE

          let campo= "fecha";
          if (orden== 0) campo= 'factura';
          if (orden== 1) campo= 'importe';

          this.stOrdenar({ campo, orden:orden== 3? 'DESC' : 'ASC' });
        },


        // filtrar registros por tipo de documento
        async filtrar() {
          await this.stFiltrar(this.filtrarFras);
          this.orden(this.ct.orden[2]);
        },

        // filtrar por tipo de documento
        // envíamos esta función desde 'filtrar', donde se ejecuta y actualiza los records del Grid
        async filtrarFras(records) {
          console.log('DEV filtrarFras ********************** ', records);

          // apiCall para obtener los registros filtrados
          let param= { apiArg:this.apiArgs.filtro, args:{ yy:this.ct.yy[2], tip_id:this.masterSyncRecord.id, tip:this.tipoDocumento }};
          let apiResult = await this.$store.dispatch("apiCall", param);

          if (apiResult.sql.error) return [];
          return apiResult.r[0];
        },


        // muestro PDF factura
        verPdf(evt) {
          this.verFichero(evt, 1);
        },


        // muestro PDF documento
        verDoc(evt) {
          this.verFichero(evt, 2);
        },


        // mostrar nueva pestaña del navegador con pdf del documento seleccionado
        async verFichero(evt, tp) {
          let param = { apiArg: this.apiArgs.pdf, args: { id: evt.item.id, Ftp:evt.item.Ftp, tp:tp }};
          let apiResult = await this.$store.dispatch("apiCall", param);

          if (apiResult.sql.error) {
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            return;
          }

          // abro documento
          window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");
        },


        // configuro botones
        // muestro el botón de ver Documento sólo si tiene (doc==1)
        botonerasSet() {
          this.records.forEach(elem => {
            console.log('botonera commonFras: ', elem);
            this.btnSet(this.btRowCfg, 'verPdf', { view:(elem.Ftp== '1' || elem.Ftp== '3') });
            this.btnSet(this.btRowCfg, 'verDoc', { view:elem.doc== '1' });
            this.btnSet(this.btRowCfg, 'sendLiquidacion', { view:elem.Ftp== '3' });
            this.btnSet(this.btRowCfg, 'facturacion', { view:elem.Ftp== '1' || elem.Ftp== '2' });
          });
        },


        //
        sendLiquidacion(evt) {
          if (!evt.item) return;
          this.$emit('sendLiquidacion', evt.item)
        },


        // muestro ventana facturación
        async facturacion(evt) {
          this.filtroAux= evt.item.expediente;
          this.componenteDinamico= "frasExpF";
        },

      }
    };

</script>


