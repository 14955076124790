<!-- Envolvente llamadas de Expedientes -->

<template>
  <div class="expPhone" v-if="loaded">
    
    <v-dialog
      content-class="modal"        
      v-model="modal">
        <v-sheet :elevation="4">
          <!-- Cabecera -->
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.finder"
              titulo="Registro de llamadas"
              @onEvent="modal=false"> 
            </baseHeader>
          </div>
     
          <!-- Filtro -->
          <div class="contenedor" style="width:650px"> 
            
              <div class="columna"> 
                <div class="cab">DATOS LLAMADAS</div>
                <v-sheet :elevation="4">
                  <div class="conflex" style="padding:5px">
                    <v-text-field  
                      style="width:25%"
                      v-bind="$input"
                      v-model="itemsRegs.datos[0].e"
                      label="Entrantes"
                      readonly>
                    </v-text-field>

                    <v-text-field       
                      style="width:25%"           
                      v-bind="$input"
                      v-model="itemsRegs.datos[0].s"
                      label="Salientes"
                      readonly>
                    </v-text-field>

                    <v-text-field   
                      style="width:25%"               
                      v-bind="$input"
                      v-model="itemsRegs.datos[0].t"
                      label="Totales"
                      readonly>
                    </v-text-field>

                    <v-text-field     
                      style="width:25%"             
                      v-bind="$input"
                      v-model="itemsRegs.datos[0].d"
                      label="Duración"
                      readonly>
                    </v-text-field>
                  </div>
                </v-sheet>
              </div>

              <!-- tab menu -->
              <v-tabs style="margin-top:10px"
                      v-model="tab"
                      background-color="blue-grey darken-1"
                      active-class="blue-grey lighten-3 font-weight-bold"
                      :align-with-title="true"
                      dark
                      show-arrows
                      hide-slider
                      height="30px">
                    
                    <v-tab v-for="seccion in secciones" :key="seccion.titulo">
                          <v-icon class="pr-2">{{ seccion.icono }}</v-icon>
                          <div class="white--text">{{ seccion.titulo }}</div>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">          
                  <v-tab-item
                    v-for="seccion in secciones"
                    :key="seccion.titulo"
                    :transition="false"
                    :reverse-transition="false">                        
                    <div >

                      <div v-if="tab==0">

                         <div style="padding-top:10px">
                          <span style="color:lightred">
                            Debes introducir o copiar (Ctrl + C) desde el programa BLUSTAR-AGENT el código IDEN.LLAMADA que aparece en el cuadro IVR de la pestaña 
                            Sessiones, dentro del cuadro de texto "Identificador de Llamada ENTRANTE" (Ctrl + V). Recuerda: NO debes introducir el número de teléfono...
                          </span>
                        </div>

                        <div class="conflex" style="width:50%;padding-top:10px">
                          <v-text-field                  
                            v-bind="$input"
                            v-model="ct.ref[2]"
                            :label="ct.ref[1]">                
                          </v-text-field>

                          <v-btn                    
                            style="margin-left:5px"
                            v-bind="$cfg.btra_cfg.standard"                                                     
                            @click="savePhone"
                            :disabled="!ct.ref[2]">
                              <v-icon small>{{ 'mdi-content-save' }}</v-icon>                
                          </v-btn>
                        </div>

                        <!-- Finder de Registros de llamadas -->
                        <div class="cab" style="margin-top:10px;">REGISTROS LLAMADAS</div>
                        <v-sheet :elevation="4">
                          <baseGridSimple
                            :headers="headerSetData('', headersRegs)"
                            :items="itemsRegs.regs"
                            :items-per-page="30"                             
                            dense
                            hide-default-footer
                            @selected="selectedSet($event, 'regs')">                               
                          </baseGridSimple>
                        </v-sheet>
                        
                      </div>

                      <div v-else>
                        <!-- <div style="padding-top:5px">
                          <span style="color:lightred">
                            Debes introducir o copiar (Ctrl + C) desde el programa BLUSTAR-AGENT el código IDEN.LLAMADA que aparece en el cuadro IVR de la pestaña 
                            Sessiones, dentro del cuadro de texto "Identificador de Llamada ENTRANTE" (Ctrl + V). Recuerda: NO debes introducir el número de teléfono...
                          </span>
                        </div> -->
                         <div style="padding-top:10px">
                          <span style="color:lightred">
                            Realizar Llamada, selecciona Interviniente del Expediente ó introduce número a llamar.
                          </span>
                        </div>

                        <div class="conflex" style="width:50%;padding-top:10px">                          
                          <v-text-field                  
                            v-bind="$input"
                            v-model="ct.tlf[2]"
                            :label="ct.tlf[1]">                
                          </v-text-field>

                          <v-btn                    
                            style="margin-left:5px"
                            v-bind="$cfg.btra_cfg.standard"
                            :disabled="!ct.tlf[2]"                            
                            :href="`AppLlamadas:${recordAux.id}-${ct.tlf[2]}-${usu}-${selected.c}`">                            
                              <v-icon small>{{ 'mdi-phone' }}</v-icon>                
                          </v-btn>
                        </div>
                        
                        <!-- Finder de Teléfonos -->
                        <div class="cab" style="margin-top:10px;">TELÉFONOS EXPEDIENTE</div>
                        <v-sheet :elevation="4">
                          <baseGridSimple                      
                            :headers="headerSetData('', headersTlfs)"
                            :items="itemsTlfs"
                            :items-per-page="30"                             
                            dense
                            hide-default-footer
                            @selected="selectedSet($event, 'tlfs')">    

                            <!-- slot cambio de color para apd/jefe de zona -->
                            <template v-slot:name="{ item }">
                                <div :style="[item.name.substring(0,3).toUpperCase()==='APD' ? {color: 'green',  fontWeight: 'bold'} : {color: 'black'}]">{{item.name}}</div>
                             
                            </template>



                          </baseGridSimple>
                        </v-sheet>     

                      </div>
                    </div>
                  </v-tab-item>
              </v-tabs-items>                    
            
          </div>
        </v-sheet>
    </v-dialog>    
  </div>
</template>


<script>
  
  import { mixCt } from "@/mixins/mixCt";
  import baseHeader from "@/base/baseHeader";  
  import baseGridSimple from "@/base/baseGridSimple";  
  
  export default {    
    components: { baseHeader, baseGridSimple },
    mixins: [mixCt],
    props: {
      syncDisparo: { type:Boolean, default:false },
      recordAux: { type: Object, default:null },
      storeRaiz: { type:String, default:'' }
    },

    data() {
      return {
        raiz:'',
        storeNameRaiz:'',       
        modal:false,       
        loaded:false,
        itemsTlfs:[],
        itemsRegs:{},
        selected:{},

        ct: {
          tlf: ['tlf', 'Nº Teléfono', ''],
          ref: ['ref', 'Nº Llamada', '']
        },

        apiArgs: {
          get: ["", 'vozip', { fnt:'get', id:0 }, "expediente" ],
          set: ["", 'vozip', { fnt:'set', id:0, cid:0 }, "expediente" ]
        },
        
        // Grid Teléfonos
        headersTlfs:{},
        headersRegs:{},

        tab: null,
        secciones: [
          {
            titulo: "Registro",
            icono: "mdi-phone-incoming"            
          },

          {
            titulo: "Llamar",
            icono: "mdi-card-account-phone-outline"            
          },         
        ],
      };
    },


    created() {
      this.iniDataParticular();
    },


    methods: {

      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");             
  
        this.headersTlfs = [{
          name:[],
          fields:[                   
            { text: "Persona / Empresa", value: "name", style:'font-weight: bold', width: "75%", slot:true  },            
            { text: "Teléfono", value: "t", width: "25%" }          
          ]},         
        ];

        //
        this.headersRegs = [{
          name:[],
          fields:[                   
            { text: "Call ID", value: "call_id", width: "10%" },            
            { text: "T", value: "tip", width: "5%" },            
            { text: "Operador", value: "usu", width: "10%" },            
            { text: "A/DE", value: "caller_tip", width: "5%" },            
            { text: "Número", value: "caller_num", width: "10%" },   
            { text: "Llamada", value: "caller_fhh", width: "50%", filtro:'fechahr' },                   
            { text: "Tiempo", value: "tie", width: "10%" },            
          ]},         
        ];

      },


      // actualiza nº de teléfono a llamar según fila seleccionada
      selectedSet(item, tipo) {
        this.selected=item[0];
        if (tipo== 'tlfs') {
          this.$set(this.ct.tlf, 2, item[0].t);
          return;
        }

        this.$set(this.ct.tlf, 2, item[0].caller_num);
      },


      // obtiene arrays llamadas y nº de teléfono desde API
      async getData() {       
        let param= { apiArg:this.apiArgs.get, args:{ id: this.recordAux.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log("getData Result:", apiResult)

        //
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al obtener los números de teléfonos del Expendiente', 'error');
          return;
        }

        // actualizo arrays de teléfonos
        this.updateArrays(apiResult.r);
        
        // blanqueo teléfono
        this.$set(this.ct.tlf, 2, '');

        this.modal= true;
        this.loaded= true;
      },


      // guarda nº de llamada indicado en llamadas entrantes
      async savePhone() {       
        let param= { apiArg:this.apiArgs.set, args:{ id: this.recordAux.id, cid:this.ct.ref[2] }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log("setData Result:", apiResult)

        //
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al grabar el número de llamada', 'error');
          return;
        }

        this.updateArrays(apiResult.r);
        this.$root.$alert.open('Número de llamada guardado correctamente', 'success', 1000);
      },


      updateArrays(data) {
        this.itemsTlfs= data[2];
        this.itemsRegs= { regs:data[0], datos:data[1] };
      },


    },


    watch: {
      syncDisparo() {            
        this.getData();        
      }
    }

  };
</script>


