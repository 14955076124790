<!-- Finder particular de Apds -->

<template>
  <div class="apdF" v-if="loaded">
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">
          
        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>


        <!-- Contenido -->
        <div class="contenedor" style="width:800px"> 
          
          <!-- Filtro de Busqueda -->          
          <div class="columna">
            <div class="conflex" style="width:55%;">
            
              <v-select
                v-bind="$select"
                style="width:50%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-model="ct.idjefe[2]"
                :label="ct.idjefe[1]"
                :items="jefeszona"
                :disabled="zona >0"
                item-value="id"
                item-text="name">            
              </v-select>

              <v-btn 
                v-bind="$cfe.btn.busca" 
                style="margin-left:5px"
                @click="eventFiltro('buscar')">
                  <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
              </v-btn>
            </div>
            
            <div class="conflex" style="align-content:center;width:100%">
              <uiText              
                style="width:70%"
                v-model="ct.name[2]"
                :label="ct.name[1]"
                @Enter="eventFiltro('buscar')"> 
              </uiText>            
          
              <v-checkbox
                style="width:28%;margin-left:10px"
                v-bind="$checkbox"
                v-model="ct.bajas[2]"
                :label="ct.bajas[1]">
              </v-checkbox>          
            </div>
          </div>

          <!-- Grid -->
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="10"           
            dense
            @onEvent="execAccion">

              <!-- Slot Botonera Top Grid -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>

              <!-- Slot Acciones Row -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
              
          </baseGridMD>         
        </div>
      </v-sheet>

     
      <!-- Mto APDS -->     
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component> 
    </div>
  </div>
</template>


<script>

  import { mixF } from "@/mixins/mixF.js";  
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import apdM from "@/components/apdM";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, apdM },
    props: {},

    data() {
      return {
        stIni: {
          api: "apdF",
          titulo:"APDS", 
          name: "apdF",
          mView: 'apdM',
          pView:[]
        },
      
        jefeszona:[]
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
            // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
            // si solo hubiera un elemento la cabecera tomará este por defecto 
            // Si no encontrara el nombre pondría el elemento 0
            name:[],
            fields:[
              { text: "Acciones", value: "acciones", sortable: false, width:'10%', slot:true },
              { text: "Nombre", value: "name", width: "60%" },
              { text: "Código", value: "codigo", width: "30%" }              
            ]}          
        ];      

        this.headerGrid= this.headerSet();        

        // asigno zona recibida
        this.$set(this.ct.idjefe, 2, this.zona);      
      
        // array zonas jefes de zona
        this.jefeszona= this.$store.state.datos_iniciales.zonas;
        this.jefeszona.unshift({ id:"0", name:"( Selecciona )"}); 
      },  

    }
  };
</script>
