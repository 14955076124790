export var presCalculo = {
    methods: {
        pres_calculo(detalle, ct, tipo_ope) {
            console.log("detalle pres_calculo", detalle);
            console.log("ctrls   pres_calculo", JSON.parse(JSON.stringify(ct)));
            console.log("tipo_op pres_calculo", tipo_ope);

            let COMPRA = tipo_ope == "C" ? true : false;
            let VENTA = tipo_ope == "V" ? true : false;
            console.log("compra/venta  pres_calculo", tipo_ope, COMPRA, VENTA)
            //{id:"0", name:"IVA",  tipos:[{value:"21.0",text:"21%"}, {value:"10.0",text:"10%"},{value:"0.0",text:"0%"},{value:"4.0",text:"4%"}]},
            let rt = this.$store.state.datos_iniciales.rTribut[this.ct.rt[2]];
            let rtTipos = rt.tipos.map(item => Number(item.value));
            let iva_gral = rtTipos[0];
            let err='';
            let self= this;
            // productos flores al 10%
            //let iva10 = [12, 21, 52, 140];
            //let err='';
            //
            var state = {
                imp: 0,
                sup: 0,
                ivapor1: 0,
                ivapor2: 0,
                ivapor3: 0,
                ivapor4: 0,
                ivapor5: 0,
                iva: 0,
                iva1: 0,
                iva2: 0,
                iva3: 0,
                iva4: 0,
                iva5: 0,
                base: 0,
                base1: 0,
                base2: 0,
                base3: 0,
                base4: 0,
                base5: 0,
                pnf1: 0,
                pnf2: 0,
                pnfsuma: 0,
                sup_pd: 0,
                imp_cia: 0,
                irpf: 0,
                irpfpor: (ct.irpfpor[2] ? ct.irpfpor[2] : 0),

            }

            //
            function acumula_iva(imp, iva) {
                if (!rtTipos.includes(iva)) err+=iva+"% "
                let key = 0;
                for (let i = 1; i < 6; i++) {
                    if (state["base" + i] == 0 && key === 0) key = i;
                    if (Number(state["ivapor" + i]) == iva) {
                        key = i;
                        break;
                    }
                }
                state["ivapor" + key] = iva;
                state["base" + key] += self.$fnGen.round(imp);
                //state["base" + key] += imp;
                state["iva" + key] = self.$fnGen.round((state["base" + key] * iva) / 100);
            }
            function suma_totales() {
                for (let i = 1; i <= 5; i++) {
                    state['base'+i]=self.$fnGen.round(state['base'+i]);
                    state['iva'+i]=self.$fnGen.round(state['iva'+i]);
                }
                state.base = self.$fnGen.round(state.base1 + state.base2 + state.base3 + state.base4 + state.base5);
                state.iva  = self.$fnGen.round(state.iva1 + state.iva2 + state.iva3 + state.iva4 + state.iva5);
                //state.imp  = this.$fnGen.round(state.base + state.iva + state.sup);
                state.irpf=  self.$fnGen.round((state.base * state.irpfpor) / 100);
                state.imp = self.$fnGen.round(state.base + state.iva + state.sup - state.irpf);
                state.pnfsuma = state.imp - state.sup_pd;
                state.imp_cia += state.imp;
            }
            //
            //-----------------------------------------------------------------------------------------
            //
            //
            //
            // const calculos = (state, item) => {
            const calculos = (state, item) => {
                let imp = Number(item.imp);
                let iva = Number(item.iva);

                //if (item.sup == "N") acumula_iva(imp,iva);
                if (item.sup == "S") {
                    state.sup += imp;

                } else {
                    acumula_iva(imp,iva);
                }


                if (COMPRA) {
                    // "PAGOS DIRECTOS"
                    // Aclarar a los apd que este caso es para derechos de inhumacion y cosas asi
                    // tendria que haber posteriormente factura de proveedor ajeno a la funeraria
                    // tambien se queda como un suplido para la compañia
                    if (item.sup == "S" && item.sp == 1) {
                        state.sup_pd += imp;
                    }

                    // DIFERENCIAS CON LA CIA
                    // algo que factura la funeraria como suplido y no es un pago directo
                    // A la compañia hay que facturarle el IVA
                    if (item.sup == "S" && item.sp != 1) {
                        state.imp_cia +=self.$fnGen.round((imp * iva_gral) / 100)
                    }
                }
               //console.log("calculos item,state2", item, state)
                return state;
            };


            // crear array ordenada por iva para los calculos antes de reduce y no machacar detalle
            []
                .concat(detalle)
                .sort((a, b) => a.iva - b.iva)
                .reduce(calculos, state);

            //
            suma_totales()
            //
            if (VENTA) {
                if (Number(this.ct.tmtpor1[2]) != 0) {

                    // calculo importe tramitación1 y actualizo campo
                    let importeTmt1= 0;
                    if (state.imp!= 0) {
                        importeTmt1= self.$fnGen.round(state.imp * Number(ct.tmtpor1[2]) / 100);
                        this.$set(this.ct.tmt1, 2, importeTmt1);
                    }

                    // compruebo si el servicio seleccionado tiene un importe de tramitación mínimo o máximo
                    // si lo tiene, actualizo campo con ese valor
                    let tram= this.itemsTramitacion.filter(elem=> elem.id== this.ct.tmttipo1[2])[0];
                    if (tram.min> 0 && importeTmt1< tram.min) {
                        this.$set(this.ct.tmt1, 2, tram.min);
                    }
                    if (tram.max> 0 && importeTmt1> tram.max) {
                        this.$set(this.ct.tmt1, 2, tram.max);
                    }

                }

                if (Number(ct.tmtpor2[2]) != 0) {
                    this.$set(ct.tmt2, 2, self.$fnGen.round(state.imp * Number(ct.tmtpor2[2]) / 100));
                }

                if (Number(ct.tmtpor4[2]) != 0) {
                    this.$set(ct.tmt4, 2, self.$fnGen.round(state.imp * Number(ct.tmtpor4[2]) / 100));
                }

                // Suma tramitaciones
                this.$set(ct.tmt, 2, Number(ct.tmt1[2]) + Number(ct.tmt2[2]) + Number(ct.tmt4[2]));

                // Suma APD
                this.$set(ct.tmt, 2, ct.tmt[2] + Number(ct.tmt3[2]));

                // Guardo importe sin tramitacion
                this.$set(ct.impstmt, 2, state.imp);
                console.log("tramitaciones",ct.impstmt,ct.tmt,ct.tmt1[2] , ct.tmt2[2] ,ct.tmt3[2], ct.tmt4[2])
                //
                acumula_iva(ct.tmt[2], iva_gral);
                suma_totales()
            }

            // paso calculos a schema.ctrls
            Object.keys(state).forEach(key => {
                if (Object.prototype.hasOwnProperty.call(this.ct, key)) this.$set(this.ct[key], 2, state[key]);
            });
            if (err!='')     this.$root.$alert.open("Tipos "+ rt.name+" no reconocidos:"+err, "error");

            console.log("*calculos----------------------------", state);
        }
    }

};
