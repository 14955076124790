<!-- Base Grid -->

<!--  props: {  
     *  headers: { type: Array, default: () => [], require:true }, Definición de las cabeceras       
        simpleSelect: { type:Boolean, default:true } Con valor false permite multiselect         
}, -->


    
<template>
  <div>
 
    <v-data-table
      v-model="selectedSync"
      v-bind="$attrs"
      class="baseGridSimple"
      :headers="headers"            
      :single-select="simpleSelect"
      @click:row="clkRow">
 

        <!-- Botones top -->
        <template v-slot:top="">
          <div class="conflex">          
            <slot name="gridTop"></slot>          
          </div>
        </template>

        <!-- creo slots si lo tiene definido en el array -->
        <template
          v-for="header in headers"
          v-slot:[`item.${header.value}`]="{ item }">        
            <div v-if="header.slot" :key="header.value">
              <slot :name="[`${header.value}`]" :item="item">
                {{ item[header.value] }}
              </slot>
            </div>

            <!-- aplicar filtro o style si lo tiene definido en la array -->
            <div v-else :key="header.value">
              <div :style="header.style ? header.style : ''">
                {{
                  header.filtro
                    ? $options.filters[header.filtro](item[header.value], header.arg)
                    : item[header.value]
                }}
              </div>
            </div>
        </template>
    </v-data-table>
  </div>
</template>



<script>
    
  export default {
    components: {},    
    props: {   
      headers: { type: Array, default: () => [], require:true },     
      simpleSelect: { type:Boolean, default:true },  
    },


    data() {
      return {
        selectedSync:[]
      }
    },
   
    
    methods: {
    
      // entro al seleccionar/pulsar una fila
      clkRow(row) {   
        console.log('Row seleccionada: ', row);
       
        // selección única
        if (this.simpleSelect) {
          this.selectedSync= [row];
          this.$emit('selected', this.selectedSync);
          return;
        }

        // selección múltiple
        let records= JSON.parse(JSON.stringify(this.selectedSync));        
        if (records.filter(elem => elem.id == row.id).length) {
          // ya existe la fila en el array de elementos seleccionados... la elimino          
          records= records.filter(elem => elem.id !== row.id);

        }else {
          // NO existe la fila en el array de elementos seleccionados... la añado
          records.push(row);
        }
      
        // actualizo array de elementos
        this.selectedSync= records;
        this.$emit('selected', this.selectedSync);
      },
  
    },


    computed: {
    

    },
  };
</script>
