<!-- Finder de Expedientes Presupuestos -->

<template>
  <div class="expPresupF" v-if="loaded">

    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">

        <!-- Contenido -->
        <div class="contenedor"> 
         
          <!-- <div v-html="info"></div> -->
          
          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            :multipleSelect=true
            show-select         
            dense
            @onEvent="execAccion">
         
              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div style="display:flex;width:1100px;justify-content: space-between;">
                 
                  <!-- BOTONERA FACTURAS grupo -->
                  <div class="conflex">
                    <div v-if="app==2 && !cerrada" style="width: 330px;display:flex;justify-content:space-between;margin-right:10px">
                      <!-- Botonera APD -->
                      <div  style="width: 250px;display:flex;justify-content:space-between;margin-right:10px">

                        <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('compraFune1')">
                          <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                          <sub style="color:orange;font-size:1rem;">C</sub>
                          <sub style="color:white;font-size:0.8rem;">1</sub>
                        </v-btn>

                        <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('compraFune2')">
                          <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                          <sub style="color:orange;font-size:1rem;">C</sub>
                          <sub style="color:white;font-size:0.8rem;">2</sub>
                        </v-btn>

                        <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('compra')">
                          <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                          <sub style="color:orange;font-size:1rem;">C</sub>
                        </v-btn>

                        <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('compraS')">
                          <div style="color:white;font-weight:bold;font-size:1.2rem"> + </div>
                          <sub style="color:red;font-size:1rem;">S</sub>
                        </v-btn>              
                        
                        <div style="width: 70px;display:flex;justify-content:space-between;margin-right:10px">
                          <v-btn fab  depressed x-small color="blue-grey lighten-1" @click="clickOperacion('fusionC')">
                            <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                            <sub style="color:orange;font-size:1rem;"> C</sub>
                          </v-btn>
      
                          <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('fusionS')">
                            <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                            <sub style="color:red;font-size:1rem;"> S</sub>
                          </v-btn>  
                        </div>
                      </div>
                    </div>

                    <!-- Bontonera GESTION -->
                    <div v-if="app==1 && !cerrada" style="width: 275px;display:flex;justify-content:space-between;margin-right:10px">
                      <div style="width: 110px;display:flex;justify-content:space-between;margin-right:10px">

                        <v-btn fab depressed x-small color="blue-grey lighten-1" @click="clickOperacion('venta')">
                          <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                          <sub style="color:green;font-size:1rem;"> V</sub>
                        </v-btn>

                        <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('compra')">
                          <div style="color:white;font-weight:bold;font-size:1rem"> + </div>
                          <sub style="color:orange;font-size:1rem;">C</sub>
                        </v-btn>

                        <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('compraS')">
                          <div style="color:white;font-weight:bold;font-size:1.2rem"> + </div>
                          <sub style="color:red;font-size:1rem;">S</sub>
                        </v-btn>                    
                      </div>
                      
                      <div style="width: 110px;display:flex;justify-content:space-between;margin-right:10px">
                        <v-btn fab  depressed x-small color="blue-grey lighten-1" @click="clickOperacion('fusionC')">
                          <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                          <sub style="color:orange;font-size:1rem;"> C</sub>
                        </v-btn>

                        <v-btn fab depressed x-small color="blue-grey lighten-1" @click="clickOperacion('fusionV')">
                          <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                          <sub style="color:green;font-size:1rem;"> V</sub>
                        </v-btn>

                        <v-btn depressed fab x-small color="blue-grey lighten-1" @click="clickOperacion('fusionS')">
                          <div style="color:white;font-weight:bold;font-size:1.2rem"> F </div>
                          <sub style="color:red;font-size:1rem;"> S</sub>
                        </v-btn>
                      </div>
                    </div>

                    <!-- boton refresco -->
                    <div class="conflex">
                      <v-btn
                          v-bind="$cfe.default.btra.std"
                          :title="$cfe.btn.refresh.tooltip"
                          @click="refresco">
                            
                            <!-- Icono -->
                            <v-icon v-bind="$cfe.default.btra.std.icons">
                              {{ $cfe.btn.refresh.icon }}
                            </v-icon>
                      </v-btn>                  
                    </div> 
                  </div>

                  <!-- botonera cierre -->
                  <div style="width: 230px;display:flex;justify-content:space-between;margin-right:10px">
                    <div>
                      <v-chip  small  ma-2              
                        :color="expediente.chkpresapd=='1'?'#8B0000':'#D3D3D3'"
                        text-color='white'>
                          <v-icon small left> mdi-lock </v-icon>
                          APD            
                      </v-chip>
                    </div>

                    <div>
                      <v-chip  small  ma-2
                        :color="expediente.chkconf24=='1'?'#8B0000':'#D3D3D3'"
                        text-color='white'>
                          <v-icon small left> mdi-lock </v-icon>
                          CTD            
                      </v-chip>
                    </div>

                    <div>
                      <v-chip  small  ma-2
                        :color="expediente.chkconfadmon=='1'?'#8B0000':'#D3D3D3'"
                        text-color='white'>
                          <v-icon small left> mdi-lock </v-icon>
                          ADM 
                      </v-chip>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Slot Columna 'bloqueada' GRID -->
              <template v-slot:bloqueada="{ item }"> 
                <v-icon size="medium" :color="Number(item.bloqueada)==1 ?'red':'green'">  {{ item.bloqueada | bloqueada }} </v-icon>
                <v-icon size="medium" :color="Number(item.aceptada)==1 ?'green':'red'">  {{ item.aceptada | aceptada }} </v-icon>                
              </template>

              <!-- Slot Columna 'suplido' GRID -->
              <template v-slot:suplido="{ item }">
                <div style="color:red;font-weight:bold">{{ item.suplido=='1'?'SUP':'' }}</div>
              </template>
                    
              <!-- Slot campo acciones del Grid -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
          </baseGridMD>          
        </div>
      </v-sheet>


      <!-- Mto Presupuestos Expedientes -->      
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        :recordAux="expediente"       
        :operacion="newOperacion"
        @onEvent="$emit('onEvent', $event)">
      </component> 
        
    </div>    
  </div>
</template>
      <!-- <v-chip @click="blinds">
          <v-icon left>
            mdi-blinds
          </v-icon>
          Close blinds
        </v-chip> 
             <v-chip
        class="ma-2"
        color="secondary"
      >
        Secondary
      </v-chip>-->

<script>

  import { mixF } from "@/mixins/mixF.js";
  import { mixOperacion } from "@/mixins/mixOperacion.js";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import expPresMD from "@/components/expPresMD";

  export default {
    mixins: [mixF, mixOperacion],
    components: { baseGridMD, baseBtraMto, expPresMD },
    props: {},

    data() {
      return {
        stIni: {
          api: "expPresF",
          titulo:"", 
          name:"expPresF",
          mView:'expPresMD',
          pView:[],
          getRecordsArray:2,
          selection:'preserve'
        },

        // datos que se pasan para fusion / nuevo presupuesto       
        datos_nuevo_presupuesto:{}
      }
    },


    // Para renderizado de iconos
    filters: {
      bloqueada(i) { 
        if (i==1) return "mdi-lock";
        if (i==0) return "mdi-lock-open";
        return "mdi-pencil";
      },

      aceptada(i) {
        if (i==1) return "mdi-check-bold";
        if (i==2) return "mdi-close";
        return "";
      },
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[
            { text: "Ver", value: "acciones", sortable: false, width: "10px", slot:true },
            { text: "B", value: "bloqueada", slot:true, style:"color:red"},
            { text: "T", value: "tipo_ope", style:"color:steelblue;font-weight:bold"},
            { text: "SUP", value: "suplido", slot:true},
            { text: "FACTURA", value: "fra_doc"},
            { text: "IMPORTE", value: "imp", style:"text-align:right", filtro:"num", arg:2 },
            { text: "P", value: "xpago", style:"color:green;font-weight:bold"},
            { text: "CUENTA", value: "name_cta_id" },
            { text: "DESCRIPCIÓN", value: "descrip" }            
          ]}
        ];

        this.headerGrid= this.headerSet();
      },


      // añado APIS particulares
      iniDataApi() {
        this.apiArgs.get= ['', 'get', { id:0, aux:this.stIni.getRecordsArray }, 'expediente'];
        this.apiArgs.fusion= ['', 'fusionLineas', { id:0, tipo:"fras" }, 'presupuesto'];
      },

      //
      async stRecordsGet(record, accion, origen) {
        // apiArg: llamada API definida en el particular. 
        // args: argumentos a fusionar con los definidos en 'args' de apiArgs

        let param = { apiArg: this.apiArgs.get, args: { id:this.expediente.id } };
        console.log("stRecordsGet *************************:", param);
        return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore, origen });
      },
      // override llamada API para obtener registros GRID
      // TEMPORAL. CUANDO EXPTES SEA LA NUEVA VERSIÓN SE ELIMINA
      /* async stRecordsGet() {
        // apiArg: llamada API definida en el particular. 
        // args: argumentos a fusionar con los definidos en 'args' de apiArgs
       
        let param= { apiArg:this.apiArgs.get, args:{ id:this.expediente.id }};      
        let apiResult = await this.$store.dispatch("apiCall", param);

        //pendiente: controlar error
        console.log("generar apiResult:",apiResult)
        console.log("stRecordsGet *************************:", param);      

        if (apiResult.sql.error) {
          // blanqueo records
          this.$store.commit(this.storeName + '/recordSet', []);
          return;
        }

        // actualizo records
        this.$store.commit(this.storeName + '/recordReadSet', apiResult.r);  
      }, */


      // gancho para búsqueda de datos particular si el origen es disparoSt y tiene getRecordsArray
      // apiCall para recargar los elementos del grid
      // SOLO CUANDO EXPTES SEA LA NUEVA VERSIÓN
      /* async callRecordsDisparo() {   
        console.log('callRecordsDisparo');     
        let param = { apiArg: this.apiArgs.exps, args: { id:this.masterField }};        
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return [];
        }

        return apiResult.r[0];
      }, */


      async refresco() {        
        this.storeUpdate('refresh');
      },


      // recolecto datos de la operación y la ejecuto
      // pasamos objeto operacion al MD como propiedad llamada 'operacion'
      async clickOperacion(accion) {        
        // recolectaF en mixOperacion
        let operacion= await this.operacionFromFinder(accion);        
        if (operacion=== false) return;
       

        // ejecuto la funcion 'operacion' en el MD  
        this.newOperacion= operacion;        
        this.execAccion({ accion:'execOperacion' });
      },      

    },


    computed: {
      cerrada(){
        /* if (this.padre) {
          let record=this.$store.state[this.padre].record
          
          if (this.app==2 && (record.chkconf24==1 || record.chkpresapd==1))  return true
          if (record.chkconfadmon==1) return true
          if (record.chkconfcierre==1) return true
        }
        return false */
        //if (this.masterStore) {
          if (this.app==2 && (this.expediente.chkconf24==1 || this.expediente.chkpresapd==1))  return true;
          if (this.expediente.chkconfadmon==1) return true;
          if (this.expediente.chkconfcierre==1) return true;
        //}

        return false;
      },


      expediente() {
        return this.recordAux;
      }
    }
  };
</script>
