<!-- Mto expansible Presupuestos Expedientes -->

<template>
  <div class="expPresMX" v-if="loaded">
    <v-sheet class="contenedor"> 

        <!-- Botones Mto -->
        <div class="pb-2 d-flex justify-left" v-if="!masterNoEdit">
          <baseBtraMto class="conflex" style="width:230px;"           
            :modulo="btMtoCfg" 
            :estado="estado"
            @onEvent="execAccion">        
          </baseBtraMto>        
        </div>

        <!-- Controles del Mto -->            
        <div style="display:flex">
          <uiText
            v-bind="$input"
            style="width:50%"                        
            v-model="ct.name_prod_id[2]"
            :label="ct.name_prod_id[1]"
            readonly>                          
          </uiText>

          <uiText
            v-bind="$input"
            style="width:50%;margin-left:5px"
            v-model="ct.namea[2]"
            :label="ct.namea[1]"
            :disabled="noEdit">
          </uiText>    
        </div>

        <div class="conflex">
          <div class="conflex" style="align-items:center;width:35%">
          
            <v-checkbox              
              v-bind="$input"
              v-model="ct.sp[2]"
              color="red"
              label="PE"
              true-value="1"
              false-value="0"
              :disabled="noEdit"
              dense>            
            </v-checkbox>

            <v-checkbox
              style="margin-left:5px"
              v-bind="$input"
              v-model="ct.grup[2]"
              label="Traslado"
              true-value="T"
              false-value="P"
              :disabled="noEdit">            
            </v-checkbox>
            
            <v-checkbox
              style="margin-left:5px"
              v-bind="$input"
              v-model="ct.sup[2]"
              label="Suplido"
              color="green"
              true-value="S"
              false-value="N"
              :disabled="noEdit"
              @change="changeSuplido">            
            </v-checkbox>

          </div>

          <div class="conflex" style="width:65%">
            <uiText
              v-bind="$input"
              style="width:15%"                        
              v-model="ct.und[2]"
              :label="ct.und[1]"
              format="entero"            
              :disabled="noEdit"
              @blur="calculoImporte">
            </uiText>

            <v-select 
              style="width:20%;margin-left:5px"
              v-model="ct.iva[2]"
              v-bind="$select"
              label="IVA"
              :items="getIvas"
              :disabled="noEdit || ct.sup[2]==='S'"
              item-value="value">
            </v-select>
                      
            <uiTf                        
              styleComp="width:30%;margin-left:5px"                        
              :ct="ct.pvp"             
              mask="-####.##"
              simbolo="€"
              :disabled="noEdit"
              :fntKey="quitIva"
              @blur="calculoImporte">
            </uiTf>         

            <uiText            
              v-bind="$input"
              style="width:31%;margin-left:5px"                        
              v-model="ct.imp[2]"
              :label="ct.imp[1]"
              format="money"
              readonly>
            </uiText>  
          </div>       
        </div>      
    </v-sheet>
  </div>
</template>



<script>

  import { mixMX } from "@/mixins/mixMX.js";
  import baseBtraMto from "@/base/baseBtraMto";
  
  export default {    
    mixins: [mixMX],
    components: { baseBtraMto },
    props: {    
      selectProduct: { type:Object, default:null },
      rt: { type:String, default:'0' },
      iva_general: { type:String, default:'0' },
      ope: { type:String, default:'' },
    },

    
    methods: {

      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
        this.line2End= true;       
      },


      // gancho final para todas las operaciones de CRUD
      crudFin() {
        if (this.ct.iva[2]) return;
        this.$set(this.ct.iva, 2, this.iva_general);   
      },
            

      // recalculo importe linea detalle
      calculoImporte() {               
        this.$set(this.ct.imp, 2, this.round(Number(this.ct.und[2]) * Number(this.ct.pvp[2])));
      },
      
      
      // comprobación de datos antes de guardar
      validateParticular() {
        // obtengo regimen tributario segun el rt recibido como propiedad
        let rt_ivas= this.$store.state.datos_iniciales.rTribut[this.rt].tipos.map(i=>Number(i.value));        
        let iva = Number(this.ct.iva[2]);
       
        // validar si es factura de suplido el iva y suplido
        // let M=this.$store.state[this.padre].padre;
        //M=this.$store.state[M];


        // caso presupuesto/factura de SUPLIDO chequeo iva y sup

        // if(M.schema.ctrls.suplido.value=="1" && this.schema.ctrls.sup.value!="S"){
        //       this.$root.$alert.open('Este tipo de presupuesto/factura SUPLIDO solo admite Suplidos', 'error' )
        //        return false;
        // }


        // caso presupuesto/factura de SUPLIDO chequeo iva y sup
        if(this.masterSyncRecord.suplido=="1" && this.ct.sup[2]!="S"){
          this.$root.$alert.open('Este tipo de presupuesto/factura SUPLIDO solo admite Suplidos', 'error' )
          return false;
        }


        // comprueba si es suplido y ha metido iva
        if(this.ct.sup[2]==="S" && Number(this.ct.iva[2])!=0) {
          this.$root.$alert.open('IVA/SUPLIDO Incompatible', 'error' )
          return false;
        }

        // Comprueba si no ha dejado el producto en blanco 
        if (this.ct.name_prod_id[2] === "") {
          this.$root.$alert.open("Debe introducir producto/servicio", "error");
          return false;
        }

        // comprueba si es un producto con un iva al 10%     
        console.log('IVA al 10%:', this.ope, this.$store.state.datos_iniciales.iva10, Number(this.ct.prod_id[2]));
        if (this.ope=='V' && this.$store.state.datos_iniciales.iva10.includes(Number(this.ct.prod_id[2])) && Number(this.ct.iva[2])> 10) {
          this.$root.$alert.open('Producto con % IVA incorrecto', 'error' )
          return false;
        }
          
      

        // Comprueba si no ha intoroducido unidades 
        if (Number(this.ct.und[2]==0 || Number(this.ct.und[2]>5000 || isNaN(this.ct.und[2])))) {
          this.$root.$alert.open("Error en unidades de producto", "error");
          return false;
        }
          
        // comprueba si ha introducido precio
        if (isNaN(this.ct.pvp[2])) {
          this.$root.$alert.open("Error en precio de producto", "error");
          return false;
        }
        //pendiente: los records estan en el padre y en la cabera, que es mejor coger?
          
        // Ver si hay productos duplicados
        let records= this.records;
        if( records.find(elem => (elem.prod_id==this.ct.prod_id[2] && elem.namea==this.ct.namea[2] && elem.id!=this.ct.id[2]))) {
          this.$root.$alert.open("Producto/servicio duplicado","error");
          return false;
        }
          
        if (rt_ivas.includes(iva)) return true;

        this.$root.$alert.open(
          `Revise: Tipo de Impuesto ${this.$store.state.datos_iniciales.rTribut[this.rt].name}   producto ${iva}%`,
          "error");
          return false;
      },


      //
      changeSuplido(val) {      
        if (val== 'S') {        
          this.$set(this.ct.iva, 2, "0.0");
          return;
        }

        this.$set(this.ct.iva, 2, this.iva_general);
      },


      // entro al seleccionar un producto 
      // actualizo nombre e id del producto en controles del MX
      changeSelectProducto() {
        console.log('changeSelectProducto;; ', this.selectProduct);
       
        if (this.selectProduct== null || typeof this.selectProduct=== 'undefined') return;
        let item= this.selectProduct;

        // Paso nombre de producto a control del xm añadiendole la clave al literal
        this.$set(this.ct.prod_id, 2, item.id);       
        this.$set(this.ct.name_prod_id, 2, ("(" + item.clave + ") " + item.name));
        this.$set(this.ct.clave, 2, item.clave);
                     
        // Paso al record del mx la clave porque no tiene ctrl
        //this.$store.state[this.storeName].record.clave =   item.clave;
      },


      // elimina IVA campo importe
      quitIva(e) {        
        if (e.key!= 'F1') return null;        
        if (!this.ct.iva[2] || e.target.value<= 0) return null;

        // obtengo porcentaje de IVA y le resto el IVA al importe
        let iva= (100 + Number(this.ct.iva[2])) / 100;
        let base= this.round(e.target.value / iva);

        // para evento y devuelve valor a modificar
        e.preventDefault();
        return base;       
      }

    },


    computed: {

      getIvas() {          
        if (this.rt!= '') {
          return this.$store.state.datos_iniciales.rTribut[this.rt].tipos;
        }

        return[]; 
      },

    },



    watch: {

      // salta al seleccionar un producto
      selectProduct: {
        handler() {
          this.changeSelectProducto();
        }
      },


    }


  };
</script>
