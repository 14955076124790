<!-- Pago Directo de Facturas  -->

<template>
  <div class="cobrosPagosDirecto" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">
          <div class="columna">

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor">

                <!-- Botoneras -->
                <div class="botonera">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>
                </div>

                <!-- <v-btn
                  v-bind="$cfg.btra_cfg.standard"
                  title="Pago Directo"
                  @click="pago()">
                    <v-icon>{{'mdi-check'}}</v-icon>
                </v-btn> -->

                <!-- Controles del Mto -->
                <div class="columna" style="width:600px;padding-top:10px">

                  <uiText
                    style="margin-top:10px"
                    styleLabel="width:80px"
                    v-model="recordAux.name_cta_id"
                    label="Cuenta"
                    labelSide
                    readonly>
                  </uiText>

                  <div class="conflex">
                    <uiText
                      style="width:50%"
                      styleLabel="width:80px"
                      v-model="recordAux.fra_doc"
                      label="Factura"
                      labelSide
                      readonly>
                    </uiText>

                    <uiText
                      style="width:50%"
                      styleLabel="width:80px"
                      v-model="recordAux.imp"
                      label="Importe"
                      labelSide
                      format="money"
                      readonly>
                    </uiText>
                  </div>

                  <div class="conflex">
                    <uiText
                      style="width:50%"
                      styleLabel="width:80px"
                      v-model="recordAux.fra_pago"
                      label="Pagado"
                      labelSide
                      format="money"
                      readonly>
                    </uiText>

                    <uiText
                      style="width:50%"
                      styleLabel="width:80px"
                      v-model="pte"
                      label="Pendiente"
                      labelSide
                      format="money"
                      readonly>
                    </uiText>
                  </div>

                  <div class="conflex">
                    <v-select
                      v-bind="$select"
                      class="green lighten-5"
                      style="width:50%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                      v-model="ct.banco[2]"
                      :label="ct.banco[1]"
                      :items="$store.state.datos_iniciales.pago_bancos"
                      item-value="d"
                      item-text="n">
                    </v-select>

                    <uiDate
                      class="green lighten-5"
                      style="width:50%"
                      v-model="ct.fh[2]"
                      :label="ct.fh[1]">
                    </uiDate>
                  </div>

                  <uiText
                    class="green lighten-5"
                    v-model="ct.imp[2]"
                    :label="txtPagar"
                    format="money">
                  </uiText>
                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";

  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraExtra, dualTemplate },
    props: {},

    data() {
      return {
        stIni: {
          api: "cobrosPagosDirectoM",
          name:"cobrosPagosDirectoM",
          titulo:"·Cobro/Pago Factura Directo",
          recordAccess:"",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },


      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'pago', icono: "mdi-check", title:"Pago Directo" }
        ];

        this.$set(this.ct.fh, 2, this.actualDate());
        this.$set(this.ct.imp, 2, this.pte);
      },


      //
      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.contawin= ["", 'pagos', { fnt:'directo', fra:0, imp:0, fh:'', ban:'' }, "facturas"];
      },


      //
      pago() {
        if (Number(this.ct.imp[2])==0) {
          this.$root.$alert.open('Debe de asignar un IMPORTE', 'error');
          return;
        }

        this.$root.$alert.open('PAGO DIRECTO FACTURA ?<br>Factura: <b>' + this.recordAux.fra_doc + '</b><br>Importe: <b>' + this.ct.imp[2] +  '</b><br>' +
          '<br>*  Recuerde que el importe que introduce puede no cancelar la factura.<br><br>'
          , 'confirm')
        .then(r=> { if (r) this.pagamos(); });
      },

      async pagamos() {
        let param = { apiArg: this.apiArgs.contawin, args: { fra: this.recordAux.id, imp: this.ct.imp[2], fh:  this.ct.fh[2], ban: this.ct.banco[2], }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Pago Realizado Asiento ' + apiResult.r[1] , 'success', 1800);
        this.$emit('refresh');
      },

      /* pago_parcial() {
        if (this.ct.pte[2]=="0") {
          this.$root.$alert.open('Debe de asignar una cantidad', 'error');
          return;
        }

        // cojo objeto record recibido y le modifico el campo pte
        let record= this.accionRow;
        record.pte= this.ct.pte[2];

        this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: record, id: record.id });
        this.$emit('update_parcial', { record:record });
        this.$emit('onEvent', { accion:'cerrar' });
      } */

    },



    computed: {

      //
      sch() {
        return {
          fh: [ 'fh', 'Fecha', "", [],
            {comp: {
              type:'fecha',
              format:'fh'
            }}
          ],
          banco: [ 'banco', 'Banco', "1" ],
          imp: [ 'imp', 'Importe a Pagar', "" ]
        }
      },


      //
      txtPagar() {
          return (this.recordAux.tipo_ope=='V'?'A COBRAR':'A PAGAR')+' (importe)';
      },


      // importe pendiente
      pte() {
          return this.recordAux.imp - this.recordAux.fra_pago;
      },

    },

  };
</script>
