<!-- Envio Email Facturas Emitidas -->

<template>
  <div class="frasExpEmail" v-if="loaded">   
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">
          <div class="columna">      

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='stIni.titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor">               
          
                <!-- Botoneras -->
                <div class="botonera">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>                
                </div>          

                <!-- Controles del Mto -->                
                <div class="ctrls conflex contenedor max-width:1240px">
                  <div class="columna" style="width:550px">
                  
                    <div class="conflex">
                      <uiText
                        style="width:33%"                        
                        v-model="ct.fra_doc[2]"
                        :label="ct.fra_doc[1]"
                        readonly>          
                      </uiText>         

                      <uiText
                        style="width:33%;margin-left:5px"
                        v-model="ct.fra_fh[2]" 
                        label="Fecha"
                        readonly>          
                      </uiText>
                      
                      <uiText
                        style="width:32%;margin-left:5px"
                        v-model="ct.imp[2]"
                        :label="ct.imp[1]"
                        format="money"
                        readonly>          
                      </uiText>
                    </div>
              
                    <div class="conflex">
                      <uiText
                        v-show="recordAux.exp_id> 0"
                        style="flex: 0 0 20%"
                        v-model="ct.control[2]"
                        :label="ct.control[1]"
                        readonly>          
                      </uiText>
                    
                      <uiText
                        :style="recordAux.exp_id> 0 ? 'flex: 0 0 79%' : 'flex: 0 0 100%'"
                        v-model="ct.name_cta_id[2]"
                        :label="ct.name_cta_id[1]"
                        readonly>          
                      </uiText>
                    </div>

                    <uiText
                      v-model="ct.email_cta_id[2]"
                      :label="ct.email_cta_id[1]">          
                    </uiText>

                    <v-select 
                      v-show="recordAux.id> 0"
                      v-bind="$select"
                      v-model="ct.adjuntos[2]"
                      :label="ct.adjuntos[1]"            
                      :items="itemsAdjuntos" 
                      item-value="id"
                      item-text="name"          
                      multiple>
                    
                      <template v-slot:selection="{ index }">              
                        <span  
                          v-if="index=== 0"              
                          class="grey--text caption">                
                            ({{ (ct.adjuntos[2]).length }} documentos adjuntos)
                        </span>
                      </template>
                      
                    </v-select>
              
                    <v-textarea
                      v-bind="$textarea"
                      v-model="ct.obs[2]"
                      :label="ct.obs[1]"
                      rows="3"
                      no-resize>                                           
                    </v-textarea>

                  </div>
                </div>
              </div>
            </v-sheet> 
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
    
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraExtra, dualTemplate },
    props: {},

    data() {
      return {
        stIni: {
          api: "frasExpEmailM",
          name:"frasExpEmailM",
          titulo:"Admon - Impresión / Envio Facturas Emitidas",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,       
          mView:'',
          pView:[]
        },
        
        itemsAdjuntos: []
      };
    },

    
    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'sendEmail', btn: "sendEmail" }
        ];
       
      },

      
      // (override) apiArgs particular
      iniDataApi() {
        this.apiArgs.lista= ["", 'lista', { tipo:0, tip_id:0 }, "documento"];
        this.apiArgs.email= ["", 'venta_email', { id:0, ema:'', txt:'', adj:[] }, "facturas"];
      },


      // (override) obtengo datos auxiliares (documentos fra)
      async getDataAux() {      
        let param = { apiArg: this.apiArgs.lista, args: { aux:1, tip_id:this.recordAux.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }  

        //
        this.itemsAdjuntos= apiResult.r[0];
      },


      // envio factura por email
      async sendEmail() {   
        let param = { apiArg: this.apiArgs.email, args: { 
          id:this.masterField, 
          ema:this.ct.email_cta_id[2],
          txt:this.ct.obs[2],
          adj:this.ct.adjuntos[2] }
        };

        let apiResult = await this.$store.dispatch("apiCall", param);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       
        this.$root.$alert.open('Email enviado correctamente!', 'success', 1800);

        // actualizo record de la fra seleccionada
        await this.masterStoreUpdate('refresh');

        // cierro ventana
        this.$emit('onEvent', { accion:6 });
      },

    }
  };
</script>
