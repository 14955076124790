import { mixCt } from "@/mixins/mixCt";
import { mixSchValidate } from "@/mixins/mixSchValidate";

export var mixMX = {
  mixins: [mixCt, mixSchValidate],
  props: {  
    sync: { type: Boolean, default: false },              // vista activa del mto sincronizada con el masterField del masterStore
    syncAccion: { type: [String, Number], default: 0 },   // acción a ejecutar al sincronizar
    syncDisparo: { type:Boolean, default:false },         // switch que al cambiarlo ejecuta el disparo del componente
    syncUpdate: { type: Boolean, default: false },        // (true/false)). True: actualiza su masterStore                                                          
    storeRaiz: { type: String, default: "" },             // nombre del store del que cuelga el componente
    masterStore: { type: String, default: '' },           // this.id= this.$store.state[this.masterStore].record[this.masterRecordField] (store relación)
    masterRecordField: { type: String, default: 'id' },   // nombre de campo donde extraigo el id relacionado  
    storeAux: { type: String, default: "" },              // store auxiliar    
    recordAux: { type: Object, default: null },           // record auxiliar
    mxN: { type: String, default: "1" },                  // 
  },

  data() {
    return {   
      raiz:'',
      storeName:this.masterStore,
      ct:0, 
      apiArgs: {},
      //
      btMtoCfg:null,
      loaded:false,
      line2End:false
    }
  },
  
  

  
  async created() {
   
    // inicializo datos auxiliares generales (Mixin)
    this.iniDataMixin();   

    // inicializo apiArgs
    this.iniDataApi();

    // inicializo variables Btra
    this.iniBtra();
    
    // cargo iniDataParticular porque se supone que ya está todo cargado
    this.ct = JSON.parse(JSON.stringify(this.sch));

    // inicializo datos particulares
    await this.iniDataParticular();

    // funciones a ejecutar una vez cargado totalmente el componente
    this.loadedComponent();
    
  
    // watch's:    
    if (this.sync) {   
       
      this.$watch("masterField", {
        immediate: true,
        handler() {    
          console.log('watch MASTERFIELD');
          this.watchAccion('masterField');          
        }
      });

    }else {      
      this.$watch("syncDisparo", {       
        handler() {  
          console.log('watch SYNCDISPARO');        
          if (!this.masterField) return;      
          this.watchAccion('syncDisparo');
        }
      });
    }
    
  },


  methods: {

    // ini Data General
    iniDataMixin() {
      console.log("DEV  ********************** (iniDataMixin)");  
    },

    // override de apiArgs en particular en caso que sea necesario
    iniDataApi() {},

    // inicializo variables Btra Mto Array
    iniBtra() {
      this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
    },
    
    // inicializo datos particulares
    iniDataParticular() {},


    // entro cuando el componente se ha cargado
    loadedComponent() {    
      
      // indico que el componente ya se ha cargado
      this.loaded= true;
      
      // viewMtoOnly:true (normalmente viene de un ctrlF)
      // actualizo ID, con el recibido en el ctrlCt, la acción y ejecuto disparo
      /* if (this.viewMtoOnly) {      
        this.$store.commit(this.storeName + `/accionSet${this.mxN}`, { id: this.ctrlCt[2], accion:0 });
        this.$store.commit(this.storeName + '/disparoSet');
        return;
      } */

      // emito evento por loaded por si lo recoge alguna funcion en el padre
      // por si hay que realizar alguna operación después de la carga del componente
      this.$emit('loaded', this.storeName);

      // si el componente esta sincronizado, el watch del masterField tiene un inmediate:true que 
      // lanzaría automáticamente el watchAccion. Cortarlo aquí evita que se vuelva a repetir al hacer la carga del componente
      if (this.sync) return;
      //      
      this.loadedAccion();
    },

    loadedAccion() {      
      // funcion que se ejecuta cuando está cargado el componente NO sincronizado
      this.watchAccion('loaded');
    },


    // ---- Acciones Watch ---------------------------------------------------------  
    watchAccion(origen, accion = this.syncAccion) {
      console.log("DEV ********** watchAccion (MIXMX). Origen: ", origen, ', Accion: ', accion);

      //origen=='syncDisparo'
      //origen=='masterField'      
      this.setStoreAccion(accion);
      this.watchAccionExec();
    },

    //
    setStoreAccion() {
      console.log("DEV ********** setStoreAccion (MIXM)");

      this.$store.commit(this.storeName + `/accionSet${this.mxN}`, { id: this.masterField, accion:this.syncAccion });
      this.$store.commit(this.storeName + '/modalSet', true);
    },

    //sw
    watchAccionExec() {    
      // si recordsSelected es un array vacío, salgo      
      if (!this.recordsSelected.length) return;

      // si la acción a ejecutar es un 'nuevo' y tiene ID, salgo
      if (this.accion== 1 && (this.ID> 0)) return;

      // si recordsSelected tiene datos, actualizo ID y ejecuto acción recibida
      //this.$store.commit(this.store + '/data2State', { prop:`ID${this.mxN}`, value:this.recordsSelected[0].id });     
      this.execAccion({ accion:this.accion });
    },
  //---------------------------------------------------------------------------

  
  // call Acciones MX ---------------------------------------------------------
    async execAccion(evt) {
      console.log("DEV braMto ********** MIXMX", evt);

      // (0:'ver', 1:'nuevo', 2:'editar', 3:'eliminar', 4:'guardar', 5:'cancelar', 6:'salir')
      const options = [
        "ver",
        "nuevo",
        "editar",
        "eliminar",
        "guardar",
        "cancelar",
        "salir"
      ];
      let accion = /^([0-9])*$/.test(evt.accion)
        ? options[evt.accion]
        : evt.accion;

      if (this.$isFunction(this[accion])) this[accion](evt);
    },
  //---------------------------------------------------------------------------
  

  // acciones MX --------------------------------------------------------------

    // muestro registro en modo NO edición
    async ver() {
      // call Store
      let record = await this.stVer();     
      await this.showRecord(record);
      
      // gancho después de ver
      this.verFin();
    },

    verFin() {
      this.crudFin();
    },
        

    // muestro registro en modo edición
    async editar() {
      // call Store
      var record= await this.stEditar();
      await this.showRecord(record);

      // gancho después de 'editar''
      this.editarFin();
    },

    editarFin() {
      this.crudFin();
    },


    // nuevo registro
    async nuevo() {
      // call Store
      await this.stNuevo();
      await this.showRecord(this.masterSyncRecordMX);      
    
      // gancho después de nuevo
      this.nuevoFin();
    },

    nuevoFin() {
      this.crudFin();
    },

    
    async showRecord(record) {
      // pendiente: controlar error
      console.log("record MX: ", record);

      // guardo controles en ct y le paso los datos del registro leido
      this.ct = JSON.parse(JSON.stringify(this.sch));
      await this.record2ctrl(record, this.ct, this.estado=='nuevo');

      // gancho por si necesitamos cargar datos auxiliares
      // entro DESPUÉS de obtener los datos del Mto
      await this.getDataAux();     
    },


    // gancho por si necesitamos cargar datos auxiliares
    // entro DESPUÉS de obtener los datos del Mto
    getDataAux() {},


    // cancelo la edición del registro
    async cancelar() {  
      // si hay cambios, muestro pregunta para cancelarlos
      // si NO hay cambios, cancelo el registro directamente       
      if (this.changes()!='') {
        this.cancelarPregunta();

      } else {
        this.cancelarSi();
      }
    },

    async cancelarPregunta() {
      // muestro pregunta para confirmar la cancelación
      await await this.$root.$alert
      .open("¿ CANCELAR los cambios realizados ?", "confirm", null, [
        "NO",
        "SI"
      ])
      .then(r => {
        if (r) this.cancelarSi();
      });
    },
  
    cancelarSi() {
      // si estado es NUEVO, actualizo datos Store y salgo
      if (this.estado == "nuevo") {    
        this.stCancelar();        
        return;
      }

      // estado EDITAR. Actualizo datos Store y hago un VER
      this.stCancelar();
      this.execAccion({ accion: 0 });      
    },

    
    // guardar registro
    async guardar() {
      
      // valido datos particulares
      if (!this.validate()) return;

      // paso datos de controles a record (aunque no hayan cambiado)
      let recordChanges = this.ctrl2record(this.ct, this.recordsSelected, false, false);
      console.log('recordChanges:: ', recordChanges);

      //
      // CAMPO RELACIONADO    
      // para rellenar un campo relacionado es necesario:
      // Que sea nuevo
      // tener definido como computed relationID en el particular (de alguna propiedad recordAux o storeAux)
      /* if (this.estado == "nuevo" && (this.stIni.relation && this.relationID > 0)) {         
          recordChanges[this.stIni.relation] = this.relationID;
      } */
      
      // call Store
      var index = await this.stGuardar(recordChanges);
            
      // si recibo -1 muestro error, si no salgo
      if (index< 0) {
        this.$root.$alert.open('Error al guardar registro', 'error', 2000);
        return;        
      }

      // gancho
      this.guardarFin();      
    },

    // gancho al terminar de guardar record MX.
    // emito evento para que el padre sepa que he actualizado el MX
    guardarFin() {        
      this.$emit('updatedMX');
      this.crudFin();
    },
    

    // elimino el registro seleccionado
    async eliminar() {
      // muestro pregunta para confirmar la eliminación
      await await this.$root.$alert
      .open("¿ Desea eliminar el registro ?", "confirm", null, [
        "NO",
        "SI"
      ])
      .then(r => {
        if (r) this.eliminarSi();
      });
    },
   
    eliminarSi() {
      // call Store
      this.stEliminar();  

      // Pendiente: controlar posible error en la eliminación

      // gancho
      this.eliminarFin();
    },

    // gancho al terminar de eliminar record MX.
    // emito evento para que el padre sepa que he actualizado el MX
    eliminarFin() {
      this.$emit('updatedMX');
      this.crudFin();
    },

   
    // cerrar expansible
    salir() {    
      // call Store
      this.stSalir();
    },


    // gancho final para todas las operaciones CRUD
    crudFin() {},
    //---------------------------------------------------------------------------


    // ---- Acciones Store ---------------------------------------------------------
    stVer() {
      console.log("stVerMX*************************:")
      return this.$store.dispatch(this.storeName + `/ver${this.mxN}`);
    },

    stEditar() {    
      console.log("stEditarMX*************************:");
      return this.$store.dispatch(this.storeName + `/editar${this.mxN}`);
    },

    stCancelar() {
      return this.$store.dispatch(this.storeName + `/cancelar${this.mxN}`);
    },

    stNuevo() {
      return this.$store.dispatch(this.storeName + `/nuevo${this.mxN}`);
    },

    stEliminar() {
      return this.$store.dispatch(this.storeName + `/eliminar${this.mxN}`);
    },

    stGuardar(record) {      
      // paso record a guardar y posición donde guardar en caso que sea nueva linea (line2End= true. Al final)
      return this.$store.dispatch(this.storeName + `/guardar${this.mxN}`, { record: record, line2End:this.line2End });
    },

    stSalir() {
      return this.$store.dispatch(this.storeName + `/salir${this.mxN}`);
    },
    //-----------------------------------------------------------------------


    // compruebo si hay cambios    
    changes() {
      // si estado es NUEVO, devuelvo ''
      if (this.estado== 'nuevo') return '';

      // si NO hay cambios, devuelvo ''
      let recordChanges = this.ctrl2record(this.ct, this.masterSyncRecordMX, true);     
      if (!Object.keys(recordChanges).length) return '';
      return 'GUARDE o CANCELE los cambios para continuar';
    },

    validate() {
      if (!this.validateParticular()) return false;
      return true
    },

    validateParticular(){
      return true;
    }
  },


  computed: {
  // variables STORE ---------------------------------------------------------

    // devuelvo el valor del campo masterRecordField del record sincronizado      
    masterField() {
      if (typeof this.masterSyncRecordMX[this.masterRecordField]=== 'undefined') return null;     
      if (this.masterSyncRecordMX[this.masterRecordField]=== "") return null;
      return this.masterSyncRecordMX[this.masterRecordField];
    },

    ID() {
      return this.$store.state[this.storeName].ID1;
    },
    
    accion() {           
      return this.$store.state[this.storeName].accion1;
    },

    noEdit() {
      return this.$store.getters[this.storeName + "/noEdit1"];
    },

    masterNoEdit() {
      return this.$store.getters[this.storeName + "/noEdit"];
    },

    /* noEdit1(){
      return this.$store.getters[this.storeName + "/noEdit1"];
    }, */

    /* estado() {
      return this.$store.state[this.storeName].estado;
    }, */

    perm() {
      return this.$store.state[this.storeName].perm;
    },

    /* sch() {
      return this.$store.state[this.storeName].sch;
    }, */
    
    sch() {      
      return this.$store.state[this.storeName].sch1;
    },

    estado() {
      return this.$store.state[this.storeName].estado1;
    },

   /*  btra() {
      return this.$store.state[this.storeName].btra;
    }, */

    /* record() {
      return this.$store.state[this.storeName].record;
    }, */
    masterSyncRecord() {
      return this.$store.state[this.storeName].record;
    },

    records() {
      return this.$store.state[this.storeName].records1;
    },

    recordsSelected() {
      return this.$store.state[this.storeName].recordsSelected;
    },

    /* change() {
      return this.$store.state[this.storeName].change;
    }, */

    change() {
      return this.$store.state[this.storeName].change1;
    },

    relation() {
      return this.$store.state[this.storeName].relation1;
    },

    permExtra() {
      return this.$store.state[this.storeName].permExtra;
    },

    // records seleccionados del componente maestro asociado
    masterSyncRecordMX() {      
      return this.$store.getters[this.storeName + "/syncRecordMX"];        
    }
  //---------------------------------------------------------------------------
    
  },


  // 
  beforeDestroy() {},


};
