<!-- Finder Facturas Expedientes -->

<template>
  <div class="frasExpF" v-if="loaded">

    <dualTemplate
      :dualTipo="!storeRaiz? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">
    
          <v-sheet v-if="!viewMtoOnly" :elevation="4">

            <!-- Cabecera -->
            <div class="cabecera" v-if="!viewMtoOnly" >     
              <baseHeader
                :cfg="$cfe.headers.finder"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader"> 
              </baseHeader>
            </div>
        
            <!-- Contenido -->
            <div class="contenedor">
                  
              <!-- <div v-html="info"></div> -->
          
              <!-- Filtro de Búsqueda -->
              <div class="conflex" style="justify-content:center">
                <div class="columna" style="min-width:650px;width:49%;">
                  
                  <div>
                   
                    <!-- Filtro de Búsqueda -->    
                    <div class="cab">SELECCIÓN DE DATOS</div> 
                    <v-sheet v-bind="$cfg.styles.marco">                
                      <div style="display:flex; align-items:center">                     
                        <uiMultiFilter
                          style="width:42%"
                          :items="itemMultifilter"
                          :ct="ct"
                          :searchFn="eventFiltro"
                          @buscar="eventFiltro($event)"
                          @onChangeMenu="currentCtrlMultiFilter=$event.ctrl">
                        </uiMultiFilter>


                        <v-select
                          style="width:28%;padding-left:5px"
                          v-bind="$select"
                          v-model="ct.tip[2]"
                          :label="ct.tip[1]"
                          :items="$store.state.datos_iniciales.fact_tipo"
                          item-value="d"
                          item-text="n"
                          @change="changeTipo">
                        </v-select>

                        <v-select
                          style="width:15%;padding-left:5px"
                          v-bind="$select"
                          v-model="ct.hora[2]"
                          :label="ct.hora[1]"
                          :items="$store.state.datos_iniciales.fact_hora"
                          item-value="d"
                          item-text="n"
                          @change="eventFiltro('hora')">
                        </v-select>

                        <v-select
                          style="width:15%;padding-left:5px"
                          v-bind="$select"
                          v-model="ct.min[2]"
                          :label="ct.min[1]"
                          :items="$store.state.datos_iniciales.fact_min"
                          item-value="d"
                          item-text="n"
                          @change="eventFiltro('min')">
                        </v-select>          
                      </div>

                      <div style="display:flex;padding:10px 10px 10px 0px;justify-content: space-between;">
                        
                        <!-- Botones -->
                        <div>

                          <!-- Botonera Extra (Ver Expediente, Documentos, Comunicados, ...) -->
                          <baseBtraExtra                              
                            :permExtra="permExtra"
                            :modulo="btExtCfg"           
                            @onEvent="execAccion">                          
                          </baseBtraExtra>
                        
                        </div>    

                      
                        <!-- Ordenación filas Grid -->            
                        <div style="display:flex;align-items:center;padding-left:15px;width:60%">                
                          <v-select  
                            style="width:30%"
                            v-bind="$select"
                            v-model="ordenado"
                            label="Ordenado"
                            :items="itemsOrdenacion" 
                            item-value="id"
                            item-text="name"
                            @change="ordenar">
                          </v-select>

                          <v-select
                            style="width:25%;padding-left:5px"
                            v-bind="$select"
                            v-model="tipoOrden"
                            label="Orden"
                            :items="itemsTipoOrden" 
                            item-value="id"
                            item-text="name"            
                            @change="ordenar">
                          </v-select>

                          <uiText 
                            style="width:45%;margin-left:5px"               
                            v-model="txtFiltro"
                            label="Búsqueda filtrada"
                            appendIcon="mdi-magnify"
                            @clickIcon="filtrar"
                            @Enter="filtrar">                
                          </uiText>

                        </div>
                      </div>
                    </v-sheet>                   

                  </div>
                  
                  <v-sheet v-bind="$cfg.styles.marco">
                    <!-- Grid --> <!-- 615px -->
                    <baseGridMD
                      :store="storeName"
                      :headers="headerGrid"
                      :items-per-page="-1"   
                      hide-default-header
                      hide-default-footer       
                      show-select 
                      dense
                      height="615px" 
                      @onEvent="execAccion">
                  
                        <!-- Slot Columna 1 -->
                        <template v-slot:col1="{ item }">
                          {{ item.control }}
                          <div class="light-blue--text text--darken-3"> {{ item.fhhr|fechahr }}</div> 
                        </template>

                        <!-- Slot Columna 2 -->
                        <template v-slot:col2="{ item }">
                          {{ item.fallename }}
                          <div class="light-blue--text text--darken-3"> {{ item.name_callemp }}</div> 
                          <div class="light-green--text text--darken-3"> {{ item.callref1 }}</div>
                        </template>

                        <!-- Slot Columna 3 -->
                        <template v-slot:col3="{ item }">
                          {{ item.fallefhhr|fechahr }}
                          <div class="light-blue--text text--darken-3"> {{ item.name_fune1 }}</div> 
                          <div class="light-green--text text--darken-3"> {{ item.poliza }}</div>
                        </template>                        
                      
                    </baseGridMD>            
                  </v-sheet>
                </div>

                <div class="columna" style="min-width:600px;width:49%;margin-left:5px;">
                  <frasExpFF
                    sync
                    syncAccion="buscar"
                    :storeRaiz="storeName"                
                    :masterStore="storeName"
                    :recordAux="expediente">
                  </frasExpFF>                   
                </div>
              </div>
            </div>
          </v-sheet>


          <!-- Ventana de Componentes Extra  -->
          <!-- paso record expediente en la propiedad recordAux para estandarizarlo -->
          <component      
            :is="componenteDinamico"
            syncUpdate
            :storeRaiz="storeName"
            :masterStore="storeName"
            :syncDisparo="syncDisparoSend"
            :syncAccion="syncAccionSend"
            :recordAux="syncRecord"
            :readOnly="readOnly"
            :viewMtoOnly="viewMtoOnly"
            tipoDocumento='0'
            @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
          </component>

          <!-- Ventana de componente Expedientes (TEMPORAL) -->
          <v-dialog
            v-model="modalExp"
            content-class="modal"
            persistent>
              <div class="centrado">
                <component                 
                    :is="componente_dinamico"
                    :padre="storeName"
                    :accion=0
                    :accionRow="syncRecord"
                    :disparo="disparo"                
                    @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
                </component>           
              </div>
          </v-dialog>
        </template>
    </dualTemplate>  
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import dualTemplate from "@/components/dualTemplate";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import frasExpFF from "@/components/frasExpFF";
  import expDocsF from "@/components/expDocsF";
  import expComunicadosF from "@/components/expComunicadosF";
  import expPhoneF from "@/components/expPhoneF";
  import exp_M from "@/components/exp_M";
  
  export default {    
    mixins: [mixF],
    components: { baseHeader, baseGridMD, dualTemplate, baseBtraExtra, frasExpFF, expDocsF, expComunicadosF, expPhoneF, exp_M },
    props: {
      filtro: { type:String, default:'' },      // texto a filtrar    
      tipoFiltro: { type:String, default:'' },  // filtro a ejecutar en el recordsGet
    },

    data() {
      return {  
        stIni: {
          api: "frasExpF",
          titulo:"Administración - Facturas Expedientes", 
          name:"frasExpF",
          mView:'',
          pView:[],
          selection:'first' // first, preserve, both
        },

        ordenado:'fhhr',
        itemsOrdenacion:[],        
        tipoOrden:"0",
        itemsTipoOrden:[],
        itemMultifilter: [],
        txtFiltro:'',
        currentCtrlMultiFilter:'',

        // variables temporales para mostrar exp_M 
        componente_dinamico:null,          
        disparo:false,
        modalExp:false
      };
    },

    
    methods: {     
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[        
            { text: "", value: "col1", witdh: "10%", slot:true, filtro:"fechahr", ffield:"fhhr" },
            { text: "", value: "col2", witdh: "45%", slot:true, style:"width:45%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" },
            { text: "", value: "col3", witdh: "45%", slot:true, style:"width:45%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" },         
          ]}
        ];

        this.headerGrid= this.headerSet();

        // obtengo datos de GRID
        this.autoLoad= 'tipo';

        // si se recibe filtro, se asigna al control
        if (this.filtro) {           
          this.$set(this.ct[this.tipoFiltro], 2, this.filtro); 
          this.autoLoad= this.tipoFiltro;
        }

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'expedientes', btn: "exp" },
          { accion:'documentos', btn: "docs" },
          { accion:'comunicados', btn: "comunicados" },
          { accion:'telefonos', icono: "mdi-phone", title:"Registro de llamadas" },
          { accion:'listados', icono: "mdi-menu", title:'Listados', menu:[
            { accion:'listado', name:'Pdf', inf:'pdf' },
            { accion:'listado', name:'Excel', inf:'xls' }
          ]},          
          { accion:'refresh', btn:'refresh', title:'Recargar', badge:this.totalExps },
        ];
        
        
        // array de Tipos de búsqueda por texto
        this.itemMultifilter = [
          { ctrl: 'exp', label: 'Expediente' },
          { ctrl: 'fallename', label: 'Fallecido' },
          { ctrl: 'fra', label: 'Factura' },
          { ctrl: 'cta', label: 'Cuenta' },
          { ctrl: 'ref', label: 'Ref. CIA / Póliza' },
          { ctrl: 'fun', label: 'Funeraria' },
        ];

        // asigna el control del primer elemento a la variable 'currentCtrlMultiFilter'
        this.currentCtrlMultiFilter= this.itemMultifilter[0].ctrl;

        
        // array de Campos de Ordenación
        this.itemsOrdenacion= [
          { id:"fhhr", name:'Servicio'},
          { id:"fallename", name:'Fallecido'},
          { id:"name_fune1", name:'Funeraria'},
          { id:"callref1", name:'Referencia'},
          { id:"name_callemp", name:'Compañía'}
        ];

        // array Tipo de Ordenación
        this.itemsTipoOrden= [
          { id:"0", name:'ASC'},
          { id:"1", name:'DES'}
        ];

      }, 


      //
      iniDataApi() {
        this.apiArgs.fichero= ["", 'listado', { tip:0, txt:'', hora:'', time:'', inf:'' }, "facturas"];       
      },


      // configuro estado botones
      botonerasSet() {        
        let NS=(!this.syncRecord || !this.syncRecord.id || this.syncRecord.id==0);        

        this.btnSet(this.btExtCfg, "expedientes", { disabled:NS });
        this.btnSet(this.btExtCfg, "documentos", { disabled:NS });
        this.btnSet(this.btExtCfg, "comunicados", { disabled:NS });
        this.btnSet(this.btExtCfg, "telefonos", { disabled:NS });
        this.btnSet(this.btExtCfg, "listados", { disabled:NS });
      },


      // override recordsGet
      async stRecordsGet({ busqueda, origen }) {        
        let param = { apiArg: this.apiArgs.get, args: { 
          ctrls: {
            tip:this.ct.tip[2],
            txtCtrl:this.currentCtrlMultiFilter, 
            txt:this.ct[this.currentCtrlMultiFilter][2],
            hora:this.ct.hora[2],
            min:this.ct.min[2],           
            ordenado:this.ordenado,
            orden:this.tipoOrden,
            txtFiltro:this.txtFiltro
          }, 
          accion:busqueda 
        }};
                
        return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore, origen });
      },


      // acciones después de cargar los records del GRID
      async recordsGetAfter() {
        if (!this.records.length) return;

        // filtra localmente por el campo 'txtFiltro'
        this.txtFiltro? await this.filtrar() : await this.ordenar();        
      },


      // listado de documentos de Expedientes
      documentos() {
        this.componenteDinamico= 'expDocsF';
      },


      // comunicados de Expedientes
      comunicados() {
        this.componenteDinamico= 'expComunicadosF';
      },


      // registro de Llamadas
      telefonos() {
        this.componenteDinamico= 'expPhoneF';
      },


      // expedientes (versión antigua). TEMPORAL
      async expedientes() {
        // cierra la ventana de expedientes si ya se encuentra abierta
        if (this.$router.currentRoute.name=='exp_M') {
          await this.$router.push({ path:'/' });
        }

        this.componente_dinamico= "exp_M";
        this.disparo= !this.disparo;
        this.modalExp= true;
      },

      // cierra ventana dinámica exp_M. TEMPORAL
      cerrar_dinamico() {
        this.modalExp= false;
        this.componente_dinamico= null;
      },


      // listado Expedientes en PDF
      async listado(item) {
        let param = { apiArg: this.apiArgs.fichero, args: { 
          tip:this.ct.tip[2],          
          txtCtrl:this.currentCtrlMultiFilter,          
          txt:this.ct[this.currentCtrlMultiFilter][2],
          hora:this.ct.hora[2],
          min:this.ct.min[2],
          inf:item.inf,
          ordenado:this.ordenado,
          orden:this.tipoOrden,
          txtFiltro:this.txtFiltro
        }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }  
       
        // abro documento
        window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");
      },


      // recargo finder
      refresh () {
        this.storeUpdate('refresh');
      },


      // entra al cambiar el combo de Situación Expedientes
      // borra el valor del control seleccionado en el uiMultiFilter
      // y realiza una nueva búsqueda de registros del GRID con la acción 'tipo'
      changeTipo() {
        this.$set(this.ct[this.currentCtrlMultiFilter], 2, '');
        this.eventFiltro('tipo');
      },


      // filtrar registros por tipo de documento
      async filtrar() {
        await this.stFiltrar(this.filtrarRecords, {});
        this.ordenar();
      },

      // filtrar por tipo de documento
      // envíamos esta función desde 'filtrar', donde se ejecuta y actualiza los records del Grid
      filtrarRecords(records) {
        return records.filter(elem=> {        
          return (
              elem.name_callemp.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.callref1.toLowerCase().includes(this.txtFiltro.toLowerCase()) || 
              elem.name_fune1.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.name_fune2.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.poliza.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.fallename.toLowerCase().includes(this.txtFiltro.toLowerCase()) ||
              elem.control.toLowerCase().includes(this.txtFiltro.toLowerCase())
            )
        });
      },


      // ordenar filas según campo seleccionado
      ordenar() {       
        let campo= this.ordenado;
        let orden= this.tipoOrden== "0"? 'ASC' : 'DESC';

        this.stOrdenar({ campo, orden });    
      },


      // registros totales
      totalExps() {       
        return this.recordCount;
      },

    },


    computed: {
     
      expediente() {
        return this.syncRecord;
      }
      
    },


    watch: {
      syncRecord() {        
        this.botonerasSet();
      }
    }


  }
</script>


