<template>
  <div class="frasPagos" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">
          <div class="columna">

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor">

                <!-- Botoneras -->
                <div class="botonera">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>
                </div>


                <!-- Controles del Mto -->
                <div class="columna" style="width:600px;padding-top:10px">

                  <uiText
                    style="margin-top:10px"
                    styleLabel="width:100px"
                    v-model="masterSyncRecord.name_cta_id"
                    label="Cuenta"
                    labelSide
                    readonly>
                  </uiText>

                  <uiText
                    styleLabel="width:100px"
                    v-model="masterSyncRecord.fra_doc"
                    label="Factura"
                    labelSide
                    readonly>
                  </uiText>

                  <div class="conflex">

                    <uiDate
                      style="width:50%"
                      styleLabel="width:100px"
                      v-model="masterSyncRecord.fra_fh"
                      label="Fecha"
                      labelSide
                      readonly>
                    </uiDate>

                    <uiText
                      style="width:50%"
                      styleLabel="width:100px"
                      v-model="masterSyncRecord.imp"
                      label="Importe"
                      labelSide
                      format="money"
                      readonly>
                    </uiText>

                  </div>

                  <div class="conflex">
                    <uiText
                      style="width:50%"
                      styleLabel="width:100px"
                      v-model="masterSyncRecord.fra_pago"
                      label="Pagado"
                      labelSide
                      format="money"
                      readonly>
                    </uiText>

                    <uiText
                      style="width:50%"
                      styleLabel="width:100px"
                      v-model="pte"
                      label="Pendiente"
                      labelSide
                      format="money"
                      readonly>
                    </uiText>
                    <!-- <v-text-field
                      class="green lighten-5"
                      v-bind="$input"
                      v-model="schema.ctrls.pte.value"
                      label="a PAGAR (parcial)">
                    </v-text-field> -->
                  </div>

                  <hr class="mt-1">

                  <!-- grid pagos ------------------------------------------------------>
                  <baseGridMD
                    :store="storeName"
                    :headers="headerGrid"
                    :items-per-page="-1"
                    height="200px"
                    hide-default-footer
                    fixed-header
                    show-select
                    dense
                    @onEvent="execAccion">
                  </baseGridMD>

                  <div v-show="syncRecord.id> 0">
                    <div class="conflex">
                      <uiText
                          style="width:33%"
                          styleLabel="width:80px"
                          v-model="syncRecord.id"
                          label="IdPago"
                          labelSide
                          readonly>
                      </uiText>

                      <uiDate
                          style="width:33%"
                          styleLabel="width:80px"
                          v-model="syncRecord.fhp"
                          label="Fecha"
                          labelSide
                          readonly>
                      </uiDate>

                      <uiText
                          style="width:34%"
                          styleLabel="width:80px"
                          v-model="syncRecord.imp"
                          label="Importe"
                          labelSide
                          format="money"
                          readonly>
                      </uiText>
                    </div>

                    <div class="conflex">
                      <uiDate
                          style="width:40%"
                          styleLabel="width:80px"
                          v-model="syncRecord.conta_fh"
                          label="Contab."
                          labelSide
                          type="datetime-local"
                          readonly>
                      </uiDate>

                      <uiText
                          style="width:30%"
                          styleLabel="width:80px"
                          v-model="syncRecord.conta_usu"
                          label="Ope"
                          labelSide
                          readonly>
                      </uiText>

                      <uiText
                          style="width:30%"
                          styleLabel="width:80px"
                          v-model="syncRecord.asi"
                          label="Tram/Asi"
                          labelSide
                          readonly>
                      </uiText>
                    </div>

                    <baseBtraExtra class="conflex"
                      :permExtra="permExtra"
                      :modulo="btExtCfgFooter"
                      :showDisabled=0
                      @onEvent="execAccion">
                    </baseBtraExtra>

                  </div>
                </div>
              </div>
            </v-sheet>


            <!-- Componentes virtuales  -->
            <component
              :is="componenteDinamico"
              syncUpdate
              :syncDisparo="syncDisparoSend"
              :syncAccion="syncAccionSend"
              :storeRaiz="storeName"
              :masterStore="storeName"
              :readOnly="readOnly"
              :viewMtoOnly="viewMtoOnly"
              :recordAux="masterSyncRecord"
              @refresh="actualizar"
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>


<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
  import cobrosPagosDirecto from "@/components/cobrosPagosDirecto";

  export default {

    mixins: [mixF],
    components: { baseHeader, baseGridMD, dualTemplate, baseBtraExtra, cobrosPagosDirecto },
    props: {},

    data() {
      return {
        stIni: {
          api: "frasPagosM",
          name:"frasPagosM",
          titulo:"·FACTURA - PAGOS",
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },

        btExtCfgFooter: null,
      }
    },


    methods: {

      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // botones Extra del Header
        this.btExtCfg.btnsAccion= [
          { accion:'salir', btn: "salir" },
          { accion:'pagoFactura', texto: "+PFD", title:'Pago Factura *Directo' }
        ];


        // botones Extra del Footer
        this.btExtCfgFooter.design= {
          class:"red--text",
          label:true,
          text:true,
          "x-small":true
        };

        this.btExtCfgFooter.btnsAccion= [
          { accion:'pagoCancelar', texto: "Cancelar", title:'Cancelar Pago' },
          { accion:'pagoEliminar', texto: "* Eliminar", title:'Eliminar Pago' },
          { accion:'pagoContabilizar', texto: "** Contabilizar", title:'Contabilizar Pago' }
        ];


        //
        this.headers = [{
          name:[],
          fields:[
            { text: "Fecha", value: "fhp", width: "20%", filtro:'fecha' },
            { text: "Remesa", value: "rem", width: "15%" },
            { text: "Pago", value: "fpa", width: "25%" },
            { text: "Importe", value: "imp", width: "25%", filtro:'num' },
            { text: "Asiento", value: "conta_asi", width: "14%" },
            { text: "T", value: "xtip", width: "1%" },
         ]}
        ];

        this.headerGrid= this.headerSet();
      },


      // inicializo Btras
      iniBtra() {
        this.btExtCfg= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
        this.btExtCfgFooter= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
      },


      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'pagos', { fnt:'get', fra:0 }, "facturas"];
        this.apiArgs.set= ["", 'pagos', { fnt:'set', idP:0 }, "facturas"];
        this.apiArgs.cancelar= ["", 'pagos', { fnt:'cancelar', idP:0 }, "facturas"];
        this.apiArgs.contawin= ["", 'pago', { idP:0, rec:1 }, "contawin"];
      },


      // configuración permisos botonera
      botonerasSet() {
        this.btnSet(this.btExtCfgFooter, 'pagoCancelar', { view:this.syncRecord.rem=='0' });
        this.btnSet(this.btExtCfgFooter, 'pagoEliminar', { view:this.ndep>= 8 });
        this.btnSet(this.btExtCfgFooter, 'pagoContabilizar', { view:this.ndep>= 8 });
      },


      // (override). Api Call de recordsGet
      async stRecordsGet() {
        let param = { apiArg: this.apiArgs.get, args: { fra:this.masterSyncRecord.id }};
        console.log("stRecordsGet *************************:", param);
        return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore });
      },


      // selecciono la primera linea del Grid
      recordsGetAfter() {
        if (!this.records.length) return;
        this.$store.commit(this.storeName + '/recordsSelectedSet', [this.records[0]]);
      },


      // eliminar pago
      pagoEliminar() {
        this.$root.$alert.open('ELIMINAMOS PAGO?<br>ID: <b>' + this.syncRecord.id + '</b><br>Importe: <b>' + this.syncRecord.tot + '</b><br>Asiento: <b>' + this.syncRecord.conta_asi + '</b><br><br>', 'confirm')
        .then(r=> { if (r) this.eliminamos(); });
      },

      async eliminamos() {
        let param = { apiArg: this.apiArgs.set, args: { idP:-this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        //
        this.$root.$alert.open('Pago eliminado', 'success', 1800);
        this.actualizar();
      },


      //contabilizar
      pagoContabilizar() {
        this.$root.$alert.open('CONTABILIZAMOS PAGO?<br>ID: <b>' + this.syncRecord.id + '</b><br>Importe: <b>' + this.syncRecord.tot + '</b><br>Asiento: <b>' + this.syncRecord.conta_asi + '</b><br><br>', 'confirm')
        .then(r=> { if (r) this.contabilizamos(); });
      },

      async contabilizamos() {
        let param = { apiArg: this.apiArgs.contawin, args: { idP:this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        //
        this.$root.$alert.open('Pago Contabilizado', 'success', 1800);
        this.actualizar();
      },


      // cancelar pago
      pagoCancelar() {
        this.$root.$alert.open('CANCELAMOS PAGO ?<br>ID: <b>' + this.syncRecord.id + '</b><br>Importe: <b>' + this.syncRecord.tot + '</b><br>Asiento: <b>' + this.syncRecord.conta_asi + '</b><br>' +
          '<br>*  En caso de no estar TRAMITADO (2021)<br> Se eliminará el registro/asiento en caso contrario se genera la devolución del mismo' +
          '<br>** Si Pago es multiple facturas, se cancelan todas las facturas incluidas en el Pago<br><br>'
          , 'confirm')
        .then(r=> { if (r) this.cancelamos(); });
      },

      async cancelamos() {
        if ((this.syncRecord.asi==0 ||this.syncRecord.asi== '') && this.syncRecord.fh>'2021') {
          this.eliminamos();
          return;
        }

        let param = { apiArg: this.apiArgs.cancelar, args: { idP:this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        //
        this.$root.$alert.open('Pago Cancelado', 'success', 1800);
        this.actualizar();
      },


      // actualizamos el finder actual y el del maestro
      actualizar() {
        this.masterStoreUpdate('refresh');
        this.storeUpdate('refresh');
      },


      // componente Dinámico Pago factura directo
      pagoFactura() {
        this.syncAccionSend= "0";
        this.componenteDinamico="cobrosPagosDirecto";
      },


      //
      salir() {
        this.$emit('onEvent', { accion:6 });
      }

    },


    computed: {

      // devuelve schema particular
      sch() {
        return {
          pte: [ 'pte', 'Pendiente', "" ]
        }
      },


      // importe pendiente por pagar
      pte() {
        return this.masterSyncRecord.imp - this.masterSyncRecord.fra_pago;
      },

    },


    watch: {
      syncRecord() {
        this.botonerasSet();
      }
    }

  };
</script>
