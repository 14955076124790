<!--Recepción Facturas -->

<template>
  <div class="frasRecepcion" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">
          <div class="columna">
                 
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div> 

            <v-sheet :elevation="4">
              <div class="contenedor">               
          
                <!-- Botoneras -->
                <div class="botonera">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>                
                </div>

                <!-- Controles del Mto -->
                <div class="ctrls conflex contenedor max-width:1240px"> 
                  <div class="columna" style="width:400px">                   

                    <uiText
                      v-model="record.razon_cta"
                      label="Cuenta"
                      :disabled=true>
                    </uiText>

                    <uiText
                      v-model="record.cif_cta"
                      label="Cif"
                      :disabled=true>
                    </uiText>
                    
                    <uiText
                      v-model="ct.fra_doc[2]"
                      label="Nº Factura"
                      :disabled="contabilizada">
                    </uiText>

                    <uiDate 
                      v-model="ct.fra_fh[2]"
                      label="Fecha Factura"
                      type="date"
                      :disabled="contabilizada">          
                    </uiDate>
                    
                    <uiDate 
                      v-model="ct.fra_vto[2]"
                      label="Fecha Vencimiento"
                      type="date"
                      :disabled="pagada">
                    </uiDate>
                    
                    <div class="conflex">
                      <v-select  
                        style="flex: 0 0 35%"
                        v-bind="$select"
                        v-model="ct.visado[2]"
                        :label="ct.visado[1]"
                        :items="itemsVisado"
                        item-value="id"
                        item-text="name"
                        :disabled="pagada"                
                        @change="changeVisado">   
                      </v-select>

                      <uiDate
                        style="flex: 0 0 65%"
                        v-model="ct.visado_fh[2]"
                        label="Fecha Visado"
                        type="datetime-local"
                        :disabled="pagada">
                      </uiDate>                      
                    </div>
                    
                    <uiDate
                      v-model="ct.fra_fhcon[2]"
                      label="Contable"
                      type="date"
                      disabled>                      
                    </uiDate>

                    <uiDate
                      v-model="ct.fra_pago_fh[2]"
                      label="Pago"
                      type="date"
                      disabled>                      
                    </uiDate>                    
                  </div>

                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>   
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
    
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraExtra, dualTemplate },
    props: {},

    data() {
      return {
        stIni: {
          api: "recepcionFrasM",
          name:"recepcionFrasM",
          titulo:"Recepción Facturas",  
          recordAccess:"masterStore",
          masterStore:this.masterStore,
          mView:'',
          pView:[]
        },

        itemsVisado: []
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'guardar', btn: "guardar" }          
        ];

        // array de Visados
        this.itemsVisado= [
          { id:"0", name:"No" },
          { id:"1", name:"Requerido" },
          { id:"2", name:"Visado" },
        ]
      },


      //
      iniDataApi() {        
        this.apiArgs.recepcion= ["", "recepcion", {}, "facturas"];
        this.apiArgs.vto= ["", "recepcionVto", { id:0 }, "facturas"];
      },


      // gancho por si necesitamos cargar datos auxiliares
      // entra DESPUÉS de obtener los datos del Mto
      // obtiene la fecha de vto (solo si no tiene ninguna)
      async getDataAux() {
        if (this.ct.fra_vto[2]) return;
        console.log('Obtiene fecha vto...');
        // llamada API
        let param= { apiArg:this.apiArgs.vto, args:{ id:this.ID }};      
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult getDataAux: ', apiResult);

        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // actualiza fecha de vto 
        this.$set(this.ct.fra_vto, 2, apiResult.r[0]);
      },
      

      // gancho al final de todas las acciones CRUD
      crudFin() {
        this.botonerasSet();
      },


      //
      botonerasSet() {
        this.btnSet(this.btExtCfg, 'guardar', { view:true, disabled:this.recepcionada });
      },


      // guardo datos factura recepcionada
      async guardar() {   
        // compruebo si se ha introducido un número de factura
        if (!this.ct.fra_doc[2]) return this.$root.$alert.open('Debe introducir Nº Factura', 'info');
        
        // obtengo datos de los controles en par-valor y le añado el campo id        
        let ctrls= this.ctrl2record(this.ct, {}, false);
        ctrls['id']= this.record.id;
        
        // llamada API
        let param= { apiArg:this.apiArgs.recepcion, args:ctrls };      
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult Guardar: ', apiResult);

        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Factura recepcionada correctamente', 'success', 1800);

        // paso datos a schema
        await this.record2ctrl(apiResult.r[0], this.ct);

        // recargo datos finder facturas
        await this.masterStoreUpdate('refresh');

        // cierro ventana
        this.salir();
      },


      // entra al cambiar el select de Visado
      // si el campo de fecha visado está vacío, muestro una fecha calculada
      changeVisado(id) {
        if (id!= 1 || this.ct.visado_fh[2]) return;
        
        var fecha = new Date();
        var dias = Number(this.$store.state.datos_iniciales.config[0].dias_visado);
        fecha.setDate(fecha.getDate() + dias);

        this.$set(this.ct.visado_fh, 2, `${fecha.getFullYear()}-${String(fecha.getMonth() + 1).padStart(2, "0")}-${String(fecha.getDate()).padStart(2, "0")}`);   
      }

    },



    computed: {
      contabilizada() {
        return Number(this.record.fra_conta_asi) > 0;
      },

      pagada() {
        return Number(this.record.fra_pago) != 0;
      },

      recepcionada() {
        return (this.contabilizada && this.pagada);
        //return Number(this.record.fra_conta_asi)> 0;        
      }
    },

  };
</script>
