/* Pendiente:

- Normalizar el método changesMD con el changesM (mixMto)
- Comprobar método auxEstado
- Revisar storesAsoc del storeMD
- Ver si crear un store MX a continuación del MD/F

*/


import { mixCt } from "@/mixins/mixCt";
import { mixSchValidate } from "@/mixins/mixSchValidate";
import { mixBtras } from "@/mixins/mixBtras";
import storeMD from "@/stores/modulos/storeMD.js";

export var mixMD = {
  mixins: [mixCt, mixSchValidate, mixBtras],
  props: {
    sync: { type: Boolean, default: false },              // vista activa del mto sincronizada con el masterField del masterStore
    syncAccion: { type: [String, Number], default: 0 },   // acción a ejecutar al sincronizar
    syncDisparo: { type:Boolean, default:false },         // switch que al cambiarlo ejecuta el disparo del componente
    syncUpdate: { type: Boolean, default: false },        // (true/false)). True: actualiza su masterStore 
    storeRaiz: { type: String, default: "" },             // nombre del store del que cuelga el componente
    masterStore: { type: String, default: '' },           // this.id= this.$store.state[this.masterStore].record[this.masterRecordField] (store relación)
    masterRecordField: { type: String, default: 'id' },   // nombre de campo donde extraigo el id relacionado  
    //
    storeAux: { type: String, default: "" },              // store auxiliar
    recordAux: { type: Object, default: null },           // record auxiliar
    IDAux: { type: [Number, String], default: '' },       // id auxiliar necesario para recopilar datos externos se definen en dataAux
    ctrlCt: { type: Array, default:()=> [] },             // array ct del control (en pric)
    readOnly: { type:Boolean, default:false },            // obliga al mto como solo lectura (omite los permisos)
    viewMtoOnly: { type: Boolean, default:false }         // muestra mto sin mostrar el finder                                                    
  },


  data() {
    return {
      raiz:'',                                            // store raiz name para pasar
      storeName:'',                                       // store name particular (storeRaiz + stIni.api + (index si storeRaiz== '')
      ct:0,                                               // array de controles
      apiArgs: {},                                        // objeto donde están definidas las llamadas a la API
      dataAux:{},                                         // variable con datos por defecto de recordDataAux
      recordDataAux:{},                                   // datos donde se almacena el getDataAux
      //
      btMtoCfg:null,                                      // (design, btnsAccion). Configuración botonera mantenimiento 
      btraRowCfg:null,                                    // (design, btnsAccion). Configuración botonera row      
      headers:{},                                         // objeto donde definimos las cabeceras del Grid
      headerActual: "",                                   // nombre de la cabecera que se está mostrando
      headerGrid:[],                                      // array con los campos de la cabecera que se está mostrando
      loaded:false,                                       // switch que indica si han terminado las cargas asíncronas para cargar el template       
      componenteDinamico:null,
      
      syncDisparoSend:false,                              // switch que al cambiarlo ejecuta el disparo del componente asociado (sólo si se pasa como propiedad)
      syncAccionSend:''                                   // acción a ejecutar en el componente asociado (sólo si se le pasa como propiedad)
    }
  },
  

  async created() {
    console.log("DEV " + this.stIni.api + " ********************** (created)");  

    // inicializo datos auxiliares generales (Mixin)
    this.iniDataMixin();  
    
    // inicializo apiArgs
    this.iniDataApi();

    // inicializo variables Btra
    this.iniBtra();

    // registro store
    await this.registerStore(storeMD);

    // inicializo store (stIni y getSchema)
    await this.iniStore();

    // paso schema a controles
    this.ct= JSON.parse(JSON.stringify(this.sch));

    // inicializo datos particulares
    await this.iniDataParticular();
       
    // funciones a ejecutar una vez cargado totalmente el componente
    this.loadedComponent();


    // watch's:    
    if (this.sync) {      
      this.$watch("masterField", {
        immediate: true,
        handler() {  
          console.log('watch MASTERFIELD MD');
          this.watchAccion('masterField');
        }
      });

    }else {      
      this.$watch("syncDisparo", {        
        handler() {  
          console.log('watch SYNCDISPARO MD');
          if (this.masterField== null) return;
          this.watchAccion('syncDisparo');
        }
      });
    }
    
  },


  methods: {

    // ini Data General
    iniDataMixin() {
      console.log("DEV " + this.stIni.api + " ********************** (iniDataMixin)");

      //
      this.apiArgs= {
        sch: ["mto", 'getSchema', {}, this.stIni.api ],
        get: ["mto", 'ver', { id: 0 }, this.stIni.api],
        set: ["mto", 'guardar', { id: 0, estado: '', changes: {} }, this.stIni.api],
        del: ["mto", 'borrar', { id: 0 }, this.stIni.api]
      }  
    },


    // override de apiArgs en particular en caso que sea necesario
    iniDataApi() {},

    // inicializo Btra Mto
    iniBtra() {
      if (this.readOnly) return;
      this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.M));
    },

    // inicializo datos particulares
    iniDataParticular() {},


    // entro cuando el componente se ha cargado
    loadedComponent() {    
      
      // indico que el componente ya se ha cargado
      this.loaded= true;
      
      // viewMtoOnly:true (normalmente viene de un ctrlF)
      // actualizo ID, con el recibido en el ctrlCt, la acción y ejecuto disparo
      if (this.viewMtoOnly) {      
        this.$store.commit(this.storeName + '/accionSet', { id: this.ctrlCt[2], accion:0 });
        this.$store.commit(this.storeName + '/disparoSet');
        return;
      }
      
      // emito evento por loaded por si lo recoge alguna funcion en el padre
      // por si hay que realizar alguna operación después de la carga del componente
      this.$emit('loaded', this.storeName);

      // si el componente esta sincronizado, el watch del masterField tiene un inmediate:true que 
      // lanzaría automáticamente el watchAccion. Cortarlo aquí evita que se vuelva a repetir al hacer la carga del componente
      if (this.sync) return;
      //      
      this.loadedAccion();
    },

    
    //
    loadedAccion() {      
      // funcion que se ejecuta cuando está cargado el componente NO sincronizado
      this.watchAccion('loaded');
    },


    // ---- Acciones Watch ---------------------------------------------------------
    watchAccion(origen, accion = this.syncAccion) {
      console.log("DEV ********** watchAccion (MIXMD). Origen: ", origen, ', Accion: ', accion);

      // origen=='syncDisparo'
      // origen=='masterField'
      // origen=='loaded'      
      this.setStoreAccion(accion);
      this.watchAccionExec();
    },


    //
    setStoreAccion(accion) {
      console.log("DEV ********** setStoreAccion (MIXM)");

      this.$store.commit(this.storeName + '/accionSet', { id: this.masterField, accion: accion });      
    },


    //
    watchAccionExec() {
      console.log('DEV ********** watchAccionExec (MIXMD): ', this.accion, this.ID);

      // si no se recibe una acción o ID, no se ejecuta nada  
      // ¿ quitar ID== null y preguntar particularmente en cada acción ?
      if (this.accion === "" || this.ID === null) return;

      // si la acción a ejecutar es un 'nuevo' y tiene ID, no se ejecuta nada
      //if (this.accion== 1 && this.ID> 0) return;


      // muestro ventana de Mto
      if (!this.sync) this.$store.commit(this.storeName + '/modalSet', true);

      // ejecuto acción      
      this.execAccion({ accion: this.accion });
    },
    //-----------------------------------------------------------------------



    // call Acciones MD ---------------------------------------------------------
    async execAccion(evt) {
      console.log("DEV execAccion ********** MIXMD", evt);

      // (0:'ver', 1:'nuevo', 2:'editar', 3:'eliminar', 4:'guardar', 5:'cancelar', 6:'salir')
      const options = [
        "ver",
        "nuevo",
        "editar",
        "eliminar",
        "guardar",
        "cancelar",
        "salir"
      ];
      let accion = /^([0-9])*$/.test(evt.accion)
        ? options[evt.accion]
        : evt.accion;

      if (this.$isFunction(this[accion])) this[accion](evt);
    },
  // ---------------------------------------------------------------------

    
  // call Acciones MX ---------------------------------------------------------
    execAccionMX(evt) {
      console.log("DEV accionGridTop ********** MIXMD", evt);       
      
      // accion NUEVO
      let idAccionNuevo= "0";

      if (evt.accion=='1') {
        // compruebo si es un Mto expansible y está en edición
        if (!this.noEdit1) {
          this.$root.$alert.open('Tiene una linea en edición. GUARDE o CANCELE los cambios', 'error');
          return;
        }
        
        // añado un objeto con id=0 como primer registro del records
        this.$store.dispatch(this.storeName + '/nuevoMX', idAccionNuevo);        
      }
       
      // guarda el item  del Grid correspondiente a la accion de la botonera (row/extra..)
      // si no recibimos item creamos uno nuevo (se supone que es una accion NUEVO)
      this.$store.commit(this.storeName + '/recordsSelectedSet', evt.item? [evt.item] : [{ id:idAccionNuevo }]);
      

      // Ejecutamos la funcion de la acción si existe
      // Si viene por ejemplo (0=ver, 1=nuevo) tenemos una por defecto
      if (this.$isFunction(this[evt.accion])) return this[evt.accion](evt);
 
      // cambiamos propiedad syncDisparoSend del componente asociado
      // cambiamos propiedad syncAccionSend (si es opcional o hay varias acciones posibles)
      // el WATCH del componente asociado debe reaccionar al syncDisparo
      this.syncAccionSend= evt.accion;
      this.syncDisparoSend= !this.syncDisparoSend;          
    },
  // ---------------------------------------------------------------------



  // acciones MD ---------------------------------------------------------
    
    // muestro registro en modo NO edición
    async ver() {
      if (!this.isvalidID()) return;

      // call Store
      await this.stVer();

      // comprueba si el id del expediente es el mismo que el guardado en el presupuesto
      this.validateExpID();
      
      await this.showRecord();
      
      // gancho después de ver
      this.verFin();
    },

    validateExpID() {
      if (this.record.exp_id== this.recordAux.id) return true;

      this.$root.$alert.open("El Id del expte. en el presupuesto no coincide con el del Expte. Comuníquese con Informática", 'error');
      return false;
    },

    verFin() {
      this.crudFin();
    },
    

    // gancho antes de editar
    editarBefore() { return true; },

    // muestro registro en modo edición
    async editar() {
      if (!this.isvalidID()) return;
      if (!this.editarBefore()) return;

      // call Store
      await this.stVer();
      // Pendiente: controlar error API

      // edito mto y paso record a controles
      await this.stEditar();      
      await this.showRecord();

      // gancho después de 'editar''
      this.editarFin();
    },

    editarFin() {
      this.crudFin();
    },

    
    // gancho antes de guardar el record del schema en el store
    nuevoBefore() {},

    // nuevo registro
    async nuevo() {
      // compruebo si hay mtos auxiliares en edición     
      if (!this.auxEstado()) return;

      // inicializo schema y lo modifico si lo necesito en 'nuevoBefore'
      this.ct = JSON.parse(JSON.stringify(this.sch));
      await this.nuevoBefore();
            
      // guardo record de schema inicializado y lo paso a los controles
      await this.stNuevo();
      await this.showRecord();
      
      // gancho después de nuevo
      this.nuevoFin();      
    },

    nuevoFin() {
      this.crudFin();
    },
    

    async showRecord() {      
      // guardo controles en ct y le paso los datos del registro leido      
      /* if (this.estado!= 'nuevo') {
        this.ct = JSON.parse(JSON.stringify(this.sch));
        this.iniDataCt();
      } */
      this.ct = JSON.parse(JSON.stringify(this.sch));
      await this.record2ctrl(this.record, this.ct, this.estado=='nuevo');
      
      // gancho por si necesitamos cargar datos auxiliares
      // entro DESPUÉS de obtener los datos del Mto
      await this.getDataAux();     
    },


    //
    iniDataCt() {},


    // gancho por si necesitamos cargar datos auxiliares
    // entro DESPUÉS de obtener los datos del Mto
    async getDataAux() {
      
       /* Para extraer los datos desde el servidor, debemos tener:
          API integrada
          this.stIni.relation
          NO DEBE TENER  recordAux 
      */
      if (!this.recordAux && this.stIni.relation) {
        let id= this.record[this.stIni.relation];
        let param= { apiArg:this.apiArgs.getDataAux, args:{ id:id }};            
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult: ', apiResult);
        // pendiente: controlar error

        this.recordDataAux={...this.dataAux, ... apiResult.r[0]};       
        return
      }
  
      /* Para extraer los datos internamente sin llamada a servidor:
              DEBE TENER recordAux
      */
       if (this.recordAux) {
           this.recordDataAux={...this.dataAux, ... this.recordAux};
           return
       }

    },

    
    // gancho antes de cancelar
    cancelarBefore() { return true; },

    // cancelo la edición del registro
    async cancelar() {
      if (!this.cancelarBefore()) return;
      
      // compruebo si hay mtos auxiliares en edición
      if (!this.auxEstado()) return false;

      // si hay cambios, muestro pregunta para cancelarlos 
      // si NO hay cambios, cancelo el registro directamente
      if (this.changesMD()!='') {
        this.cancelarPregunta();

      } else {
        this.cancelarSi();
      }
    },

    async cancelarPregunta() {
      // muestro pregunta para confirmar la cancelación
      await await this.$root.$alert
        .open("¿ CANCELAR los cambios realizados ?", "confirm", null, [
          "NO",
          "SI"
        ])
        .then(r => { if (r) this.cancelarSi(); });
    },

    cancelarSi() {
      // si estado es NUEVO, cierro MD
      if (this.estado == "nuevo") {        
        this.salir();
        return;
      }

      // estado EDITAR. Actualizo datos Store y hago un VER
      this.stCancelar();
      this.execAccion({ accion: 0 });
      return;      
    },

    
    // gancho antes de guardar     
    // comprueba si existe algún mto que cuelgue del actual que esté en edición
    guardarBefore() { return true; },

    // guardar cabecera y lineas
    async guardar() {
      if (!this.guardarBefore()) return;

      // valido datos particulares
      if (!await this.validate()) return false;

      // compruebo si hay mtos auxiliares en edición
      if (!this.auxEstado()) return false;

      // comprueba si hay incongruencias en las lineas del Grid
      if (!this.checkDatosLineas(this.records1)) return false;
      

      // ------- CABECERA
      // obtengo cambios cabecera (sólo cambios) y almaceno en recordChanges
      // si no hay cambios ni en cabecera ni en lineas y estamos en edición, hacemos un VER
      let recordChanges = this.ctrl2record(this.ct, this.record, true);
      console.log("recordChanges:", recordChanges);
      if (!Object.keys(recordChanges).length && !this.change1 && this.estado == "editar") {
        this.execAccion({ accion: 0 });        
        return;
      }

      // obtengo todos los campos de la cabecera y almaceno en recordCAB
      let recordCAB= this.ctrl2record(this.ct, this.record, false);

      
      // ------- CAMPO RELACIONADO
      // si estamos en un NUEVO, hemos definido un campo relacionado (stIni - particular) 
      // y tenemos definido un relationID (data/computed - particular), guardamos ese ID en el record
      if (this.estado == "nuevo" && this.relation && (this.relationID && this.relationID > 0)) {
        recordCAB[this.relation] = this.relationID;
      }

      
      // ------ LINEAS
      // obtengo array de datos de todas las lineas
      let record = {};
      const records1 = this.records1;
      const sch1 = this.sch1;
      let records = [];
      
      // recorro controles de cada linea y voy guardándolos como propiedad=> valor
      for (var i= 0; i < records1.length; i++) {
        Object.keys(sch1).forEach(key => {
          if (this.ctProp("nosave", sch1[key])) return;
          if (sch1[key][0] === "") return;
          record[sch1[key][0]] = records1[i][sch1[key][0]];
        });
                       
        records.push(record);
        record = {};
      }

      this.guardarBeforeAPI(recordCAB, records);
      this.guardarAPI(recordCAB, records);
    },
    
    // gancho despues de obtener los records pero antes de guardar
    guardarBeforeAPI() {},
    
    async guardarAPI(recordCAB, records) {
      // call Store
      let apiResult= await this.stGuardar(recordCAB, records);

      // Pendiente: controlar error API
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');        
        return;
      }
      
      //this.$root.$alert.open("Registro guardado correctamente!", "success", 1000);

      // actualizo record maestro, si corresponde, y mto      
      this.guardarUpdate();
    },

    //
    async guardarUpdate() {
      // si es un guardar nuevo, ejecuta la mutation 'nuevo' del master asociado,
      // normalmente será un finder, así que, añadirá una linea nueva con el ID del nuevo registro
      /* if (this.estado== 'nuevo') {
        this.masterStoreUpdate('nuevo', apiResult.r);
      } */
      
      // actualizo record del master asociado si lo tuviera
      await this.masterStoreUpdate('refresh');

      await this.stVer('guardar'); 

      // muestro record Mto
      await this.showRecord();
      
      // gancho después de guardar
      this.guardarFin();
            
      /* if (typeof apiResult.r=== 'number') this.$store.commit(this.storeName + '/data2State', { prop:'ID', value:apiResult.r });
      this.ver(); */
    },
    
    guardarFin() {
      this.$root.$alert.open("Registro guardado correctamente!", "success", 1800);
      this.crudFin();
    },


    // gancho antes de eliminar
    eliminarBefore() { return true; },

    // elimino registro cabecera y todas sus lineas
    async eliminar() { 
      if (!this.eliminarBefore()) return;

      // muestro pregunta para confirmar la eliminación
      await await this.$root.$alert
        .open("¿ Desea eliminar el registro ?", "confirm", null, [
          "NO",
          "SI"
        ])
        .then(r => {
          if (r) this.eliminarSi();
        });
    },  
   
    async eliminarSi() {  
      // call Store
      let apiResult= await this.stEliminar();  
      console.log('apiResult eliminar MD: ', apiResult);
      // Pendiente: controlar error API

      // elimino registro del master asociado      
      this.masterStoreUpdate('refresh');

      // ejecuto accion SALIR
      this.execAccion({ accion: 6 });

      // gancho después eliminar 
      this.eliminarFin();
    },

    eliminarFin() {
      this.crudFin();
    },
  

    // cierro ventana MD
    salir() {

      // compruebo si hay mtos auxiliares en edición
      if (!this.auxEstado()) return false;
      
      // actualizo estado en el store
      this.stCancelar();

      // si no hay cambios o el estado es VER, cierro la ventana
      let msg= this.changesMD();
      if (this.noEdit || msg== '') {
        this.$store.commit(this.storeName + '/data2State', { prop:'modal', value:false });        
        return;
      }
      
      // muestro error obtenido al comprobar si hay cambios
      this.$root.$alert.open(msg, 'error', 2000);      
    },


    // gancho final para todas las operaciones CRUD
    crudFin() {},


    // compruebo si el ID es válido
    isvalidID() {
      if (!this.recordAccess) return false;      
      if (this.ID== null || (typeof this.ID=== 'number' && Number(this.ID)<= 0)) {
        this.noID();
        return false;
      }

      return true;
    },


    //
    noID() {
      this.$root.$alert.open('Registro inválido, salga y recargue la página', 'error');
    },

  // ---------------------------------------------------------------------



    // compruebo si hay una fila en edición (EDITAR/NUEVO), si está en edición muestro un error 
    // y no continuo con la acción inicial, si no lo está, continuo
    auxEstado() {
      if (this.noEdit1) return true;
      
      this.$root.$alert.open('Existe una linea en edición. GUARDE o CANCELE los cambios de la linea', 'error');      
      return false;
    },


    // compruebo si hay cambios en la cabecera y lineas
    // devuelvo '' si no hay cambios o el mensaje de error si si los hay
    changesMD() {
      // si estado es NUEVO no compruebo cambios
      if (this.estado== 'nuevo') return '';
      
      let recordChanges = this.ctrl2record(this.ct, this.record, true);
      if (!Object.keys(recordChanges).length && !this.change1) return '';

      let msg= '';
      if (Object.keys(recordChanges).length || this.change1) msg+= 'Antes de Salir GUARDE o CANCELE los cambios';      
      return msg;
    },


    //
    async validate() {
      let r= await this.validateParticular();
      return !r? false : true;
    },

    // gancho para realizar validaciones particulares de datos antes de guardarlos
    async validateParticular(){
      return true;
    },


    //
    masterStoreUpdate(accionMasterSt, params = null) {
      if (!this.syncUpdate || !this.masterStore) return;
      this.$store.dispatch(this.masterStore + '/' + accionMasterSt, params);
    },


    // ---- Acciones Store ---------------------------------------------------------
    async stVer(origen= '') {
      // apiArg: llamada API definida en el particular. 
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArg.get      
      let param= { apiArg:this.apiArgs.get, args:{ id: this.ID }};
      console.log("stVer *************************:", param, origen);
      return await this.$store.dispatch(this.storeName + "/ver", { param, origen });
    },

    async stEditar() {      
      return await this.$store.dispatch(this.storeName + "/editar");
    },

    async stCancelar() {
      return await this.$store.dispatch(this.storeName + "/cancelar");
    },

    async stNuevo() {            
      return await this.$store.dispatch(this.storeName + "/nuevo", this.ctrl2record(this.ct, {}, false));
    },

    async stEliminar() {
      // apiArg: llamada API definida en el particular. 
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.set
      let param= { apiArg:this.apiArgs.eliminar, args:{ id: this.ID }};  
      console.log("stEliminar *************************:", param);    
      return await this.$store.dispatch(this.storeName + "/eliminar", param);     
    },

    async stGuardar(cab,lin) {
      // apiArg: llamada API definida en el particular. 
      // args: argumentos a fusionar con los definidos en 'fn_args' de apiArgs.set
      let param= { apiArg:this.apiArgs.set, args:{ id: this.ID, cab:cab, lin:lin }}; 
      console.log("stGuardar *************************:", param);     
      return await this.$store.dispatch(this.storeName + "/guardar", param);
    },

    // ----------------------------------------------------------------------


    // eventos de la cabecera
    eventHeader(evt) {
      console.log("DEV eventHeader ********** MIXMD", evt); 

      if (this.storeRaiz== "") {
        this.$router.push({ path: '/' });
        this.$store.commit("set_activeMenu", []);
        return;
      }
            
      this.salir();
    },
  },


  computed: {
  // variables STORE ---------------------------------------------------------

    // devuelvo el valor del campo masterRecordField del record sincronizado
    masterField() {          
      if (!this.masterStore) return null;            
      if (!this.masterSyncRecord) return null;            
      if (!this.masterSyncRecord[this.masterRecordField]) return 0;      
      return this.masterSyncRecord[this.masterRecordField];
    },

    // id Mto
    ID() {
      if (!this.$store.state[this.storeName]) return null;
      return this.$store.state[this.storeName].ID;
    },

    // (true/false). Mto modal visible o no (ahora mismo se utiliza a traves del componente dualTemplate)
    modal() {
      if (!this.$store.state[this.storeName]) return false;
      return this.$store.state[this.storeName].modal;
    },

    // acción a ejecutar
    accion() {     
      if (!this.$store.state[this.storeName]) return '';
      return this.$store.state[this.storeName].accion;
    },
    
    /* disparo() {
      return this.$store.state[this.storeName].disparo;
    }, */

    // (true/false). Devuelve si la cabecera de Mto está en edición
    noEdit() {
      return this.$store.getters[this.storeName + "/noEdit"];
    },

    // (true/false). Devuelve si hay alguna linea de detalle que está en edición
    noEdit1(){
      return this.$store.getters[this.storeName + "/noEdit1"];
    },

    // estado de acciones ene l que se encuentra el Mto (ver, editar, nuevo, ...)
    estado() {
      return this.$store.state[this.storeName].estado;
    },

    // permisos de Mto
    perm() {
      return this.$store.state[this.storeName].perm;
    },

    // schema de controles Mto
    sch() {     
      return this.$store.state[this.storeName].sch;
    },

    // schema de controles Mto Detalle
    sch1() {
      return this.$store.state[this.storeName].sch1;
    },
    
    /* btra() {
      return this.$store.state[this.storeName].btra;
    }, */
    // objeto con los campos de la cabecera de Mto
    record() {
      return this.$store.state[this.storeName].record;
    },

    // objeto con los records de las lineas de detalle
    records1() {
      return this.$store.state[this.storeName].records1;
    },

    // objeto con los campos de la cabecera de Mto
    recordRead() {
      return this.$store.state[this.storeName].recordRead;
    },

    // tipo de lectura forzada (local, serv, masterStore....)
    recordAccess() {
      return this.$store.state[this.storeName].recordAccess;
    },

    // (true/false). Switch que indica si ha cambiado algún dato de la cabecera de Mto
    change() {
      return this.$store.state[this.storeName].change;
    },

    // (true/false). Switch que indica si ha cambiado alguna linea de detalle
    change1() {
      return this.$store.state[this.storeName].change1;
    },

    disparoSt() {
      if (!this.$store.state[this.storeName]) return false;
      return this.$store.state[this.storeName].disparo;
    },

    // nombre del campo relacionado con el detalle
    relation() {
      return this.$store.state[this.storeName].relation;
    },

    // permisos Extra
    permExtra() {
      return this.$store.state[this.storeName].permExtra;
    }, 

    // records seleccionados del componente maestro asociado
    masterSyncRecord() {      
      return this.$store.getters[this.masterStore + "/syncRecord"];        
    },

    
  // ---------------------------------------------------------------------

  },

  watch: {

    // Para utilizar este disparo y ejecutar una accion, debemos asignar  el ID , accion y disparo  del store externamente
    disparoSt() {
      this.watchAccionExec();
    },
    
  },


  // destruyo store
  beforeDestroy() {    
    this.$store.unregisterModule(this.storeName);
  },

};
