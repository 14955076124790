<template>
  <div v-if="Aimp.length" class="presClaves" style="width:100%">
    <v-sheet v-bind="$cfg.styles.marco" >
       <div
        class="conflex"
        style="width: 100%; align-items: baseline; align-content: stretch">
      
        <div class="columna" style="width: 44%;">
          <div
            class="cab"
            style="width: 98%; text-align: center; margin-right: 2px"
          >
            SERVICIO
          </div>
         <div style="width:100%">
          <div v-for="(item, index) in clavesServicio" :key="index">
            <vllabel
              style="flex: 1 1 100%"
              :label="item.grupo.toUpperCase()"
              :txt="item.imp[0] | num(2)"
              stilot="text-align:right"
            >
            </vllabel>
          </div>

          <vllabel
            style="flex: 1 1"
            stilot="background-color:rgb(70, 131, 180,.3);text-align:right"
            stilol="background-color:rgb(70, 131, 180,.3)"
            label="TOTALES"
            :txt="T.servicio | num(2)"
          >
          </vllabel>
          <vllabel
            style="flex: 1 1 46%"
            stilot="background-color:rgb(214, 234, 248 ,.8);text-align:right"
            stilol="background-color:rgb(214, 234, 248 ,.8)"
            label="TRASLADO"
            :txt="Aimp[6].imp[0] | num(2)"
          >
          </vllabel>

          <vllabel
            style="flex: 1 1 46%"
            stilot="background-color:rgb(214, 234, 248 ,.8);text-align:right"
            stilol="background-color:rgb(214, 234, 248 ,.8)"
            label="RECOG.JUD"
            :txt="Aimp[5].imp[0] | num(2)"
          >
          </vllabel>

          <vllabel
            style="flex: 1 1 100%"
            stilot="text-align:right"
            label="P.MORTEM"
            :txt="T.pmortem | num(2)"
          >
          </vllabel>

          <vllabel
            style="flex: 1 1"
            stilot="text-align:right"
            label="TRASLADO"
            :txt="T.traslado | num(2)"
          >
          </vllabel>
          </div>
        </div>

        <!-- separo en 2 columnas para luego unirlas -->
        <div  v-show="todo" class="conflex" style="width: 56%">
          <div  class="columna" style="width: 49%">
            <div
              class="cab"
              style="width: 98%; text-align: center; margin-right: 2px"
            >
              CAPITAL
            </div>
            <div v-for="(item, index) in clavesServicio" :key="index">
              <vllabel
                style="flex: 1 1"
                :txt="item.imp[1] | num(2)"
                stilot="text-align:right"
              >
              </vllabel>
            </div>
            <vllabel
              style="flex: 1 1"
              stilot="background-color:rgb(70, 131, 180,.3);text-align:right"
              :txt="T.capital | num(2)"
            >
            </vllabel>
          </div>

          <div  class="columna" style="width: 49%">
            <div
              class="cab"
              style="width: 98%; text-align: center; margin-right: 2px"
            >
              DIFERENCIA
            </div>
            <div v-for="(item, index) in clavesServicio" :key="index">
              <vllabel
                style="flex: 1 1"
                :txt="item.imp[2] | num(2)"
                stilot="text-align:right"
              >
              </vllabel>
            </div>
            <vllabel
              style="flex: 1 1"
              stilot="background-color:rgb(70, 131, 180,.3);text-align:right"
              :txt="T.dif | num(2)"
            >
            </vllabel>
          </div>
          <div class="columna " style='width:100%'>
          <vllabel
           style="flex: 0 0 "
            stilot="text-align:right"
            label="COMPLEMTO"
            :txt="T.complemento | num(2)"
          >
          </vllabel>

          <vllabel
            
            stilot="text-align:right"
            label="DIFERENCIA"
            :txt="T.complementoDif | num(2)"
          >
          </vllabel>
          </div>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    Aimp: { type: Array, default: () => [] },
    T: {},
    // todo=true muestra capitales y direferencias
    // todo=false muestra solo servicio (presupuestos de funerarias)
    todo: { type: Boolean, default: () => true },
  },

  data() {
    return {
      titulo_claves: ["SERVICIO", "CAPITALES", "DIFERENCIAS"],
      id_titulo: 0,
    };
  },

  computed: {
    clavesServicio() {
      return this.Aimp.slice(0, 5);
    },
  },
};
</script>
