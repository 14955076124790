export var mixCalculosFras = {
  data() {
    return {
      
    };
  },

  methods: {

    async calcFras(ct, records) {
      //if (!records.length) return;

      
      // reseteo campos
      await this.record2ctrl({}, ct, true);

      //
      records.map(elem=> {             
        if (elem.aceptada!='1') return;
                      
        if (elem.tipo_ope=='V') {
          this.$set(ct.serv_v, 2, Number(ct.serv_v[2]) + Number(elem.base) + Number(elem.sup) - Number(elem.tmt));
          this.$set(ct.tram_v, 2, Number(ct.tram_v[2]) + Number(elem.tmt) - Number(elem.tmt3));
          this.$set(ct.apd_v, 2, Number(ct.apd_v[2]) + Number(elem.tmt3));

        } else if (elem.tipo_ope=='C') {
          this.$set(ct.serv_c, 2, Number(ct.serv_c[2]) + Number(elem.base) + Number(elem.sup));
         
          if (elem.suplido==0) this.$set(ct.sup, 2, Number(ct.sup[2]) + Number(elem.sup));                                                                                                                                                                                              
          this.$set(ct.dif_sup_fac, 2, Number(ct.dif_sup_fac[2]) + Number(elem.supdif));          
          this.$set(ct.sup_fac, 2, Number(ct.sup_fac[2]) + Number(elem.supfra));
          if (elem.suplido==1) this.$set(ct.pd, 2, Number(ct.pd[2]) + Number(elem.sup));
          
          if (elem.suplido==0 && Number(elem.sup)==Number(elem.imp) && Number(elem.sup)<0 && Number(elem.imp)==-Number(elem.supfra)) { 
            this.$set(ct.serv_c, 2, (Number(ct.serv_c[2]) - Number(elem.sup)).toFixed(2)); 
            this.$set(ct.sup, 2, (Number(ct.sup[2]) - Number(elem.sup)).toFixed(2)); 
          }
        }
      });

      this.$set(ct.total_v, 2, this.round(Number(ct.serv_v[2]) + Number(ct.tram_v[2]) + Number(ct.apd_v[2]),2));
      this.$set(ct.dif_cv, 2, (Number(ct.serv_v[2]) - Number(ct.serv_c[2])).toFixed(2));
      this.$set(ct.dif, 2, (Number(ct.serv_v[2]) - Number(ct.serv_c[2]) + Number(ct.dif_sup_fac[2])).toFixed(2));
      this.$set(ct.bp, 2, Number(Number(ct.total_v[2]) - Number(ct.serv_c[2]))); 
    },       

  }
};
