<!-- Comunicados de expedientes -->

<template>
  <div class="expComunicadosF" v-if="loaded">

    <v-dialog v-model="modal" content-class="modal" persistent>      
      
        <div>    

          <!-- Cabecera -->
          <div class="cabecera">     
            <baseHeader
              :cfg="$cfe.headers.finder"
              :titulo='$store.state[storeName].titulo'
              @onEvent="eventHeader"> 
            </baseHeader>
          </div>
          
          <v-sheet :elevation="4">        
            <div class="contenedor" style="width:900px"> 

              <!-- <div v-html="info"></div> -->

              <!-- Filtro de Busqueda -->
              <div class="conflex">
                <div class="columna" style="width:40%">          
                  <div class="cab">EXPEDIENTE</div>
                  <v-sheet v-bind="$cfg.styles.marco">  
                    <uiText
                      v-model="recordAux.control"
                      label="Nº Control"                      
                      disabled>
                    </uiText>

                    <uiText
                      v-model="recordAux.fallename"
                      label="Nombre"                      
                      disabled>
                    </uiText>
                    
                    <uiDate 
                      v-model="recordAux.fallefhhr"
                      label="Fecha/Hora"          
                      type="datetime-local"
                      disabled>          
                    </uiDate>                   
                  </v-sheet>          
                </div>

                <div class="columna" style="width:60%">          
                  <div class="cab">COMPAÑÍA</div>
                  <v-sheet height="141px" v-bind="$cfg.styles.marco">
                    <uiText
                      v-model="recordAux.name_callemp"
                      label="CIA"                      
                      disabled>
                    </uiText>

                    <uiText
                      v-model="recordAux.name_agencia"
                      label="Agencia"                      
                      disabled>
                    </uiText>

                    <uiText
                      v-model="recordAux.name_fune1"
                      label="Funeraria"                      
                      disabled>
                    </uiText>     
                  </v-sheet>          
                </div>
              </div>

              
              <!-- Grid -->
              <baseGridMD
                style="padding-top:10px"
                :store="storeName"
                :headers="headerGrid"
                :items-per-page="-1"
                :expansible="`${storeName}${stIni.mView}`"
                dense           
                @onEvent="execAccion">

                  <!-- Slot botonera Top -->
                  <template v-slot:gridTop="{}">
                    <baseBtraMto
                      :perm="permMto"
                      :modulo="btGridCfg"
                      @onEvent="execAccion">
                    </baseBtraMto>
                  </template>

                  <!-- Slot campo botones expansible  -->
                  <template v-slot:expansibleBtra="{ item }">                
                    <baseBtraMto
                      :perm="permMto"
                      :modulo="btRowCfg"
                      @onEvent="execAccion({ ...$event, ...{ item } })">
                    </baseBtraMto>
                  </template>

                  <!-- Slot fhhr -->
                  <template v-slot:fhhr="{ item }">               
                    {{ item.fhhr|fechahr }}                    
                    <div v-if="item.usu" class="light-blue--text text--darken-3"> {{ item.usu }}</div>
                  </template>

                  <!-- Slot emp -->
                  <template v-slot:emp="{ item }">               
                    {{ item.emp }}                    
                    <div v-if="item.ate" class="light-blue--text text--darken-3"> {{ item.ate }}</div>
                  </template> 

                  <!-- Slot name_env -->
                  <template v-slot:name_env="{ item }">
                    {{ item.name_env }}
                    <div v-if="item.name_tip" class="light-blue--text text--darken-3"> {{ item.name_tip }}</div>
                  </template>  

                  <!-- Slot panel expansible -->
                  <template v-slot:expansibleMX="{}">
                    <expComunicadosMX 
                      sync
                      syncUpdate
                      :syncAccion="syncAccionSend"
                      :storeRaiz="storeName"
                      :masterStore="storeName"
                      :recordAux="recordAux"
                      :readOnly="readOnly"
                      :viewMtoOnly="viewMtoOnly"                      
                      :tipoDocumento="tipoDocumento">
                    </expComunicadosMX>
                  </template>                                       

              </baseGridMD>
            </div>
          </v-sheet>
        </div>        
    </v-dialog>  
  </div>
</template>


 
<script>

    import { mixF } from "@/mixins/mixF.js";     
    import baseHeader from "@/base/baseHeader";
    import baseGridMD from "@/base/baseGridMD";
    import baseBtraMto from "@/base/baseBtraMto";
    import expComunicadosMX from "@/components/expComunicadosMX";
          
    export default {
      mixins: [mixF],
      components: { baseHeader, baseGridMD, baseBtraMto, expComunicadosMX },
      props: {
        tipoDocumento: { type: String, default: "0" },
      },

      data() {
        return {
          stIni: {
            api: "expComunicadosF",
            titulo:"Comunicados Expediente", 
            name:"expComunicadosF",
            mView:'expComunicadosMX',
            pView:[],
            expansibleMX:'expComunicadosMX'
          },
        
        };
      },

     
      methods: {
        iniDataParticular() {
          console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
    
          this.headers = [{
            name:[],
            fields:[              
              { text: "Fecha / Usuario", value: "fhhr", width:"15px", slot:true },
              { text: "Envio Compañía / Funeraria", value: "emp", width:"60px", slot:true },
              { text: "Envio / Tipo", value: "name_env", width:"15px", sortable:false, slot:true }
            ]}
          ];

          // configuro cabecera Grid
          this.headerGrid= this.headerSet();

          // añado botón de ver Pdf a las rows
          this.btRowCfg.btnsAccion= [{ accion:'verPdf', btn:'pdf' }];

          // le indico que el finder tiene un autoload con la función 'buscar'
          // al cargar el componente hago un recordGet          
          this.autoLoad= 'buscar';        
        },


        //
        iniDataApi() {
          // añado a apiArgs la llamada a pdf y filtro
          this.apiArgs.pdf= ["", "fichero", { id:0 }, "comunicado"];         
        },


        // gancho después de cargar los records
        recordsGetAfter() {          
        },


        // mostrar nueva pestaña del navegador con pdf del documento seleccionado
        async verPdf(evt) {          
          let param = { apiArg: this.apiArgs.pdf, args: { id: evt.item.id }};                      
          let apiResult = await this.$store.dispatch("apiCall", param);
          console.log('verPdf: ', apiResult);
                              
          if (apiResult.sql.error) {
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            return;
          }

          // abro documento
          window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");          
        },
      
      }
    };

</script>


