<!-- Mto particular de APDS -->

<template>
  <div class="apdM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
       persistent>

        <template v-slot:controles="{}">

          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">
              <div class="contenedor" style="width:1200px">

                <div class="conflex">
                  <!-- Botoneras -->

                  <!-- Mto -->
                  <div>
                    <baseBtraMto
                      v-if="!readOnly"
                      :perm="permMto"
                      :modulo="btMtoCfg"
                      :estado="estado"
                      @onEvent="execAccion">
                    </baseBtraMto>
                  </div>

                  <!-- Extra -->
                  <div class="conflex" style="margin-left: 50px">
                    <baseBtraExtra class="conflex"
                      :permExtra="permExtra"
                      :modulo="btExtCfg"
                      @onEvent="execAccion">
                    </baseBtraExtra>
                  </div>
                </div>

                <div class="contenedor conflex" style="padding-top:10px">
                  <div class="columna" style="width:55%">
                    <div class="conflex" style="justify-content:space-between">
                      <div class="cab ">IDENTIFICACIÓN</div>
                      <!-- <div style="color:steelblue;padding-right:20px">
                        {{ ct.id[2] }}
                      </div> -->
                    </div>

                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex" style="align-items:center">
                        <div style="margin-top:-10px;margin-right:15px">
                          {{ ct.activo[2]==1? 'Activo' : 'Inactivo' }}
                        </div>

                        <v-switch
                          style="flex:1 1 15%;margin:0;padding:0"
                          v-model="ct.activo[2]"
                          true-value="1"
                          false-value="0"
                          color="green"
                          :readonly="!editable">
                        </v-switch>
                      </div>
                      <div class="conflex">
                        <uiText
                          style="width:90%;font-weight:bold;"
                          v-bind="$input"
                          v-model="ct.name[2]"
                          :label="ct.name[1]"
                          :disabled="!editable">
                        </uiText>
                        <uiText
                          style="width:10%;"
                          v-bind="$input"
                          v-model="ct.id[2]"
                          :label="ct.id[1]"
                          :readonly="true">
                        </uiText>
                      </div>
                      <div style="display:flex">
                        <uiText
                          style="flex: 1 1 40%"
                          v-bind="$input"
                          v-model="ct.codigo[2]"
                          :label="ct.codigo[1]"
                          :disabled="!editable">
                        </uiText>

                        <uiText
                          style="flex: 1 1 40%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.nif[2]"
                          :label="ct.nif[1]"
                          format="cif"
                          :disabled="!editable">
                        </uiText>

                        <v-select
                          style="flex: 1 1 20%;margin-left:5px"
                          v-bind="$select"
                          v-model="ct.tarifa[2]"
                          :label="ct.tarifa[1]"
                          :items="itemsTarifa"
                          :disabled="!editable"
                          item-value="id"
                          item-text="label">
                        </v-select>
                      </div>

                      <div style="display:flex">
                        <ctrlF
                          style="width:50%"
                          :ct="ct.idjefe"
                          :edicion="editable">
                        </ctrlF>

                        <ctrlF
                          style="width:50%;margin-left:5px"
                          :ct="ct.idage"
                          :edicion="editable">
                        </ctrlF>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:74%"
                          v-bind="$input"
                          v-model="ct.empresa[2]"
                          :label="ct.empresa[1]"
                          :disabled="!editable">
                        </uiText>

                        <uiText
                          style="width:25%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.cif[2]"
                          :label="ct.cif[1]"
                          format="cif"
                          :disabled="!editable">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:74%"
                          v-bind="$input"
                          v-model="ct.cont1[2]"
                          :label="ct.cont1[1]"
                          :disabled="!editable">
                        </uiText>

                        <uiText
                          style="width:25%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.cont1_nif[2]"
                          :label="ct.cont1_nif[1]"
                          format="cif"
                          :disabled="!editable">
                        </uiText>
                      </div>
                    </v-sheet>

                    <div style="display:flex;justify-content:space-between">
                      <div class="columna" style="width:25%">
                        <div class="cab" style="width:120px">FOTO</div>
                        <v-sheet v-bind="$cfg.styles.marco">
                          <uiImg
                            max-height="300"
                            width="180"
                            :ct="ct.foto"
                            @change="changeImg"
                            :disabled="estado!=='nuevo'">
                          </uiImg>
                        </v-sheet>
                      </div>

                      <div class="columna" style="width:74%">
                        <div class="conflex">
                          <div class="columna" style="width:50%">

                            <div class="conflex">
                              <div class="cab" style="width:150px">COD. POSTALES</div>
                            </div>

                            <v-sheet v-bind="$cfg.styles.marco">
                              <div class="columna list_zonas">
                                <div class="conflex">
                                  <v-btn
                                    icon
                                    @click="addCp(1)"
                                    :disabled="estado=='nuevo'">
                                      <v-icon >{{'mdi-map-marker-plus'}}</v-icon>
                                  </v-btn>

                                  <v-btn
                                    icon
                                    style="margin-left:20px"
                                    @click="delCp(1)"
                                    :disabled="estado=='nuevo'">
                                      <v-icon>{{'mdi-map-marker-minus'}}</v-icon>
                                  </v-btn>

                                  <v-btn
                                    v-if="itemsZonas.length"
                                    style="margin-left:20px"
                                    title="Copiar"
                                    icon
                                    @click="copyCp(0)"
                                    :disabled="estado=='nuevo'">
                                      <v-icon >{{'mdi-content-copy'}}</v-icon>
                                  </v-btn>
                                </div>

                                <v-list
                                  dense
                                  style="height:324px;width:100%"
                                  class="overflow-y-auto">
                                  <!-- <template v-for="(item, index) in itemsZonas"> -->
                                    <v-list-item dense v-for="(item, index) in itemsZonas" :key="index">
                                        <v-list-item-content>
                                          <v-list-item-title>{{ item.pob==''? '---' : item.pob }}</v-list-item-title>
                                          <v-list-item-subtitle>{{ item.cod }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                  <!-- </template> -->
                                </v-list>
                              </div>
                            </v-sheet>

                          </div>

                          <div class="columna" style="width:50%">

                            <div class="conflex">
                              <div class="cab" style="width:150px">RESTO CIAS</div>
                            </div>

                            <v-sheet v-bind="$cfg.styles.marco">
                              <div class="columna list_zonas">
                                <div class="conflex">
                                  <v-btn
                                    icon
                                    @click="addCp(2)"
                                    :disabled="estado=='nuevo'">
                                      <v-icon >{{'mdi-map-marker-plus'}}</v-icon>
                                  </v-btn>

                                  <v-btn
                                    icon
                                    style="margin-left:20px"
                                    @click="delCp(2)"
                                    :disabled="estado=='nuevo'">
                                      <v-icon>{{'mdi-map-marker-minus'}}</v-icon>
                                  </v-btn>

                                  <v-btn
                                    v-if="itemsRestoZonas.length"
                                    style="margin-left:20px"
                                    title="Copiar"
                                    icon
                                    @click="copyCp(1)"
                                    :disabled="estado=='nuevo'">
                                      <v-icon >{{'mdi-content-copy'}}</v-icon>
                                  </v-btn>
                                </div>

                                <v-list
                                  dense
                                  style="height:324px;width:100%"
                                  class="overflow-y-auto">
                                  <!-- <template v-for="(item, index) in itemsRestoZonas"> -->
                                    <v-list-item dense v-for="(item, index) in itemsZonas" :key="index">
                                        <v-list-item-content>
                                          <!-- <v-list-item-title v-html="item.pob==''? '---' : item.pob"></v-list-item-title> -->
                                          <v-list-item-title>{{ item.pob==''? '---' : item.pob }}"</v-list-item-title>
                                          <v-list-item-subtitle>{{ item.cod }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                  <!-- </template> -->
                                </v-list>
                              </div>
                            </v-sheet>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="columna" style="width:45%">
                    <div class="cab">DATOS GENERALES</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div style="display:flex">
                        <uiText
                          style="width:69%"
                          v-bind="$input"
                          v-model="ct.dir[2]"
                          :label="ct.dir[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:30%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.cpo[2]"
                          :label="ct.cpo[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:60%"
                          v-bind="$input"
                          v-model="ct.pob[2]"
                          :label="ct.pob[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:39%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.pro[2]"
                          :label="ct.pro[1]"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:27%"
                          v-bind="$input"
                          v-model="ct.tlf1[2]"
                          :label="ct.tlf1[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:27%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.mov1[2]"
                          :label="ct.mov1[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:27%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.fax1[2]"
                          :label="ct.fax1[1]"
                          :disabled="noEdit">
                        </uiText>

                        <v-checkbox
                          style="width:19%;margin-left:5px"
                          v-bind="$checkbox"
                          v-model="ct.agecom[2]"
                          :label="ct.agecom[1]"
                          :disabled="noEdit">
                        </v-checkbox>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:27%"
                          v-bind="$input"
                          v-model="ct.tlf2[2]"
                          :label="ct.tlf2[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:27%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.mov2[2]"
                          :label="ct.mov2[1]"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          style="width:27%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.fax2[2]"
                          :label="ct.fax2[1]"
                          :disabled="noEdit">
                        </uiText>

                        <v-checkbox
                          style="width:18%;margin-left:5px"
                          v-bind="$checkbox"
                          v-model="ct.agemf[2]"
                          :label="ct.agemf[1]"
                          :disabled="noEdit">
                        </v-checkbox>
                      </div>

                      <div style="display:flex">
                        <uiText
                          style="width:90%"
                          v-bind="$input"
                          v-model="ct.email1[2]"
                          :label="ct.email1[1]"
                          :disabled="noEdit">
                        </uiText>

                        <v-checkbox
                          style="width:17.5%;margin-left:5px"
                          v-bind="$checkbox"
                          v-model="ct.ageind[2]"
                          :label="ct.ageind[1]"
                          :disabled="noEdit">
                        </v-checkbox>
                      </div>

                      <uiText
                        v-bind="$input"
                        v-model="ct.email2[2]"
                        :label="ct.email2[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs"
                        :edicion="!noEdit">
                      </uiObs3>
                    </v-sheet>

                    <div class="cab">OTROS DATOS</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div style="display:flex">
                        <uiText
                          style="width:60%"
                          v-bind="$input"
                          v-model="ct.banco[2]"
                          :label="ct.banco[1]"
                          format="banco"
                          :disabled="!editable">
                        </uiText>

                        <uiText
                          style="width:20%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.iva[2]"
                          :label="ct.iva[1]"
                          :disabled="!editable">
                        </uiText>

                        <uiText
                          style="width:20%;margin-left:5px"
                          v-bind="$input"
                          v-model="ct.irpf[2]"
                          :label="ct.irpf[1]"
                          :disabled="!editable">
                        </uiText>
                      </div>

                      <div style="display:flex">
                        <uiDate
                          v-model="ct.fhalta[2]"
                          :label="ct.fhalta[1]"
                          :disabled="!editable">
                        </uiDate>

                        <uiDate
                          style="margin-left:5px"
                          v-model="ct.fhbaja[2]"
                          :label="ct.fhbaja[1]"
                          :disabled="!editable">
                        </uiDate>

                        <uiDate
                          style="margin-left:5px"
                          v-model="ct.fhnac[2]"
                          :label="ct.fhnac[1]"
                          :disabled="!editable">
                        </uiDate>
                      </div>

                      <div style="display:flex">
                        <uiPass
                          styleComp="width:40%"
                          :ct="ct.passwd"
                          :disabled="noEdit">
                        </uiPass>

                        <uiDate
                          style="width:40%;margin-left:5px"
                          v-model="ct.acceso[2]"
                          :label="ct.acceso[1]"
                          type="datetime-local"
                          :disabled=true>
                        </uiDate>

                        <v-checkbox
                          style="width:20%;margin-left:15px"
                          v-bind="$checkbox"
                          v-model="ct.web[2]"
                          :label="ct.web[1]"
                          :disabled="!editable">
                        </v-checkbox>
                      </div>

                      <div style="display:flex">
                        <v-menu top offset-y>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on='on' :disabled="!noEdit"
                              v-bind="$cfg.btn.busca_with_text"
                              style="width:20%">
                                Contrato <v-icon right dark>{{ "mdi-file-document-edit-outline" }}</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                              v-for="(item, index) in itemsContratos"
                              :key="index"

                              @click="contratos(item.contrato)">
                                <v-list-item-title>{{ item.label }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <uiDate
                          style="width:58%;margin-left:5px"
                          v-model="ct.ctt_firma[2]"
                          :label="ct.ctt_firma[1]"
                          type="datetime-local"
                          :disabled=true>
                        </uiDate>

                        <v-checkbox
                          style="width:20%;margin-left:12px"
                          v-bind="$checkbox"
                          v-model="ct.ctt[2]"
                          :label="ct.ctt[1]"
                          :disabled="!editable || ndep<8">
                        </v-checkbox>
                      </div>

                      <div style="display:flex">
                        <v-btn
                          style="width:40%"
                          :disabled="true"
                          v-bind="$cfg.btn.busca_with_text">
                            Entrega Material <v-icon right dark>{{ "mdi-truck-delivery-outline" }}</v-icon>
                        </v-btn>

                        <v-checkbox
                          style="margin-left:15px"
                          v-bind="$checkbox"
                          v-model="ct.ent_mat[2]"
                          :label="ct.ent_mat[1]"
                          :disabled="true">
                        </v-checkbox>
                      </div>
                    </v-sheet>
                  </div>
                </div>
              </div>
            </v-sheet>


            <!-- Ventana de Componentes Dinámicos  -->
            <component
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"
              :readOnly="readOnly"
              :accionZona="accionZona"
              :tipoZona="tipoZona"
              :cps="cps"
              tipoDocumento='12'
              :apd="apd"
              @updateZonas="updateZonas"
              @sendLiquidacion="sendLiquidacion"
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
  import apdMZonas from "@/components/apdMZonas";
  import apdsContrato from "@/components/apdsContrato";
  import commonDocsF from "@/components/commonDocsF.vue";
  import commonFrasF from "@/components/commonFrasF.vue";

  export default {
    mixins: [mixM],
    components: {
      baseHeader,
      baseBtraMto,
      baseBtraExtra,
      dualTemplate,
      apdMZonas,
      apdsContrato,
      commonDocsF,
      commonFrasF
    },

    props: {},

    data() {
      return {
        stIni: {
          api: "apdM",
          name:"apdM",
          titulo:"·Mantenimiento APDS",
          recordAccess:"local",
          mView:'',
          pView:[]
        },


        showpass1: false,
        itemsTarifa: [],
        itemsContratos: [],
        itemsZonas: [],
        itemsRestoZonas: [],
        itemsCP:[],
        cps:"",
        accionZona:0,
        tipoZona:0,
        apd:0
      };
    },




    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { "accion":'documentos', "btn": "docs" },
          { "accion":'facturas', "btn": "facturas" }
        ];

        // array de Tarifas
        this.itemsTarifa= [
          { id: "1", label: "1" },
          { id: "2", label: "2" },
          { id: "3", label: "3" },
          { id: "4", label: "4" },
          { id: "5", label: "5" },
          { id: "6", label: "6" },
          { id: "7", label: "7 (Esp)" },
          { id: "8", label: "8 (A)" },
          { id: "9", label: "9 (A)" },
          { id: "10", label: "10 (A)" },
          { id: "11", label: "11 (A)" }
        ];

        // array de Contratos
        this.itemsContratos= [
          { id:"0", label: "Contrato Servicios", contrato: "servicios" },
          { id:"1", label: "Borrador Contrato", contrato: "contrato" },
          { id:"2", label: "Declaración Jurada", contrato: "declaracion" },
          { id:"3", label: "*FIRMA Contrato", contrato: "firma" }
        ]

      },


      //
      iniDataApi() {
        this.apiArgs.codPostales= ["", "codPostales", { fnt:'get', tp:0, apd:0 }, "apds"];
        this.apiArgs.imagenSet= ["", "imagenDB", { fnt:'set', tip:0, tip_id:0 }, "aplica"];
        this.apiArgs.imagenDel= ["", "imagenDB", { fnt:'del', tip:0, tip_id:0 }, "aplica"];
        this.apiArgs.liquidacionApds= ["", "factura", { id:0, ema:'*', opc:1 }, "apds"];
      },


      // gancho por si necesitamos cargar datos auxiliares
      // entro DESPUÉS de obtener los datos del Mto
      async getDataAux() {

        if (this.estado=='nuevo') {
          this.itemsZonas= [];
          this.itemsRestoZonas= [];
          return;
        }

        // llamada API
        let param= { apiArg:this.apiArgs.codPostales, args:{ apd:this.record.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult Cod. Postales: ', apiResult);

        //
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // actualizo arrays de zonas
        this.itemsZonas= apiResult.r[0];
        this.itemsRestoZonas= apiResult.r[1];

        // guardo códigos postales separados por comas
        this.itemsCP= apiResult.r[2];
      },

      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.botonerasSet();
      },

       // desahbilitamos botones extra cuando se edita
       botonerasSet() {
        this.btnSet(this.btExtCfg, 'documentos', { view: true, disabled:!this.noEdit? true : false });
        this.btnSet(this.btExtCfg, 'facturas', { view: true, disabled:!this.noEdit? true : false });        
      },

      // entra al cambiar la imagen de la foto
      // guarda imagen
      changeImg() {
        this.guardarImagen();
      },


      // guarda / elimina imagen
      async guardarImagen() {
        let api= this.ct.foto[4].comp.files=== -1? 'imagenDel' : 'imagenSet';

        // apiCall
        let args= await this.$store.dispatch("apiCallComposicion", { apiArg: this.apiArgs[api], args:{ tip:this.ct.foto[4].comp.tip, tip_id:this.record.id }});
        args.args= JSON.stringify(args.args);

        // pasa los argumentos a FORMDATA
        let formData = new FormData();
        for (const key in args) {
          formData.append(key, args[key]);
        }

        // añade la imagen a formData
        formData.append("DOCUM", this.ct.foto[4].comp.files);
        formData.append("token", sessionStorage.getItem("login") || null);

        // ejecuto llamada API
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: formData });
        if (apiResult.sql.error) this.$root.$alert.open(apiResult.sql.msg, 'error');
      },



      // COMPONENTES DINAMICOS (asignación)
      //
      documentos() {
        this.componenteDinamico= 'commonDocsF';
      },

      //
      facturas() {
        this.componenteDinamico= 'commonFrasF';
      },


      // contratos
      contratos(val) {
        if (val=== 'firma') this.contratoFirma();
      },


      //
      contratoFirma() {
        this.apd= this.record.id;
        this.componenteDinamico= "apdsContrato";
      },

      // fn para  seleccionar la entrega de material a un APD
      entrega_material() {
        //alert("entrega material");
      },




      // añade código postal a la zona
      addCp(tipo) {
        this.accionZona= 1;
        this.tipoZona= tipo;
        this.componenteDinamico= 'apdMZonas';
      },


      // elimina código postal a la zona
      delCp(tipo) {
        this.accionZona= 2;
        this.tipoZona= tipo;
        this.componenteDinamico= 'apdMZonas';
      },


      // copia códigos postales
      copyCp(tipo) {
        this.cps=  this.itemsCP[tipo];
        this.$root.$alert.open('Códigos Postales copiados', 'info', 1500);
      },


      // actualizo list de la zona recibida
      updateZonas(evt) {
        this.itemsCP[Number(evt.tipo) - 1]= evt.r[1][0];
        if (evt.tipo==1) {
          this.itemsZonas= evt.r[0];
          return;
        }

        this.itemsRestoZonas = evt.r[0];
      },


      // envio Facturas Liquidación por email
      sendLiquidacion(item) {
        if (!Object.keys(item).length) return;

        this.$root.$alert.open('Enviamos eMail Liquidacion ?<br>APD: <b>'
                              + this.syncRecord.name + '</b><br>Factura: <b>' + item.factura
                              + '</b><br>Expedientes: <b>' + item.exped
                              + '</b><br>Importe: <b>' + item.importe + '</b><br><br>', 'confirm')
        .then(r=> { if (r) this.envio_email(item.id); });
      },

      async envio_email(id) {
        //igual que pdf_factura
        let param = { apiArg: this.apiArgs.liquidacionApds, args: { id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Email enviado correctamente', 'success');
      },

    },



    computed: {

      editable() {
        return (!this.noEdit && this.app==1);
      },

    },

  };
</script>


<style scoped>
  .list_zonas .v-list-item {
    padding:0px;
    margin:0px;
  }

  .list_zonas .v-list-item__content {
    padding: 0px 0px !important;
  }

  .list_zonas .v-list-item__title {
    font-size: 10px !important;
  }

  .list_zonas .v-list-item__subtitle {
    font-size: 10px !important;
  }
</style>