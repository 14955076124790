<!-- Mto Suplidos -->

<template>
  <div class="suplidosMX" v-if="loaded">
    <v-sheet class="contenedor" :elevation="0"> 
    
      <!-- Botoneras -->
      <div class="pb-2 d-flex justify-left">
        <!-- Botones Mto -->
        <baseBtraMto 
          style="width:230px;"           
          :modulo="btMtoCfg" 
          :estado="estado"
          @onEvent="execAccion">        
        </baseBtraMto>     

        <!-- Botones Extra -->
        <baseBtraExtra
          style="padding-left:25px"
          :modulo="btExtCfg"
          @onEvent="execAccion">
        </baseBtraExtra>
      </div>


      <!-- Controles del Mto -->
      <div class="contenedor">    

        <div class="columna">
          <div class="conflex">
            <uiText
              style="width:15%"
              v-model="ct.id[2]"
              label="Id"
              labelSide
              readonly>              
            </uiText>

            <uiDate
              style="width:25%;margin-left:5px"
              v-model="ct.fhhr[2]"
              label="Fecha"
              type="datetime-local"
              labelSide
              readonly>             
            </uiDate>          
            
            <uiText
              style="width:25%;margin-left:5px"
              v-model="ct.conta_asi[2]"
              label="Asiento"
              labelSide
              readonly>              
            </uiText>

            <uiText
              style="width:30%;margin-left:5px"
              v-model="ct.usu[2]"
              label="Usuario"
              labelSide
              readonly>           
            </uiText>
          </div>

          <uiMultiple     
            style="width:100%"            
            :ctrl="ct.cta_id"
            :ct="ct"                  
            :disabled="noEdit">           
          </uiMultiple>
         
          <v-textarea
            v-bind="$textarea"
            v-model="ct.des[2]"
            :label="ct.des[1]"
            rows="3"
            no-resize
            :disabled="noEdit">  
          </v-textarea>

          <div class="conflex">           
            <uiText
              style="width:33%"              
              v-model="ct.fra_doc[2]"
              label="Factura"
              :disabled="noEdit">
            </uiText>

            <uiDate 
              style="width:32%;margin-left:5px"
              v-model="ct.fra_fh[2]"
              :label="ct.fra_fh[1]"
              :disabled="noEdit">          
            </uiDate>

            <uiText
              style="width:33%;width:33%;margin-left:5px"
              v-model="ct.tot[2]"
              label="Total"
              format="money"
              readonly>              
            </uiText>
          </div>


          <div class="conflex" style="padding-top:10px;justify-content:flex-end">
            <div class="columna">
              <div class="cab">Base</div> 
              <v-sheet :elevation="4"  style="padding:5px">        
                <uiText  
                  style="margin-top:43px"                            
                  v-model="ct.base1[2]"
                  :disabled="noEdit"
                  format="money"
                  :ndecimals=2
                  @blur="calcular">              
                </uiText>

                <uiText                            
                  v-model="ct.base2[2]"
                  :disabled="noEdit"
                  format="money"
                  :ndecimals=2
                  @blur="calcular">          
                </uiText>

                <uiText                            
                  v-model="ct.base3[2]"
                  :disabled="noEdit"
                  format="money"
                  :ndecimals=2
                  @blur="calcular">                 
                </uiText>

              </v-sheet>
            </div>

            <div class="columna" style="margin-left:20px;">
              <div class="cab">Iva</div> 
              <v-sheet :elevation="4"  style="padding:5px">
                <uiText 
                  style="margin-top:43px"                           
                  v-model="ct.ivapor1[2]"
                  :disabled="noEdit"
                  @blur="calcular"
                  format="decimal"
                  :ndecimals=2
                  simbolo="%">                 
                </uiText>

                <uiText                            
                  v-model="ct.ivapor2[2]"
                  :disabled="noEdit"
                  @blur="calcular"
                  format="decimal"
                  :ndecimals=2
                  simbolo="%">               
                </uiText>

                <uiText                            
                  v-model="ct.ivapor3[2]"
                  :disabled="noEdit"
                  format="decimal"
                  :ndecimals=2
                  simbolo="%"
                  @blur="calcular">                 
                </uiText>

                <uiText          
                  label="I.R.P.F"                  
                  v-model="ct.irpfpor[2]"
                  :disabled="noEdit"
                  @blur="calcular"
                  format="decimal"
                  :ndecimals=2
                  simbolo="%">                
                </uiText>                
              </v-sheet>
            </div>
                
            <div class="columna" style="margin-left:20px;">
              <div class="cab">Importe</div> 
              <v-sheet :elevation="4" style="padding:5px">
                <uiText                            
                  v-model="ct.sup[2]"
                  label="Suplidos"
                  :disabled="noEdit"
                  format="money"
                  @blur="calcular">               
                </uiText>

                <uiText                            
                  v-model="ct.iva1[2]"
                  format="money"
                  :disabled=true>              
                </uiText>

                <uiText                            
                  v-model="ct.iva2[2]"
                  format="money"
                  :disabled=true>                   
                </uiText>

                <uiText                            
                  v-model="ct.iva3[2]"
                  format="money"
                  :disabled=true>     
                </uiText>

                <uiText                            
                  v-model="ct.irpf[2]"
                  format="money"
                  :disabled=true>                  
                </uiText>
              </v-sheet>
            </div>
          </div>
        </div>                                        
      </div>
    </v-sheet>   

    <!-- Componentes virtuales  -->  
    <component      
      :is="componenteDinamico"
      syncUpdate     
      :storeRaiz="storeName"
      :masterStore="storeName"        
      :readOnly="readOnly"
      :viewMtoOnly="viewMtoOnly"
      :recordAux="recordAux"
      :tipo=2
      @refresh="$emit('refresh')"
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
    </component>    
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js"; 
  import baseBtraMto from "@/base/baseBtraMto";
  import baseBtraExtra from "@/base/baseBtraExtra";  
  /* import frasDigi from "@/components/frasDigi"; */
       
  export default {
    mixins: [mixM],  
    components: { baseBtraMto, baseBtraExtra },
    props: {},

    data() {
      return {
        stIni: {
          api: "suplidosMX",
          name:"suplidosMX",          
          titulo:"",
          recordAccess:"local",
          mView:'',
          pView:[],
          relation:'prescab_id'
        },  
       
      };
    },  
    

    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)"); 

        this.btExtCfg.btnsAccion= [          
          /* { accion:'frasDigi', texto: "DIG", title:"Digitalización de Facturas", styB:this.styleBtnDigi } */
        ]; 

        console.log('btExtCfg;;; ', this.btExtCfg.btnsAccion);
                
      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
        this.btExtCfg= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
      },


      // validación particular de controles
      validateParticular() {
        let error="";

        if (!this.ct.fra_doc[2]) error= 'Debe indicar un Nº de Factura';
        if (!this.ct.fra_fh[2]) error= 'Debe indicar una Fecha de Factura';
        if (!this.ct.tot[2]) error= 'Debe tener un Importe';
        if (this.ct.cta_id[2]== 0) error= 'Debe indicar una Cuenta';
        
        // si hay algún error, muestro mensaje
        if (error) {
          this.$root.$alert.open(error, 'error');
          return false;
        }

        return true;
      },


      // gancho final para la acción 'nuevo'
      nuevoFin() {
        this.$set(this.ct.fra_fh, 2, this.actualDate());
        this.crudFin();
      },


      //si contabilizado no edicion, ni borrado
      crudFin() {        
        this.btnSet(this.btMtoCfg, 2,{ disabled: (this.record.conta_asi> 0) });
        this.btnSet(this.btMtoCfg, 3,{ disabled: (this.record.conta_asi> 0) });
        /* this.btnSet(this.btExtCfg, 'frasDigi',{ disabled: this.estado=='nuevo' }); */
      },


      // componente dinámico de Digitalización de Facturas
      /* frasDigi() {       
        this.componenteDinamico= 'frasDigi';
      }, */


      //
      calcular() {
        let X= this.ct;

        this.$set(X.sup, 2, this.round(X.sup[2]));
        this.$set(X.base1, 2, this.round(X.base1[2]));
        this.$set(X.ivapor1, 2, this.round(X.ivapor1[2]));
        this.$set(X.iva1, 2, (X.ivapor1[2]>0 ? this.round(X.base1[2] * X.ivapor1[2] / 100) : 0));
        this.$set(X.base2, 2, this.round(X.base2[2]));
        this.$set(X.ivapor2, 2, this.round(X.ivapor2[2]));
        this.$set(X.iva2, 2, (X.ivapor2[2]>0 ? this.round(X.base2[2] * X.ivapor2[2] / 100) : 0));
        this.$set(X.base3, 2, this.round(X.base3[2]));
        this.$set(X.ivapor3, 2, this.round(X.ivapor3[2]));
        this.$set(X.iva3, 2, (X.ivapor3[2]>0 ? this.round(X.base3[2] * X.ivapor3[2] / 100) : 0));
      
        let base= X.base1[2]+X.base2[2]+X.base3[2];
        let iva= X.iva1[2]+X.iva2[2]+X.iva3[2];
        this.$set(X.irpfpor, 2, this.round(X.irpfpor[2]));
        this.$set(X.irpf, 2, (X.irpfpor[2]>0 ? this.round(base * X.irpfpor[2] / 100) : 0));

        //this.calculaTot();
        this.$set(X.tot, 2, (base + iva + X.sup[2] - X.irpf[2]));
      },


      // style botón Digitalización Facturas
      styleBtnDigi() {
        return `background-color:${this.record.digf_fh? '#a5d6a7' : '#ef9a9a'} !important;`;
      }


    },


    computed: {

      relationID() {       
        if (!this.recordAux || !Object.keys(this.recordAux).length ) return 0;        
        return this.recordAux.id;
      },

    }

  };
</script>
