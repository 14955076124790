<template>
  <div class="flex" v-if="tip>0">
      <div class="canvas_header px-2 py-2">
        <h3>
           <v-btn class="ma-0" text icon :color="geo_pos>''?'green':'red'">
            <v-icon>mdi-map-marker-circle</v-icon>
          </v-btn>
          FIRMADO {{ fecha }}
        </h3>
        
        <v-btn class="secondary mr-1 mt-1" @click='resetCanvas'>LIMPIAR</v-btn>        
        <v-btn class="success mt-1" @click='guardar'>GUARDAR</v-btn>
        
      </div>

      <div class="canvas_pad pt-1 pb-3">
        <canvas ref="canvas" id='firmaCanvas' width='700' height='300'/>                  
      </div>         

  </div>  
</template>


<script>
  export default {

    props: {
      tip: { type:[Number, String], default: '0'}, // 1=iap,2=contrato ...
      exp: { type:[Number, String], default: '0'},
      apd: { type:[Number, String], default: '1'},

    
    },
    
    data () {
      return {        
        canvas: null,
        context: null,
        isDrawing: false,
        startX: 0,
        startY: 0,
        points: [],
        position: null,

        geo_pos: '', 
        geo_error: '',

      }
    },

    created() {         
      // 
      document.body.addEventListener("touchstart", function (e) {         
        if (e.target == document.getElementById('firmaCanvas')) {            
          e.preventDefault();            
        }
      }, { passive: false });

      document.body.addEventListener("touchend", function (e) {             
        if (e.target == document.getElementById('firmaCanvas')) {            
          e.preventDefault();
        }          
      }, { passive: false });

      document.body.addEventListener("touchmove", function (e) {            
        if (e.target == document.getElementById('firmaCanvas')) {            
          e.preventDefault();
        }
      }, { passive: false });

      //this.geolocalizacion();
        
    },

    mounted(){
      this.geolocalizacion();

      var vm = this;
      vm.canvas = vm.$refs.canvas
      vm.context = vm.canvas.getContext("2d");   
      vm.canvas.addEventListener('MSPointerDown', vm.drawpointerdown);
      vm.canvas.addEventListener('MSPointerMove', vm.drawpointermove);
      vm.canvas.addEventListener('mousedown', vm.mousedown);
      vm.canvas.addEventListener('mousemove', vm.mousemove);
      document.addEventListener('mouseup', vm.mouseup);
      //this.drawMouse();
      this.position=vm.canvas.position;


      // Set up touch events for mobile, etc
      vm.canvas.addEventListener("touchstart", function (e) {      
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY
        });

        vm.canvas.dispatchEvent(mouseEvent);
      }, false);

      vm.canvas.addEventListener("touchend", function () {
        var mouseEvent = new MouseEvent("mouseup", {});
        vm.canvas.dispatchEvent(mouseEvent);
      }, false);

      vm.canvas.addEventListener("touchmove", function (e) {
        var touch = e.touches[0];
        var mouseEvent = new MouseEvent("mousemove", {
          clientX: touch.clientX,
          clientY: touch.clientY
        });

        vm.canvas.dispatchEvent(mouseEvent);
      }, false);
      
    },

    computed: {
      fecha()  {      
        return new Date().toLocaleDateString("es-ES", {year:'numeric',month:'long',day:'numeric'});      
        //return moment().format('LLL');
      },
      
    },

    methods: {

      ///////////////////////////////////////////////////////////// canvas
      // Get the position of a touch relative to the canvas
      getTouchPos(canvasDom, touchEvent) {
        var rect = canvasDom.getBoundingClientRect();
        return {
          x: touchEvent.touches[0].clientX - rect.left,
          y: touchEvent.touches[0].clientY - rect.top
        };
      },

      //
      mousedown(e) {
        var vm = this
        var rect = vm.canvas.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;
        
        vm.isDrawing = true;
        vm.startX = x;
        vm.startY = y;
        vm.points.push({ x: x, y: y });
      },

      //
      mousemove(e){
        var vm = this
        var rect = vm.canvas.getBoundingClientRect();
        var x = e.clientX - rect.left;
        var y = e.clientY - rect.top;

        if (vm.isDrawing) {
          vm.context.beginPath();
          vm.context.moveTo(vm.startX, vm.startY);
          vm.context.lineTo(x, y);
          vm.context.lineWidth = 3;
          vm.context.lineCap = 'round';
          vm.context.strokeStyle = "rgba(0,0,0,1)";
          vm.context.stroke();
          
          vm.startX = x;
          vm.startY = y;  
          
          vm.points.push({ x: x, y: y });
        }
      },


      //
      mouseup(e){
        console.log(e);
        var vm = this;
        vm.isDrawing = false;
        if (vm.points.length > 0) {
          localStorage['points'] = JSON.stringify(vm.points);
        }
      },

      //
      drawpointerdown(e) {
        var vm = this;
        e = e.originalEvent;
        let x = e.pageX - this.position.left;
        let y = e.pageY - this.position.top;
        vm.context.beginPath();
        vm.context.moveTo(x,y);
      },

      //
      drawpointermove(e) {
        var vm = this;
        e.preventDefault();
        e = e.originalEvent;
        let x = e.pageX - this.position.left;
        let y = e.pageY - this.position.top;
        vm.context.lineTo(x,y);
        vm.context.stroke();
      },    

      //
      resetCanvas() {
        var vm=this;
        //vm.context = vm.canvas.getContext("2d");
        vm.context.clearRect(0,0, vm.canvas.width, vm.canvas.height);

        //vm.canvas.width = this.canvas.width;
        vm.points.length = 0; // reset points array
      },

      /////////////////////////////////////////////////// 
      //
      async guardar() {        
        //guardamos imagen de canvas
        let img=this.saveImage();
                
        //alert('Guardar Firma ' + this.exp + " - " + this.apd + this.geo_pos);

        let X=['','iap_pdf','contrato_pdf']
        let aa=X[this.tip];

        // monto argumentos para hacer la llamada a la API
        var args = { tipo:'fnc', accion: 'apds', 
          fn_args: { 
            accion: aa, 
            tpd: 2,       //guardar
            exp:this.exp, 
            apd:this.apd,
            imagen: img, 
            mapa: this.geo_pos,          
          }
        }             
        console.log('args Guardar: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });  
        
        let msg=" DOCUMENTO FIRMADO ... correctamente";
        if (apiResult.sql.error) { 
          msg='Error FIRMA documento ' + aa + ': ' + apiResult.sql.msg;
          this.$root.$alert.open(msg, 'success');
          return;
        }

        this.$root.$alert.open(msg, 'success', 2500);
        //cerramos y salimos
        this.$emit('onEvent', { accion:'evtFirma' });

      },

      saveImage() {
        return this.canvas.toDataURL();
      },

      //posicion desde created / mounted

      geolocalizacion() {
        this.geo_pos='';

        if(!("geolocation" in navigator)) {
          this.geo_error = 'Geolocalizacion No Permitida';
          return;
        }        
        navigator.geolocation.getCurrentPosition(pos => {          
          this.geo_pos= pos.coords.latitude + "," + pos.coords.longitude;           
          //alert(this.geo_pos);
        }, err => {          
          this.geo_error = err.message;          
        })
        console.log('** geolocalizacion',this.geo_pos,this.geo_error);
      },
    
    },
  }

</script>



<style>  
  canvas { border: 3px solid red; cursor:crosshair; }  
</style>
