export var mixinPresPack = {
    methods: {
        presPackEncode(records){
           // cabecera_fields =["preferente", "paxfun", "fhrev", "imp", "descrip", "obs"];
           let record_fields = ["prod_id", "namea", "sp", "und", "sup", "iva", "pvp", "imp"];
		
			let pack = "";
            records.forEach(rec=>{
                pack=pack>""?pack+"$":pack
                let recFields=''

                record_fields.forEach(field=>{
                    recFields=recFields>""?recFields+":":recFields
                    recFields=recFields+rec[field]
                })

                pack=pack+recFields
            })
			return pack
			}
			
        },
    };



       // public var OLD_detalle:DataProvider;
		// public var NEW_detalle:DataProvider;
		// public var HIST_detalle:DataProvider;

	// 		for (var i = 0; i < records.length; i++) {
	// 			item = hist_dp.getItemAt(i);
	// 			if (i > 0) pack += "$"; // fin de registro

	// 			for (var s = 0; s < detalle_fields.length; s++) {
	// 				if (s > 0) pack += ":"; // fin de campo
	// 				pack += item[detalle_fields[s]];
	// 			}
	// 		}
	// 		return pack
	// 	}


	// 	public function desempaqueta_records(paquete) {
	// 		// Devuelve array de registros de detalle
	// 		return A_record_get(detalle_fields, paquete, "$", ":")
	// 	}




	// 	// Devuelve array de registros de detalle
	// 	function A_record_get(Afields, pack, field_separator = "]", field_separator_d = ":") {
	// 		var records: Array = new Array();
	// 		var records_pack: Array;

	// 		records_pack = pack.split(field_separator)
	// 		for (var i: int = 0; i < records_pack.length; i++) {
	// 			records.push(record_get(Afields, records_pack[i], field_separator_d))
	// 		}
	// 		return records

	// 	}

	// 	// devuelve 1 registro de detalle con los nombres de campo Afields
	// 	function record_get(Afields, pack: String, field_separator = "]") {
	// 		// record_pack es el campo  empaquetado guadado en la tabla de historico
	// 		// el orden de los campos del diccionario debe de coincidir con el orden de los campos guardados en la tabla
	// 		// devuelve objeto record de par de valores campo valor
	// 		trace("")
	// 		trace(">>>>  rcord_get")
	// 		var record = new Object();
	// 		var Af: Array = pack.split(field_separator);

	// 		for (var i: int = 0; i < Afields.length; i++) {
	// 			record[Afields[i]] = Af[i]
	// 			trace(Afields[i] + "=" + Af[i])
	// 		}
	// 		return record;
	// 	}








	// 	// compara registros iniciales y finales
	// 	// devuelve array de registros solo con los cambios y el tipo de cambio (A,B,M)
	// 	function detalle_historico(real,  importado) {
	// 		OLD_detalle=importado;
	// 		NEW_detalle=real;
	// 		HIST_detalle = new DataProvider();

			
			
	// 		if (real== null) return "";
	// 		trace("OLD lineas:" + OLD_detalle.length);
	// 		trace("NEW lineas:" + NEW_detalle.length);

			
	// 		detalle_old()

	// 		// Añadir Borrados de 
	// 		for (i = 0; i < OLD_detalle.length; i++) {
	// 			Oi = OLD_detalle.getItemAt(i);
	// 			Oi.op = "B"
	// 			HIST_detalle.addItem(Oi)
	// 			trace("	NEW borrado ", Oi.op, Oi.name, Oi.imp)
	// 		}
	// 		// Añadir Nuevos
	// 		for (i = 0; i < NEW_detalle.length; i++) {
	// 			var Ni: Object;
	// 			Ni = NEW_detalle.getItemAt(i);
	// 			Ni.op = "A"
	// 			HIST_detalle.addItem(Ni)
	// 			trace("NEW AÑADIDO ", Ni.op, Ni.name, Ni.imp)
	// 		}

	// 		return detalle_string(HIST_detalle);
	// 	}

	// 	function detalle_old() {
	// 		//trace( "PASANDO historico_old");
	// 		var Oi: Object;
	// 		var i: int;
	// 		for (i = 0; i < OLD_detalle.length; i++) {
	// 			Oi = OLD_detalle.getItemAt(i);
	// 			trace("DETALLE  OLD", Oi.name, Oi.imp)
	// 			//trace(i+ "# "+Oi.name);

	// 			// Eliminar los que no han cambiado
	// 			if (detalle_new(Oi)) {
	// 				OLD_detalle.removeItem(Oi)
	// 				detalle_old()
	// 				break;
	// 			}
	// 		}


	// 	}

	// 	function detalle_new(Oi: Object) {
	// 		var change: Boolean
	// 		var Ni: Object;
	// 		for (var j: uint = 0; j < NEW_detalle.length; j++) {

	// 			Ni = NEW_detalle.getItemAt(j);

	// 			change = false;
	// 			for (var s = 0; s < detalle_fields.length; s++) {
	// 				//for (var s:String in Ni ) {
	// 				trace("COmPARANDO ", s, Ni[s], Oi[s])
	// 				//if (Ni[s]!=Oi[s] && (s!="orden" && s!="id")){
	// 				if (Ni[detalle_fields[s]] != Oi[detalle_fields[s]]) {
	// 					change = true
	// 					trace("CON CAMBIO", Ni.name, Ni.imp)
	// 					break;
	// 				}
	// 			}

	// 			// Eliminar los que no tienen cambios
	// 			if (!change) {
	// 				NEW_detalle.removeItem(Ni);
	// 				trace("REMOVIENDO SIN CAMBIO NEW", Ni.name, Ni.imp)
	// 				trace("REMOVIENDO SIN CAMBIO OLD", Oi.name, Oi.imp)
	// 				return true;
	// 			}
	
	// 			// Hay algun cambio dentro de un mismo Prod_id/namea
	// 			// Añadir Modificaciones
	// 			if (Ni.prod_id == Oi.prod_id && Ni.namea == Oi.namea) {
	// 				Oi.op = "M";
	// 				HIST_detalle.addItem(Oi)
	// 				trace(Oi.op, Oi.name, Oi.imp)
	// 				NEW_detalle.removeItem(Ni)
	// 				return true
	// 			}
	// 		}
	// 		return false;
	// 	}
	// 	}
	// }

