<template>
  <div class="presIva">
    <!-- <div class="cab" style="width:92%;margin-left:8px">IVA DESGLOSE</div> -->
    <v-sheet v-bind="$cfg.styles.marco">
      <div class="conflex">
        <div class="cab" style="width:32%; text-align:center;">IVA %</div>
        <div class="cab" style="width:32%; text-align:center; margin-left:3px">BASES</div>
        <div class="cab" style="width:32%; text-align:center; margin-left:3px">IVAS</div>
      </div>

      <div v-for="n in 5" :key="n">
        <div class="conflex" v-if="ct['base' + n][2] != 0">
          <vllabel                       
            :txt="`${ct['ivapor' + n][2]}%`"          
            style="width:33%"
            stilot="text-align:right;background-color:#d7dee2;color:#5e92bc">            
          </vllabel>
          
          <vllabel                        
            :txt="ct['base' + n][2] | num(2)"
            style="width:33%"
            stilot="text-align:right">
          </vllabel>

          <vllabel                        
            :txt="ct['iva' + n][2] | num(2)"
            style="width:33%"
            stilot="text-align:right">
          </vllabel>
        </div>
      </div>     
    </v-sheet>
  </div>
</template>



<script>

  export default {
    props: {
      ct: { type: Object, default: null }      
    }
  };

</script>
