//
// STORE MD.JS

import Vue from "vue";

const getDefaultState = () => {
  return {
    /* Maestro */  
    storeTipo:'MD', // Pendiente: ¿uso?
    record: {},    
    perm: [],
    permExtra: [], 
    
    ID:0,
    accion:'',
    modal:false,
    recordRead: {},
    // accionCt:{}, (solo storeF)
    disparo:false,   
    estado: "indefinido",
    
    /* Detalle */
    ID1:0,
    accion1:'',
    estado1: "indefinido",
    records1: [],    
    change1: false,    
    recordsSelected: [],
    ordenando:false,
    
  };
};


export default {
  namespaced: true,
  state: () => {
    return {
      api: "",
      name: "",
      titulo:"",
      recordAccess: "local",
      relation: "",     
      mView:"",
      pView:[],
      // ver si relation1 no es necesario porque el servidor conoce la relacion

      relation1: "",
      sch: 0,
      sch1: 0,      
            
      ...getDefaultState()
    };
  },


  mutations: {
    // inicializar variables del state
    INI(state, data) {
      Object.assign(state, data);      
      console.log("INI store", data);
    },

    // resetear variables del state
    RESET(state) {
      Object.assign(state, getDefaultState());
      console.log("RESET store");
    },

    // guardar schemas y permisos y botonera en el store
    SCHSET(state, data) {
      console.log("SCHSET:: ", data);
      Vue.set(state, "sch", data[0]);
      Vue.set(state, "sch1", data[1]);      
    },

    //
    PESOSET(state, data) {        
      Vue.set(state, "perm", data.permisoMto);
      Vue.set(state, "permExtra", data.permisoAccion);
    },

    // actualizar propiedad del state
    data2State(state, data) {
      if (data.key) {
        Vue.set(state[data.prop], data.key, data.value);
        return;
      }

      Vue.set(state, data.prop, data.value);
    },

    // actualizar ID, record Maestro y records lineas
    recordSet(state, records) {    
      // ID Maestro      
      Vue.set(state, 'ID', records[0].id);

      // record Maestro
      Vue.set(state, "record", JSON.parse(JSON.stringify(records[0])));
      Vue.set(state, "recordRead", JSON.parse(JSON.stringify(records)));

      // records Detalle
      Vue.set(state, "records1", JSON.parse(JSON.stringify(records[1])));      
      Vue.set(state, "recordsSelected", []);
    },

    // actualizar records Detalle
    recordSet1(state, records) {
      Vue.set(state, "records1", JSON.parse(JSON.stringify(records)));
      Vue.set(state, "recordsSelected", []);
    },

    // actualizar records Grid,
    // reemplaza los records mostrados en el grid (records1) (normalmente los filtrados/vistos)
    // y los records originales leidos
    recordReadSet1(state, records) {    
      Vue.set(state, "records1", JSON.parse(JSON.stringify(records)));
      Vue.set(state, "recordsSelected", []);
    },

    // actualizar variables del state al hacer un VER (Maestro)
    verUpdate(state) {
      Vue.set(state, "estado", "ver");
      Vue.set(state, "estado1", "indefinido");
      Vue.set(state, "change", false);
      Vue.set(state, "change1", false);
      Vue.set(state, "ordenando", false);
    },

    // actualizar variables del state al hacer un VER (Detalle)
    ver1Update(state) {    
      Vue.set(state, "estado1", "ver");                  
    },

    // actualizar variables del state al hacer un EDITAR (cabecer)
    editarUpdate(state) {
      Vue.set(state, "estado", "editar");
      Vue.set(state, "estado1", "indefinido");
      Vue.set(state, "change", false);
      Vue.set(state, "change1", false);
    },

    // actualizar variables del state al hacer un EDITAR (Detalle)
    editar1Update(state) {
      Vue.set(state, "estado1", "editar");     
    },
    
    // actualizar variables del state al hacer un NUEVO (Maestro)
    nuevoUpdate(state) {      
      Vue.set(state, "estado", "nuevo");
      Vue.set(state, "estado1", "indefinido");
      Vue.set(state, "change", false);
      Vue.set(state, "change1", false);
      Vue.set(state, "ordenando", false);
    },

    // actualizar variables del state al hacer un NUEVO (Detalle)
    nuevo1Update(state) {
      Vue.set(state, "estado1", "nuevo");
    },

    // actualizar variables del state al hacer un GUARDAR (Detalle)
    guardar1Update(state) {
      Vue.set(state, "estado1", "ver");      
      Vue.set(state, "change1", true);
      //Vue.set(state, "recordsSelected", []);
    },

    // actualizar variables del state al hacer un CANCELAR (Maestro)
    cancelarUpdate(state) {
      Vue.set(state, "estado", "indefinido");
      Vue.set(state, "estado1", "indefinido");
      Vue.set(state, "change", false);
      Vue.set(state, "change1", false);
      Vue.set(state, "ordenando", false);
    },

    // actualizar variables del state al hacer un CANCELAR (Detalle)
    cancelar1Update(state) {
      Vue.set(state, 'estado1', 'indefinido');
      Vue.set(state, 'recordsSelected', []);
    },

    // actualizar variables del state al hacer un eliminar (Maestro)
    eliminarUpdate(state) {      
      Vue.set(state, "estado", "indefinido");
      Vue.set(state, "estado1", "indefinido");
      Vue.set(state, "change", false);
      Vue.set(state, "change1", false);
    },

    // actualizar variables del state al hacer un eliminar (Detalle)
    eliminar1Update(state) {      
      Vue.set(state, "estado1", "indefinido");
      Vue.set(state, "change1", true);
      Vue.set(state, "recordsSelected", []);
    },

    // actualizar variables del state al hacer un SALIR (Detalle)
    salir1Update(state) {
      Vue.set(state, "estado1", "indefinido");      
      Vue.set(state, "recordsSelected", []);
    },

    // actualizo variables de acciones Grid
    accionSet(state, { id, accion }) {   
      console.log('accionSet;; ', id, accion);
      Vue.set(state, "ID", id);
      Vue.set(state, "accion", accion);      
    },

    // actualizo variables de acciones Grid
    accionSet1(state, { id, accion }) {      
      console.log('accionSet1;; ', id, accion);
      Vue.set(state, "ID1", id);
      Vue.set(state, "accion1", accion);      
    },

    //
    disparoSet(state) {
      state.disparo= !state.disparo;
    },

    // 
    modalSet(state, val) {           
      Vue.set(state, "modal", val);
    },

    //
    recordsSelectedSet(state, records) {     
      Vue.set(state, "recordsSelected", records);      
    },
    
    
  },


  actions: {
    // cargar schemas de Maestro y Detalle
    async ini({dispatch, commit, rootGetters}, { stIni, schAPI, storeName }) {
      commit("INI", stIni);
      
      // guardo permisos de mView
      let peso= rootGetters.pesoGet(stIni.mView || stIni.name);
      commit("PESOSET", peso);

      // si en schAPI recibo null quiere de cir que el mto particular va a utilizar
      // un schema controls propio
      if (!schAPI) return;
      await dispatch('schGet', { storeName:storeName, api:stIni.api, schAPI:schAPI }, {root:true});      
      //return context.state;
    },

    
    // ver Maestro
    async ver(context, { param, origen }) {

      // param: { apiArgs, args } o tipo de lectura forzada (tambien: local, serv, masterStore....)
      // recordAcces:'masterStore',  // EL record lo extrae del masterSyncRecord no hay que hacer lectura del servidor, guardar datos de record en state
      // recordAccess:'local',       // Al guardar devuelve los datos guardados y se hace un ver('local')
      // recordAccess:'serv',        // Al guardar no devuelve los datos guardados y hay que hacer un ver('serv')
      
      let recordAccess= context.state.recordAccess;
      let apiResult='';
      let records= {};

      //
      switch (recordAccess) {
        case 'masterStore': 
          records= [[context.rootGetters[context.state.masterStore + '/syncRecord']]];
          break;
    
        case 'serv':              
        case 'local':
          if (origen==='guardar' && recordAccess==='local') {
            // pongo recordRead para que incluya los recordsAjuntos si los tiene
            records= context.state.recordRead;

          } else {                           
            apiResult = await context.dispatch("apiCall", param, { root:true });
            // Pendiente: controlar error API               
            records= apiResult.r;                
          }              
          break;
      }
    
      console.log("apiResult ver (storeMD): ", records);
      
      // actualizo variables del state
      context.commit("recordSet", records);
      context.commit("verUpdate");      
      return records;
    },

    // ver Detalle
    ver1(context, id=null) {
      if(id== null) id=context.state.ID1;
      console.log('IDD;; ', id, context.state.recordsSelected);
      let record= context.getters.records1Find(id);
      console.log("records1Find ver1: ", record);

      // si no he encontrado el registro, salgo
      if (!record) return record;
                 
      // actualizo variables del state
      context.commit("ver1Update");      
      return record;
    },

    // abrir MX asignando record recibido a recordsSelected
    verMX(context, record) {  
      // si he pulsado en una fila ya abierta, la cierro blanqueando recordsSelected  
      if (context.state.recordsSelected.find(element => element.id== record.id)) {      
        context.commit('data2State', { prop:'recordsSelected', value:[] });
        return;
      } 
      
      // asigno record recibido a recordsSelected
      context.commit('data2State', { prop:'recordsSelected', value:[record] });
    },


    // editar Maestro
    async editar(context) {     
      // actualizo variables del state
      context.commit("editarUpdate");            
    },

    // editar Detalle
    async editar1(context, id=null) {
      if(id== null) id=context.state.ID1;
      let record= context.getters.records1Find(id);
      console.log("records1Find editar1: ", record);

      // si no he encontrado el registro, salgo
      if (!record) return record;
                  
      // actualizo variables del state
      context.commit("editar1Update");      
      return record;
    },


    // cancelar Maestro
    async cancelar(context) {
      // actualizo variables del state
      context.commit("cancelarUpdate");      
    },
    
    // cancelar Detalle
    async cancelar1(context) {
      // si el estado es NUEVO, elimino la primera linea de records y actualizo store
      if (context.state.estado1== 'nuevo') {
        context.state.records1.splice(0, 1);                        
        context.commit("cancelar1Update");
        return context.state.records1;
      }

      // el estado es EDITAR. Actualizo store      
      context.commit("ver1Update");            
    },


    // nuevo Maestro
    nuevo(context, record) {      
      // actualizo record Maestro y Detalle a arrays vacíos
      let data= [record, []];            
      context.commit("recordSet", data);
            
      // actualizo variables del state
      context.commit("nuevoUpdate");     
    },

    nuevo1(context) {
      // actualizo variables del state  
      context.commit("nuevo1Update");      
    },

    // Normalmente se llama desde la botonera top del grid
    // la botonera top llama a execAccionMX(1) 
    // execAccionMX llama a nuevoMX
    // execAcccionMx (cambia accion y disparo en el mx)
    
    nuevoMX(context, id) { 
      console.log('nuevoMX');

      // crea nuevo item de array en records
      let records= context.state.records1;      
      records.unshift({ id:id });

      // actualizo record Detalle
      context.commit("recordSet1", records);
    },


    // guardar Maestro
    async guardar(context, param) {
      // call API
      let apiResult = await context.dispatch("apiCall", param, { root:true }); 
      console.log("apiResult guardar MD: ", apiResult);
      
      // Pendiente: controlar error API
      if (apiResult.sql.error) return apiResult;
      
      // Guardo los datos de vuelta de la grabación
      if (context.state.recordAccess== 'local') {
        context.commit("recordSet", apiResult.r);          
      }

      return apiResult;     
    },

    // guardar Detalle
    guardar1(context, { record, line2End }) {
      var index= 0;

      // si estado es NUEVO, reemplazo el primer elemento del array por el record recibido      
      if (context.state.estado1== 'nuevo') {
        // actualizo id nuevo registro a un número aleatorio para que no se repita el
        // ID si se realiza una nueva linea sin haber guardado antes la cabecera
        record.id= new Date().getTime();

        // line2End: true -> ponemos la nueva linea en la última posición de los records
        // line2End: false-> dejamos la nuevo linea en la primera posición de los records
        if (line2End) {
          context.state.records1.shift();
          context.state.records1.push(record);          
          
        }else {
          context.state.records1.splice(0, 1, record);
        }
        
        // guardo como elemento seleccionado el nuevo registro
        // al hacer esto, dejo el expansible abierto
        context.state.recordsSelected= [record];
      }

      // si estado es EDITAR, busco el elemento en edición y lo sustituyo por el record recibido      
      if (context.state.estado1== 'editar') {
        index= context.getters.records1Index(record.id); 
        if (index< 0) return index;

        context.state.records1.splice(index, 1, record);        
      }
     
      // actualizo variables del state
      context.commit('guardar1Update');
      return index;
    },


    // eliminar Maestro
    async eliminar(context, param) {
      // call API
      let apiResult = await context.dispatch("apiCall", param, {root:true});            
      console.log('apiResult eliminar MD: ', apiResult);
      // Pendiente: controlar error API

      // actualizo variables del state
      context.commit('eliminarUpdate');    
      return apiResult;   
    },

    // eliminar Detalle
    eliminar1(context, id=null) {
      if (id== null) id=context.state.ID1;

      // busco el elemento en edición
      let index= context.getters.records1Index(id); 
      if (index< 0) {
        this.$root.$alert.open('Error al eliminar registro', 'error', 2000);
        return;
      }  

      // elimino elemento seleccionado del array      
      context.state.records1.splice(index, 1);      

      // actualizo variables del state
      context.commit('eliminar1Update');
    },

    // cerrar Expansible
    salir1(context) {
      // actualizo variables del state
      context.commit('salir1Update');
    },
    

    //
    line2End(context, record= null) {      
      // paso item recibido al último elemento del array
      if (!record) return;
     
      let arrayRecords= JSON.parse(JSON.stringify(context.state.records1));
      console.log("arrayRecords", arrayRecords)
      
      // encuentro el index del item en la array
      let index= context.getters.records1Index(record.id);    
      if (index<0) return;
      
      let item= arrayRecords[index];
      arrayRecords.splice(index, 1);
      arrayRecords.push(item);

      arrayRecords= arrayRecords.map((e,index) => {e.orden=index; return e});
      context.commit('recordSet1', arrayRecords);      
    },

  },


  getters: {
    recordsGrid: state => {
      return state.records1;
    },
    
    // estado Maestro
    noEdit: state => {
      return state.estado == "ver" || state.estado== "indefinido";
    },

    // estado Detalle
    noEdit1: state => {
      //return state.estado1 == "ver";
      return state.estado1 == "ver" || state.estado1== "indefinido";
    },

    // duplico por polimorfismo por llamada desde el baseGridMD 
    // porque en un expansible puedo utilizar un storeM o un storeMD
    // En el StoreM duplico la fn noEdit a noEditMX
    // En el StoreMD duplico la fn noEdit1 a noEditMX    
    noEditMX: state => {
      return state.estado1 == "ver" || state.estado1== "indefinido";
    },

    // busco ID en records Detalle. Si existe devuelvo el record, si no, undefined
    records1Find: (state) => (id) => {   
      console.log('Busco reg: (id: ', id, ') - records1: ', state.records1); 
      return state.records1.find(elem => elem.id == id);
    },

    // busco ID en records Detalle. Si existe devuelvo el indice en el array, si no, -1
    records1Index: (state) => (id) => {    
      return JSON.parse(JSON.stringify(state.records1.findIndex(elem => elem.id == id)));      
    },


    // creo syncRecord para polimorfismo entre stores sobre el record activo o seleccionado 
    // en storeF tenemos el seleccionado en recordsSelected[0]
    // en storeM tenemos el activo en record
    // en storeMD tenemos el record de la cabecera
    // ...
    // normalizamos con el getter en synRecord    
    syncRecord: state => {     
      if (!Object.keys(state.record).length) return {};
      return state.record;      
    },


    // devolvemos el record seleccionado en recordsSelected[0]
    syncRecordMX: state => {
      if (!state.recordsSelected.length) return {};      
      return state.recordsSelected[0];
    },


    

  }
};
