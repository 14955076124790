<!-- Envolvente llamadas de Expedientes -->

<template>
  <div class="expPhone" v-if="loaded">
    
    <v-dialog
      content-class="modal"        
      v-model="modal">
        <v-sheet :elevation="4">
          <!-- Cabecera -->
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.finder"
              :titulo="$store.state[storeName].titulo"
              @onEvent="eventHeader"> 
            </baseHeader>
          </div>
     
          <!-- Filtro -->
          <div class="contenedor" style="width:650px"> 
            
              <div v-if="Object.keys(regsLlamadas).length" class="columna"> 
                <div class="cab">DATOS LLAMADAS</div>
                <v-sheet :elevation="4">
                  <div class="conflex" style="padding:5px">
                    <uiText
                      style="width:24%"
                      v-bind="$input"
                      v-model="regsLlamadas.e"
                      label="Entrantes"
                      readonly>                      
                    </uiText>

                    <uiText
                      style="width:24%;margin-left:5px"
                      v-bind="$input"
                      v-model="regsLlamadas.s"
                      label="Salientes"
                      readonly>                      
                    </uiText>
                 
                    <uiText
                      style="width:24%;margin-left:5px"
                      v-bind="$input"
                      v-model="regsLlamadas.t"
                      label="Totales"
                      readonly>                      
                    </uiText>

                   <uiText
                      style="width:24%;margin-left:5px"
                      v-bind="$input"
                      v-model="regsLlamadas.d"
                      label="Duración"
                      readonly>                      
                    </uiText>
                  </div>
                </v-sheet>
              </div>

              <!-- tab menu -->
              <v-tabs style="margin-top:10px"
                v-model="tab"
                background-color="blue-grey darken-1"
                active-class="blue-grey lighten-3 font-weight-bold"
                :align-with-title="true"
                dark
                show-arrows
                hide-slider
                height="30px">
                
                  <v-tab v-for="seccion in secciones" :key="seccion.titulo">
                        <v-icon class="pr-2">{{ seccion.icono }}</v-icon>
                        <div class="white--text">{{ seccion.titulo }}</div>
                  </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">          
                <v-tab-item
                  v-for="seccion in secciones"
                  :key="seccion.titulo"
                  :transition="false"
                  :reverse-transition="false">                        
                    
                    <div>
                      <div v-if="tab==0">
                        <div style="padding-top:10px">
                          <span style="color:lightred">
                            Debes introducir o copiar (Ctrl + C) desde el programa BLUSTAR-AGENT el código IDEN.LLAMADA que aparece en el cuadro IVR de la pestaña 
                            Sessiones, dentro del cuadro de texto "Identificador de Llamada ENTRANTE" (Ctrl + V). Recuerda: NO debes introducir el número de teléfono...
                          </span>
                        </div>

                        <div class="conflex" style="width:50%;padding-top:10px">
                          <uiText                            
                            v-bind="$input"
                            v-model="ct.ref[2]"
                            :label="ct.ref[1]">                                                  
                          </uiText>                         
                       
                          <v-btn                    
                            style="margin-left:5px"
                            v-bind="$cfg.btra_cfg.standard"                                                     
                            @click="savePhone"
                            :disabled="!ct.ref[2]">
                              <v-icon small>{{ 'mdi-content-save' }}</v-icon>                
                          </v-btn>
                        </div>

                        <!-- Finder de Registros de llamadas -->
                        <div class="cab" style="margin-top:10px;">REGISTROS LLAMADAS</div>
                        <v-sheet :elevation="4">
                          <baseGridSimple
                            :headers="headerSetData('', headersRegs)"
                            :items="itemsRegs.regs"
                            :items-per-page="30"                             
                            dense
                            hide-default-footer
                            @selected="selectedSet($event, 'regs')">                               
                          </baseGridSimple>
                        </v-sheet>                        
                      </div>

                      <div v-else>                       
                        <div style="padding-top:10px">
                          <span style="color:lightred">
                            Realizar Llamada, selecciona Interviniente del Expediente ó introduce número a llamar.
                          </span>
                        </div>

                        <div class="conflex" style="width:50%;padding-top:10px">
                          <uiText                            
                            v-bind="$input"
                            v-model="ct.tlf[2]"
                            :label="ct.tlf[1]">                                                  
                          </uiText>
                        
                          <v-btn                    
                            style="margin-left:5px"
                            v-bind="$cfg.btra_cfg.standard"
                            :disabled="!ct.tlf[2]"                            
                            :href="`AppLlamadas:${recordAux.id}-${ct.tlf[2]}-${usu}-${selected.c}`">                            
                              <v-icon small>{{ 'mdi-phone' }}</v-icon>                
                          </v-btn>
                        </div>
                        
                        <!-- Finder de Teléfonos -->
                        <div class="cab" style="margin-top:10px;">TELÉFONOS EXPEDIENTE</div>
                        <v-sheet :elevation="4">
                          <baseGridSimple                      
                            :headers="headerSetData('', headersTlfs)"
                            :items="itemsTlfs"
                            :items-per-page="30"                             
                            dense
                            hide-default-footer
                            @selected="selectedSet($event, 'tlfs')">    

                            <!-- slot cambio de color para apd/jefe de zona -->
                            <template v-slot:name="{ item }">
                              <div :style="[item.name.substring(0,3).toUpperCase()==='APD' ? {color: 'green',  fontWeight: 'bold'} : {color: 'black'}]">{{item.name}}</div>                             
                            </template>
                          </baseGridSimple>
                        </v-sheet>
                      </div>
                    </div>
                </v-tab-item>
              </v-tabs-items>
          </div>
        </v-sheet>
    </v-dialog>
  </div>
</template>


<script>
  
  import { mixF } from "@/mixins/mixF";
  import baseHeader from "@/base/baseHeader";  
  import baseGridSimple from "@/base/baseGridSimple";  
  
  export default {    
    components: { baseHeader, baseGridSimple },
    mixins: [mixF],
    props: {},

    data() {
      return {
        stIni: {
          api: "expPhoneF",
          titulo:"Registro de llamadas", 
          name: "expPhoneF",
          mView: '',
          pView:[]
        },  

        // array de Teléfonos del expediente
        itemsTlfs:[],

        // array de registros de llamadas
        itemsRegs:{},
       
        // cabeceras GRID
        headersTlfs:{},
        headersRegs:{},

        selected:{},
        tab: null,
        secciones: [
          {
            titulo: "Registro",
            icono: "mdi-phone-incoming"            
          },

          {
            titulo: "Llamar",
            icono: "mdi-card-account-phone-outline"            
          },         
        ],

      };
    },



    methods: {

      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");             
  
        this.headersTlfs = [{
          name:[],
          fields:[                   
            { text: "Persona / Empresa", value: "name", style:'font-weight: bold', width: "75%", slot:true  },            
            { text: "Teléfono", value: "t", width: "25%" }          
          ]},         
        ];

        //
        this.headersRegs = [{
          name:[],
          fields:[                   
            { text: "Call ID", value: "call_id", width: "10%" },            
            { text: "T", value: "tip", width: "5%" },            
            { text: "Operador", value: "usu", width: "10%" },            
            { text: "A/DE", value: "caller_tip", width: "5%" },            
            { text: "Número", value: "caller_num", width: "10%" },   
            { text: "Llamada", value: "caller_fhh", width: "50%", filtro:'fechahr' },                   
            { text: "Tiempo", value: "tie", width: "10%" },            
          ]},         
        ];

      },


      // inicializo APICALL'S
      iniDataMixin() {
        this.apiArgs= {
          sch: null,
          get: ["", 'vozip', { fnt:'get', id:0 }, "expediente" ],
          set: ["", 'vozip', { fnt:'set', id:0, cid:0 }, "expediente" ]
        }
      },


      // obtiene arrays llamadas y nº de teléfono desde API
      async recordsGet() {       
        let param= { apiArg:this.apiArgs.get, args:{ id: this.recordAux.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log("getData Result:", apiResult)

        //
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al obtener los números de teléfonos del Expendiente', 'error');
          return;
        }

        // actualizo arrays de teléfonos
        this.updateArrays(apiResult.r);
        
        // blanqueo teléfono
        this.$set(this.ct.tlf, 2, '');
      },


      // actualiza nº de teléfono a llamar según fila seleccionada
      selectedSet(item, tipo) {
        this.selected= item[0];
        if (tipo== 'tlfs') {
          this.$set(this.ct.tlf, 2, item[0].t);
          return;
        }

        this.$set(this.ct.tlf, 2, item[0].caller_num);
      },      


      // guarda nº de llamada indicado en llamadas entrantes
      async savePhone() {       
        let param= { apiArg:this.apiArgs.set, args:{ id: this.recordAux.id, cid:this.ct.ref[2] }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log("setData Result:", apiResult)

        //
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al grabar el número de llamada', 'error');
          return;
        }

        this.updateArrays(apiResult.r);
        this.$root.$alert.open('Número de llamada guardado correctamente', 'success', 1000);
      },


      updateArrays(data) {
        this.itemsTlfs= data[2];
        this.itemsRegs= { regs:data[0], datos:data[1] };
      },

    },


    computed: {

      sch() {
        return {
          tlf: ['tlf', 'Nº Teléfono', ''],
          ref: ['ref', 'Nº Llamada', '']
        }
      },


      regsLlamadas() {        
        if (!Object.keys(this.itemsRegs).length) return {};
        return this.itemsRegs.datos[0];
      }
      
    },

  };

</script>


