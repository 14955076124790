<!-- Mto Presupuestos Funerarias Sucursales -->

<template>
  <div class="exp-pres-md" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      persistent>

      <template v-slot:controles="{}">

        <div>
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.mto"
              :titulo="$store.state[storeName].titulo"
              @onEvent="eventHeader">
            </baseHeader>
          </div>

          <v-sheet :elevation="4">
            <div class="contenedor">

              <!-- botoneras -->
              <div class="conflex" style="width:1100px;margin-bottom:5px">

                <!-- btra Mto -->
                <baseBtraMto class="conflex" style="width:230px;"
                  :perm="perm"
                  :modulo="btMtoCfg"
                  :estado="estado"
                  @onEvent="execAccion">
                </baseBtraMto>

                <!-- extra -->
                <div class="conflex" style="width: 380px; margin-left: 50px">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>
                </div>

                <!-- plantillas -->
                <div style="width:240px">
                  <v-select
                    style="white-space:nowrap;overflow: hidden;text-overflow: ellipsis;"
                    dense
                    @change="changePlantilla()"
                    v-model="plantillaValue"
                    v-bind="$select"
                    label="Plantillas"
                    :items="itemsPlantilla"
                    :disabled="plantillaDisabled"
                    item-value="id"
                    item-text="name">
                  </v-select>
                </div>

                <!-- fin botonera comun -->
              </div>


              <!-- fila 1 -->
              <div class="conflex" style="width: 1180px">

                <!-- fila 1 columna 1 -->
                <div class="columna" style="flex: 0 1 750px">
                  <div class="conflex" style="justify-content: space-between">
                    <div class="cab">DATOS PRESUPUESTO</div>
                    <div style="color: steelblue; padding-right: 20px">
                      <button @dblclick="botonEditar"
                        v-if="(record.fra_conta_fh>'2021' || record.fra_conta_fh=='') && record.idRR==0 && Number(ndep)>=6 && app==1"
                        style="text-align:right;color:maroon">
                          <i> {{ ID }} - {{ record.fra_conta_asi }}</i>
                      </button>

                      <span v-else>{{ ID }} </span>
                    </div>
                  </div>

                  <v-sheet v-bind="$cfg.styles.marco" style="width: 750px">
                    <div class="conflex">
                      <uiText
                        v-bind="$input"
                        style="width:15%;font-weight:bold;color:steelblue;"
                        v-model="expediente.control"
                        label="EXPEDIENTE"
                        disabled>
                      </uiText>
                      <uiText
                        v-bind="$input"
                        style="width:65%;margin-left:5px;color:steelblue;"
                        v-model="expediente.fallename"
                        label="Fallecido"
                        disabled>
                      </uiText>
                      <uiText
                        v-bind="$input"
                        style="width:19%;color:steelblue;"
                        v-model="expediente.callref1"
                        label="Referencia"
                        disabled>
                      </uiText>

                    </div>
                    <div class="conflex">
                      <uiMultiple
                        style="font-weight:bold;flex: 1 1"
                        :ctrl="ct.cta_id"
                        :ct="ct"
                        :disabled="noEdit"
                        @change="changeCta">
                      </uiMultiple>

                      <div v-if="ct.pdi[2]=='1'" style="font-weight:bold;font-size:1.5rem;color:red" > PDI </div>
                    </div>
                    <!-- fin linea -->

                    <uiText
                      v-bind="$input"
                      v-model="ct.descrip[2]"
                      :label="ct.descrip[1]"
                      :disabled="noEdit">
                    </uiText>

                    <div class="conflex" style="flex: 1 1 750px">
                      <v-select
                        style="text-align: center; flex: 0 1 100px"
                        v-model="ct.rt[2]"
                        v-bind="$select"
                        label="Tipo I."
                        :items="$store.state.datos_iniciales.rTribut"
                        :disabled="noEdit || !noEdit1"
                        item-value="id"
                        item-text="name"
                        @change="changeRt">
                      </v-select>

                      <v-select
                        style="flex: 0 1 100px"
                        v-model="ct.iva_general[2]"
                        v-bind="$select"
                        label="% Gral"
                        :items="getIvas"
                        :disabled="noEdit"
                        item-value="value"
                        @change="changeIvaGeneral">
                      </v-select>

                      <presSumas style="flex:1 1 60%" :ct="ct"></presSumas>
                    </div>

                    <v-textarea
                      v-bind="$textarea"
                      v-model="ct.obs_fra[2]"
                      :label="ct.obs_fra[1]"
                      no-resize
                      rows="1"
                      :disabled="noEdit">
                    </v-textarea>
                  </v-sheet>
                </div>


                <!-- fila 1 columna 2 -->
                <div class="columna" style="width: 280px">
                  <div class="conflex" style="align-items: baseline;">
                      <div class="cab" style="width:80px;margin-right:4px;">
                        <button @dblclick="cambioTipo"
                          v-if="(record.fra_conta_asi==0 || (record.idRR==0 && record.fra_fh>'2021'))
                          && record.tipo_ope=='C'
                          && app==1
                          && ndep>=6 && !noEdit"
                          style="text-align:right;color:yellow">
                            <i> * TIPO *</i>
                        </button>

                        <span v-else>TIPO</span>
                      </div>
                      <div :style="tipo_ope.style + ';padding:0'">{{ tipo_ope.text }}</div>
                  </div>

                  <v-sheet v-bind="$cfg.styles.marco">
                    <!-- <div :style="tipo_ope.style">{{tipo_ope.text}} </div> -->

                    <!--
                    <v-radio-group
                      v-model="ct.tipo_ope[2]"
                      row
                      dense
                      :ripple="false"
                    >
                      <v-radio label="COMPRA" value="C" color="green"></v-radio>
                      <v-radio label="VENTA" value="V"></v-radio>
                    </v-radio-group> -->

                    <uiText
                      v-bind="$input"
                      styleLabel="width:85px"
                      v-model="ct.fra_doc[2]"
                      label="N.FRA "
                      labelSide
                      :disabled="(noEdit==true || rol!='gestion' || ct.tipo_ope[2]!='C')">
                    </uiText>

                    <uiDate
                      styleLabel="width:85px"
                      v-model="ct.fra_fh[2]"
                      label="FECHA"
                      labelSide
                      :disabled="(noEdit || rol!='gestion' || ct.tipo_ope[2]!='C')">
                    </uiDate>

                    <div class="conflex" style="justify-content: space-between">
                      <!-- <v-checkbox
                        style="margin-left: 0px"
                        v-model="ct.tipo_carabon[2]"
                        label="Cargo/Abono"
                        false-value="0"
                        true-value="1"
                      >
                      </v-checkbox> -->

                      <select class="select"
                        :disabled="(noEdit || rol!='gestion')"
                        v-model="ct.rel_tp[2]"
                        @change="changeTipoCargoAbono">
                          <option
                            v-for="item in itemsTipoCargo"
                            :key="item.value"
                            :value="item.value">
                              {{item.text}}
                          </option>
                      </select>
                    </div>

                    <!-- <div v-if="ct.rel_tp[2]>0"> -->
                    <div v-if="ct.rel_tp[2]>=0">
                      <uiText
                        v-bind="$input"
                        style="margin-top:10px"
                        styleLabel="width:85px"
                        v-model="ct.relacion[2]"
                        label="Rectificada"
                        labelSide
                        :disabled="(noEdit || rol!='gestion')">
                      </uiText>
                    </div>

                    <!-- <vlinput
                      v-model="ct.exp_id[2]"
                      stilol="flex:0 60px"
                      type="date"
                      label="FECHA"

                      :disabled="noEdit"
                    >
                    </vlinput> -->

                  </v-sheet >


                  <v-sheet v-bind="$cfg.styles.marco" v-if="app==1">
                    <div>
                      <uiDate
                        styleLabel="width:100px"
                        v-show="ct.tipo_ope[2]=='V'"
                        v-model="record.fra_emision"
                        type="datetime-local"
                        label="Emitida"
                        labelSide
                        readonly>
                      </uiDate>

                      <uiDate
                        styleLabel="width:100px"
                        v-model="record.fra_conta_fh"
                        type="datetime-local"
                        label="Contabilizada"
                        labelSide
                        readonly>
                      </uiDate>

                      <uiText
                        v-bind="$input"
                        styleLabel="width:100px"
                        v-model="record.fra_conta_asi"
                        label="Asiento"
                        labelSide
                        readonly>
                      </uiText>
                    </div>
                  </v-sheet>
                  <!-- fin columna 2 -->
                </div>


                <!-- fila 1 columna 3 -->
                <div class="columna">
                  <div  class="conflex">
                    <v-icon large :color="viewBloqueada[Number(ct.bloqueada[2])||0].color ">>
                      {{ viewBloqueada[Number(ct.bloqueada[2])||0].icon }}
                    </v-icon>

                    <v-icon large :color="viewAceptada[Number(ct.aceptada[2])||0].color">
                      {{ viewAceptada[Number(ct.aceptada[2])||0].icon }}
                    </v-icon>
                  </div>

                  <div class="mt-auto" v-if="noEdit && record.idRR>0 && app==1 && Number(ndep)>=6">
                    <!-- Cambios Contabilidad Factura  -->
                    <v-btn depressed fab x-small color="indigo lighten-2" @click="restauraFactura()">
                      <div style="color: white;font-weight: bold;font-size: 1rem">#</div>
                      <sub style="color: silver;font-size: 1rem;">M</sub>
                    </v-btn>
                    <!-- ReEditar Suplido-->
                    <v-btn x-small @dblclick="reeditarSuplido" class="ml-2"
                      style="text-style: italic; text-align:right;color:rgb(43, 25, 25)"
                      v-show="record.tipo_ope=='C' && record.suplido=='1'">
                      <small>EDIT.SUP</small>
                    </v-btn>

                  </div>

                  <!-- fin fila 1 columna 3 -->
                </div>
                <!-- fin fila 1 -->
              </div>


              <!-- fila 2 -->
              <div class="fila2 conflex">
                <!-- fila2 columna 1-->
                <div class="columna" style="padding-right: 8px">

                  <!-- pagos directos asociados -->

                  <div v-if="presImportDirectos.length">
                    <div class="cab" style="width:750px">PRESUPUESTOS RELACIONADOS CON EL PRESUPUESTO IMPORTADO</div>
                      <v-sheet :elevation="4">

                        <div v-for="(directo, index) in presImportDirectos" :key="index">
                          <div class="conflex" style="margin-left:55px">
                            <div style="width:400px"> {{ directo.name_prod_id + "   " + directo.namea }}</div>
                            <div style="width:100px"> {{ directo.imp }}€</div>
                            <div style="width:100px"> {{ directo.sp?'SUPLIDO':'' }}</div>
                          </div>
                        </div>

                        <v-btn
                          depressed
                          x-small
                          color="blue-grey lighten-4"
                          style="margin: 10px 0 10px 55px"
                          @click="generarPresupuestos()">
                            Generar presupuestos relacionados
                        </v-btn>

                      </v-sheet>
                    </div>


                    <!-- GRID -->
                    <div class="cab" style="width:750px">DETALLE</div>
                    <v-sheet :elevation="4">
                      <baseGridMD
                        style="width: 750px;padding-top:10px"
                        :store="storeName"
                        :headers="headerGrid"
                        :items-per-page="30"
                        :expansible='storeName'
                        ordenar
                        dense
                        @onEvent="execAccionMX">

                          <!-- Slot botones Top -->
                          <template v-slot:gridTop="{}" v-if="!noEdit">
                            <baseBtraMto class="conflex"
                              :perm="perm"
                              :modulo="$cfe.btra.GRID"
                              @onEvent="execAccionMX">
                            </baseBtraMto>
                          </template>

                          <!-- Slot panel expansible -->
                          <template v-slot:expansibleMX="{}">
                              <div class="columna">
                                <div style="display:flex;align-items:center">

                                  <expPresMX
                                    sync
                                    syncUpdate
                                    :syncAccion="syncAccionSend"
                                    :storeRaiz="storeName"
                                    :masterStore="storeName"
                                    :selectProduct="selectProduct"
                                    :rt="ct.rt[2]"
                                    :iva_general="ct.iva_general[2]"
                                    :ope="ct.tipo_ope[2]"
                                    @updatedMX="updatedMX">
                                  </expPresMX>

                                </div>
                              </div>
                          </template>

                          <!-- Slot columna Header 'sp' -->
                          <template v-slot:sp="{item}">
                            <div style="color:red">
                              {{item.sp=='1'?'PE':''}}
                            </div>
                          </template>

                          <!-- Slot columna Header 'sup' -->
                          <template v-slot:sup="{item}">
                            <div style="color:green">
                              {{item.sup=='S'?'S':''}}
                            </div>
                          </template>

                          <!-- Slot columna Header 'name_prod_id' -->
                          <template v-slot:name_prod_id="{ item}">
                            {{ item.name_prod_id }}
                            <div
                            v-if="item.namea >''" class="light-blue--text text--darken-3">
                              {{ item.namea }}
                            </div>
                          </template>

                      </baseGridMD>
                    </v-sheet>

                    <!-- fin fila2 columna 1 -->
                  </div>


                <!-- fila2 columna 2 -->
                <div v-if="noEdit1" class="columna" style="width: 420px">

                  <div v-if="ct.tipo_ope[2]!='C'">
                    <!-- otros conceptos -->
                    <div class="cab" style="width:125px">
                      OTROS CONCEPTOS
                    </div>

                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <v-checkbox
                          style="width:20%;margin-right:0px"
                          v-model="apdComputed"
                          :disabled="noEdit">
                        </v-checkbox>

                        <uiText
                          v-bind="$input"
                          style="width:80%;margin-left:0px"
                          styleLabel="width:50px"
                          v-model="ct.tmt3[2]"
                          label="APD"
                          format="money"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <div class="conflex">
                        <uiText
                          v-bind="$input"
                          style="width:50%;"
                          v-model="ct.apd1[2]"
                          label="APD 1"
                          @blur="calculoAPDManual()"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          v-bind="$input"
                          style="width:50%;"
                          v-model="ct.apd2[2]"
                          label="APD 2"
                          @blur="calculoAPDManual()"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <v-divider style="margin:4px 0"></v-divider>

                      <!-- tramitacion -->
                      <div class="conflex">
                        <uiText
                          v-bind="$input"
                          style="width:33%;"
                          v-model="ct.tmt1[2]"
                          label="TRAMITACIÓN"
                          @blur="calculo"
                          format="money"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          v-bind="$input"
                          style="width:17%;"
                          v-model="ct.tmtpor1[2]"
                          label="%"
                          simbolo="%"
                          format="decimal"
                          :ndecimals=2
                          @blur="calculo()"
                          :disabled="noEdit">
                        </uiText>

                        <!-- simbolo="%" -->
                        <v-select
                          style="width:50%"
                          v-model="ct.tmttipo1[2]"
                          v-bind="$select"
                          v-show="!noEdit"
                          :label="ct.tmttipo1[1]"
                          :items="itemsTramitacion"
                          :disabled="noEdit"
                          item-value="id"
                          item-text="name"
                          @change="changeTramitacion">
                        </v-select>
                      </div>

                      <!-- otros paises -->
                      <div class="conflex">
                        <uiText
                          v-bind="$input"
                          style="width:50%;"
                          v-model="ct.tmt2[2]"
                          label="OTROS PAISES"
                          @blur="calculo()"
                          format="money"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          v-bind="$input"
                          style="width:50%;"
                          v-model="ct.tmtpor2[2]"
                          label="%"
                          simbolo="%"
                          format="decimal"
                          :ndecimals=2
                          @blur="calculo()"
                          :disabled="noEdit">
                        </uiText>
                      </div>

                      <!-- varios -->
                      <div class="conflex">
                        <uiText
                          v-bind="$input"
                          style="width:50%;"
                          v-model="ct.tmt4[2]"
                          label="VARIOS"
                          @blur="calculo()"
                          format="money"
                          :disabled="noEdit">
                        </uiText>

                        <uiText
                          v-bind="$input"
                          style="width:50%;"
                          v-model="ct.tmtpor4[2]"
                          label="%"
                          simbolo="%"
                          format="decimal"
                          :ndecimals=2
                          @blur="calculo()"
                          :disabled="noEdit">
                        </uiText>
                      </div>


                      <uiText
                        v-bind="$input"
                        styleLabel="text-align:center;width:50%"
                        v-model="ct.tmt[2]"
                        label="TRAMITACION"
                        labelSide
                        format="money"
                        readonly>
                      </uiText>

                      <uiText
                        v-bind="$input"
                        styleLabel="text-align:center;width:50%"
                        v-model="ct.impstmt[2]"
                        label="IMP S/TRAM"
                        labelSide
                        format="money"
                        readonly>
                      </uiText>
                    </v-sheet>
                  </div>

                  <!-- fin if compras/ventas -->

                  <!-- <div v-if="noEdit1" class="columna" style="width:430px;padding-left:8px"> -->

                  <presIva
                    :ct="ct">
                  </presIva>

                  <!-- <presClaves :Aimp="clavesAc" :T="clavesTotal" :todo="false"></presClaves> -->
                  <!-- <pres_iva_desglose
                    :pdat="ct"
                    :is_edit="!noEdit">
                  </pres_iva_desglose> -->

                  <div class="conflex" v-if="ct.tipo_ope[2]=='C'">
                    <uiText
                      v-bind="$input"
                      style="width:50%;"
                      v-model="ct.irpfpor[2]"
                      label="% IRPF"
                      simbolo="%"
                      format="decimal"
                      :ndecimals=2
                      @blur="calculo()"
                      :disabled="noEdit">
                    </uiText>

                    <uiText
                      v-bind="$input"
                      style="width:50%;"
                      v-model="ct.irpf[2]"
                      label="I.R.P.F."
                      format="money"
                      :disabled="noEdit">
                    </uiText>


                  </div>

                  <presClaves :Aimp="clavesAc" :T="clavesTotal"></presClaves>
                </div>

                <div v-else>
                  <productosNV :edicion="!noEdit1" @eventSelectProduct="selectProduct=$event" />
                </div>



              </div>
            </div>

            <!-- fin bloque -->
          </v-sheet>


          <!-- ventana modal COMPONENTES DINÁMICOS (Documentos, Importación, ...) -->
          <!-- Propiedades:
            masterRecordField: field master asociado. exp_id (expDocsF), cia_id (presImportF)
            recordAux: record de expedientes
            filtroImportServicio: filtro del finder de importación (cta_id, ...)
          -->
          <component
            :is="componenteDinamico"
            :storeRaiz="storeName"
            :masterStore="storeName"
            :masterRecordField="fieldAux"
            :recordAux="expediente"
            :filtroImportServicio="filtroImportServicio"
            :readOnly="readOnly"
            :viewMtoOnly="viewMtoOnly"
            :tipoView="tipoView"
            @onEvent="impPrestosExtraer">
          </component>

        </div>
      </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixMD } from "@/mixins/mixMD.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";
  import presIva from "@/components/presIva";
  import presClaves from "@/components/presClaves";
  import productosNV from "@/components/productosNV";
  import expPresMX from "@/components/expPresMX";
  import presImportF from "@/components/presImportF";
  import expDocsF from "@/components/expDocsF.vue";
  import presSumas from "@/components/presSumas";
  import suplidosF from "@/components/suplidosF.vue";
  import { presCalculo } from "@/mixins/presCalculo.js";
  import { calculo_claves } from "@/mixins/calculo_claves.js";
  import { mixinPresPack } from "@/mixins/mixinPresPack.js";
  import { mixOperacion } from "@/mixins/mixOperacion.js";
  import { lineasOp } from "@/mixins/lineasOp.js";



  /* const pres_claves = () => plugs.groute("pres_claves.vue", "comp");
  const pres_sumas = () => plugs.groute("pres_sumas.vue", "comp");
  const pres_iva_desglose = () => plugs.groute("pres_iva_desglose.vue", "comp");
  const compMultiple = () => plugs.groute("compMultiple.vue", "comp");
  const funes_pres_import_F = () => plugs.groute("funes_pres_import_F.vue", "comp");
  const exp_Docs = () => plugs.groute("exp_Docs.vue", "comp");
  const info_cta_view = () => plugs.groute("info_cta_view.vue", "comp"); */
  // const exp_Comunicados = () => plugs.groute("exp_Comunicados.vue", "comp");

  // const pres_total_cia = () => plugs.groute("pres_total_cia.vue", "comp");
  // const pres_total_compra = () => plugs.groute("pres_total_compra.vue", "comp");

  export default {
    mixins: [mixMD, calculo_claves, mixinPresPack, mixOperacion, presCalculo],
    components: {
      baseHeader,
      baseGridMD,
      baseBtraMto,
      baseBtraExtra,
      dualTemplate,
      presIva,
      presClaves,
      productosNV,
      expPresMX,
      presImportF,
      expDocsF,
      presSumas,
      suplidosF,
      /* ctrlF, */

      /* exp_pres_detalle,
      pres_claves,
      pres_sumas,
      funes_pres_import_F,
      exp_Docs,
      info_cta_view */
      // exp_Comunicados
      // pres_total_compra,
    },

    props: {
      operacion:{ type:Object, default:()=> {}},
    },


    data() {
      return {
        stIni: {
          api: "expPresMD",
          name: "expPresMD",
          titulo: "·Presupuestos/Facturas",
          recordAccess: "local",
          relation: "exp_id",
          mView:'expPresMD',
          pView:[]
        },


        // nombre del campo relacionado con el maestro
        // se utiliza en los componentes dinámicos
        fieldAux: "",

        // filtro del finder de importación (exp_id, ...)
        filtroImportServicio:{},


        presImportID: 0,        // guardo id de presuspuesto importado
        presImportDirectos:[],  // guardo lineas de p.externos del pres importado
        selectProduct:null,
        //

        // tipo de vista para los componentes dinámicos
        tipoView:'',

        //producto: null, // pendiente: no creo que se use
        fila_copiada: null,
        pservicios: null,
        lineas_actualizadas: false,

        // array de tarifas de tramitación
        // * cambios en 2024, mapfre,gomera a 2,7
        itemsTramitacion:[
          //{ id: '0', name:"Seleccione Tramitación", por:4.7, min:0, max:0 },
          { id: '0', name:"Seleccione Tramitación", por:2.7, min:0, max:0, fn: this.getMapfrePor },
          //{ id: '1', name:"MAPFRE", por:4.7, min:0, max:0, fn: this.getMapfrePor },
          { id: '1', name:"MAPFRE", por:2.7, min:0, max:0, fn: this.getMapfrePor },
          { id: '2', name:"MAPFRE ASISTENCIA", por:4.7, min:80.77, max:650 },
          //{ id: '3', name:"KUTXABANK", por:5, min:0, max:0 },
          //{ id: '4', name:"ENCARGOS", por:4.7, min:0, max:0 },
          { id: '4', name:"ENCARGOS", por:2.7, min:0, max:0, fn: this.getMapfrePor },
          { id: '5', name:"RECOMENDADO", por:5, min:80.77, max:0 },
          { id: '6', name:"DERIVADOS SI24 MAPFRE", por:10, min:80.77, max:0 },
          { id: '9', name:"VARIOS", por:0, min:0, max:0 }
        ],

        // array de tipo de presupuesto
        itemsTipoOpe:[
          { tipo_ope:"", text:"ERROR TIPO PRESUPUESTO", style:"font-weight:bold;color:steelblue"},
          { tipo_ope:"C", text:"COMPRA",  style:"font-weight:bold;font-size:1rem;color:orange"},
          { tipo_ope:"V", text:"VENTA",   style:"font-weight:bold;font-size:1rem;color:green;"},
          { tipo_ope:"SUP", text:"SUPLIDO",   style:"font-weight:bold;font-size:1rem;color:blue;"},
          { tipo_ope:"W", text:"VENTA TRAMITACIÓN",   style:"font-weight:bold;font-size:.9;color:rgb(0, 255, 0);"}
        ],

        // array de cargo/abono
        itemsTipoCargo: [
          { value: 0, text: "Seleccione Cargo/Abono"},
          { value: 1, text: "PARCIAL" },
          { value: 2, text: "ANULA" },
          { value: 3, text: "TOTAL" }
        ],

        // Productos con IVA 10%
        iva10:[],

        // código de Canarias en atlas
        locCanarias:'06206',

        itemsCias: [
          { id: "83", name: "MAPFRE" },
          { id: "50165", name: "KUTXABANK" },
        ],

        // itemsPrestacion: [],
        // noduplicar_tipos:[10, 30, 18, 38, 36, 16, 17, 37],
        // municipio_required: [10, 11, 30, 31, 18, 38, 36, 16, 17, 37],
        // apd_tipos: [10, 11, 30, 31, 18, 38],
        apd_cias: [50165, 50508],
        itemsPlantilla:[
          { id:'4', name:'Servicio Incineración' },
          { id:'3', name:'Servicio Inhumación' },
          { id:'5', name:'Servicio Traslado' },
          { id:'6', name:'Servicio Recepción / Terminación' }
        ],
        plantillaValue:'0',
        plantillaDisabled:true,
        viewAceptada:[
          {icon:"mdi-check-bold", color:"grey"},
          {icon:"mdi-check-bold", color:"green"},
          {icon:"mdi-close",     color:"red"}
        ],

        viewBloqueada:[
          {icon:"mdi-lock-open", color:"green"},
          {icon:"mdi-lock",      color:"red"}
        ]
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // defino botones extra de la cabecera
        this.btExtCfg= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
        this.btExtCfg.btnsAccion= this.btExtCfg.btnsAccion.concat([
          { accion: "acepto", icono: "mdi-check-bold", show: 1 },
          { accion: "bloqueo", icono: "mdi-lock", show: 1 },
          { accion: "pasoVenta", texto:"V", view:false, disabled:true, styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1, title:'Generar Nueva VENTA' },
          { accion: "pasoFactura", texto:"F", view:false, disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1, title:'Asignar Numeración FACTURA VENTA' },
          { accion: "pasoTramitacion", texto:"TR", view:false,disabled:true,styT:'color:green;font-weight:bold;font-size:1rem;',  show: 1, title:'Generar Nueva TRAMITACIÓN' },
          { accion: "impPrestos", texto: "IMP", show: 1, title:'Importar Datos desde Presupuestos' },
          { accion: 'documentos', btn: "docs" },
          { accion: "pdf", btn: "pdf", show: 1 },
          { accion: "copy", texto: "copy", show: 1 },
          { accion: "pega", texto: "pega", show: 1 },
          { accion: 'suplidos', texto: "SUP", title:'Suplidos' },
        ]);

        // defino botones lineas detalle
        this.btRowCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));

        // cabecera Finder
        this.headers = [{
          name:[],
          fields:[
            { text: "CONCEPTO", value: "name_prod_id", width: "50%", slot:true },
            { text: "SUP", value: "sup", style: "color:green", slot:true },
            { text: "UD", value: "und",align:"center", style: "text-align:right" },
            { text: "IVA", value: "iva", style: "text-align:right" },
            { text: "PRECIO", value: "pvp", style: "text-align:right" },
            { text: "IMP", value: "imp", style: "font-weight:bold;text-align:right;color:#01579B" }
          ],
        }];

        this.headerGrid= this.headerSet();


        // array de productos con iva al 10%
        this.iva10= this.$store.state.datos_iniciales.iva10;
      },


      // defino todas las API calls
      iniDataApi() {
        this.apiArgs= {
          sch: ["mto", 'getSchema', {}, this.stIni.api ],
          get: ["", "get", { tipo: "fras", id: 0 }, "presupuesto"],
          set: ["", "set", { tipo: "fras", id: 0, cab: {}, lin: [] }, "presupuesto"],
          eliminar: ["", "eliminar", { tipo: "fras", id: 0 }, "presupuesto"],
          plantilla: ["", "plantilla", { rt:'', id:0 }, "presupuesto"],
          pdf: ['', 'factura', { id:0, opc:1 }, "expediente"],
          importar: ["", "importar", { id:0, fnt:'', exp_id:0 }, "presupuesto"],
          apdCalculo: ["", "mysqlProc", {}, "aplica"],
          fusion: ["", "fusionLineas", { id: 0, gen:0, tipo:'fras' }, "presupuesto"],
          estado: ["", "estado", { tipo:'fras', id:0 }, "presupuesto"],
          pasoFactura: ["", "facturaNumero", { id:0 }, "facturas"],
          restauraFactura: ["", "fraRegistroAnulacion", { fra:0 }, "sap"]
        }
      },


      // configurado estado botonera, tanto extra como de mto
      async botonerasSet() {

        this.btnSet(this.btMtoCfg, 1, {view:true, disabled:true});
        this.botonPega();

        //boton pdf solo si no esta en edicion
        this.btnSet(this.btExtCfg, "pdf",{ view: true, disabled: !this.noEdit });

        // // this.$store.state.G.APLICA.VERSION (1:gestion, 2:apd, 3:consulta)
        // // this.$store.state.G.G.USUARIO (usu)
        // //                  .ROL ("gestion","apds","jz","consultas")
        // //                  .ZONA (0)
        let COMPRA=this.ct.tipo_ope[2]=="C";
        let VENTA=this.ct.tipo_ope[2]=="V";
        //let TRAMITACION=this.ct.tipo_ope[2]=="W";

        let ACEPTADA=this.ct.aceptada[2]=='1';
        let FACTURADA=this.ct.fra_doc[2]>'';
        //let PAGADA=this.ct.fra_pago[2]!='0';

        let CONTABILIZADA=this.record.fra_conta_asi>'0'  || (VENTA && FACTURADA);

        let GESTION=this.app==1;
        let APDS=this.app==2;

        //------------------------------------------------
        let CIERRE=(APDS && this.expediente.chkconf24==1) || this.expediente.chkconfadmon==1;
        if (CIERRE) {
          this.botonesCierre();
          return;
        }

        // APD con presupuesto bloqueado habilita boton bloqueo
        if (APDS && this.ct.bloqueada[2]==1) {
          this.botonesCierre();
          this.btnSet(this.btExtCfg, "bloqueo",{ view:true, disabled:false });
          return;
        }

        //-----------------------------------------------
        // habilitar edición y eliminar
        this.btnSet(this.btMtoCfg, 2, {view:true, disabled: CONTABILIZADA && this.ndep!=9 });
        //this.btnSet(this.btMtoCfg, 3, {view:true, disabled: CONTABILIZADA || this.ndep<2 });
        this.btnSet(this.btMtoCfg, 3, {view:true, disabled: CONTABILIZADA });

        //aceptar y bloqueo
        this.btnSet(this.btExtCfg, "acepto",{ view:true, disabled: CONTABILIZADA || !this.noEdit });
        this.btnSet(this.btExtCfg, "bloqueo",{ view:true, disabled: CONTABILIZADA || !this.noEdit });

        //asignar numero de factura para venta/tramitacion
        this.btnSet(this.btExtCfg, "pasoFactura",{ view: GESTION && !COMPRA,
            disabled: !this.noEdit || CONTABILIZADA || !ACEPTADA || FACTURADA || this.ndep<4 });
        //generar nuevas factura de venta y/o tramitacion desde compra
        this.btnSet(this.btExtCfg, "pasoVenta", { view: GESTION, disabled: !this.noEdit});
        this.btnSet(this.btExtCfg, "pasoTramitacion", { view: GESTION && COMPRA,  disabled: !this.noEdit});

        this.plantillaDisabled= this.noEdit;
        this.btnSet(this.btExtCfg, "impPrestos",{ view: COMPRA, disabled: this.noEdit });
        this.btnSet(this.btExtCfg, "documentos",{ view: true });
        this.btnSet(this.btExtCfg, "suplidos",  { view: GESTION && COMPRA,
          disabled:Number(this.record.sup)==0 || Number(this.record.suplido)==1 });

        //plantillas e importar
        if (!this.noEdit && this.ct.tipo_cta[2]==1) {
            this.btnSet(this.btExtCfg, "impPrestos", {view:COMPRA, disabled:false})
            this.plantillaDisabled=false;
          }

      },

      //
      botonesCierre() {
        let GESTION= this.app==1;

        this.btnSet(this.btMtoCfg, 1, { view:true, disabled:true });
        this.btnSet(this.btMtoCfg, 2, { view:true, disabled:(this.ndep!=9) });
        this.btnSet(this.btMtoCfg, 3, { view:true, disabled:true });

        this.btnSet(this.btExtCfg, "pasoFactura", { view:false, disabled:true });
        this.btnSet(this.btExtCfg, "pasoVenta", { view:false, disabled:true });
        this.btnSet(this.btExtCfg, "pasoTramitacion", { view:false, disabled:true });
        this.btnSet(this.btExtCfg, "acepto", { view:false, disabled:true });

        this.btnSet(this.btExtCfg, "bloqueo", { view:false, disabled:true });
        this.btnSet(this.btExtCfg, "impPrestos", { view:false, disabled:true });
        this.plantillaDisabled=true;

        this.btnSet(this.btExtCfg, "suplidos",  { view: GESTION && this.record.tipo_ope=="C", disabled:true });

        //compra,empresa mixta (5949), usuarios administracion y abierto admon ---> dejar boton tramitacion
        if (this.record.tipo_ope=="C" &&
            this.record.tipo_cta=='1' &&
            this.record.cta_id=='5949' &&
            this.ndep>=5 &&
            this.app==1 &&
            this.expediente.chkconfadmon==0) {

            this.btnSet(this.btExtCfg, "pasoTramitacion", {view:true, disabled:false});
        }

        // añadido para control del fbi, borrar y aceptar facturas
        // +C,+S aceptadas sin contabilizar y sin pagar
        if (this.record.tipo_ope=="C" &&
            this.record.aceptada=='1' &&
            this.record.fra_conta_asi==0 &&
            this.app==1 &&
            Number(this.record.fra_pago)== 0) {
              this.btnSet(this.btMtoCfg, 3,{view:true, disabled:false});
              if (this.record.imp!=0) {
                this.btnSet(this.btExtCfg, "acepto", {view:true, disabled:false});
              }
        }
      },

      // edicion manual (doble click)
      botonEditar() {
        this.btnSet(this.btMtoCfg, 2,{ view:true, disabled:false });
      },


      //
      botonPega() {
        this.btnSet(this.btExtCfg, 'pega', {view:true, disabled:!this.$store.state.datos_iniciales.recordsCopy.length || this.noEdit || !this.noEdit1});
      },


      // gancho al final de la acción 'ver'
      // inicializa variables
      verFin() {
        this.iniVars();
        this.crudFin();
      },


      // gancho final para todas las operaciones de CRUD
      // calcula capitales y configura botonera
      crudFin() {
        this.calculo_capitales();
        this.botonerasSet();
      },


      // TEMPORAL.
      guardarBefore() {
        // si venta suplido -> pdi a 0 (ejemplo: fusion solo factura suplido)
        if (this.ct.tipo_ope[2]=='V') {
          this.$set(this.ct.suplido, 2, '0');
          this.$set(this.ct.pdi, 2, '0');
        }

        // si +suplido pdi obligatorio
        if (this.ct.suplido[2]=='1') this.$set(this.ct.pdi, 2, '1');


        // comprueba si el ID del expediente, en un editar, es el mismo que el que hay en el
        // campo exp_id del presupuesto
        if (this.estado=='nuevo') return true;
        return this.validateExpID();
      },


      // gancho a ejecutar antes de mostrar la pregunta '¿Desea eliminar el registro?'
      // elimino registro cabecera y todas sus lineas
      eliminarBefore() {
        console.log('eliminarBefore: ', this.record, this.ct);

        //if ((this.record.fra_conta_asi>0) && (this.ct.aceptada[2]==1 || this.ct.bloqueada[2]==1 || this.ct.fra_doc[2]>'') {
        if (this.record.fra_conta_asi>0 || this.ct.aceptada[2]==1 || this.ct.bloqueada[2]==1 || this.ct.fra_doc[2]>'') {

            this.$root.$alert.open("DENEGADO ELIMINAR (Presupuesto ACEPTADO, BLOQUEADO O FACTURADO", "error");
            return false;
        }

        if (this.record.fra_pago!=0) {
          this.$root.$alert.open("DENEGADO ELIMINAR ( Presupuesto/Factura YA PAGADA )", "error");
          return false;
        }

        return true;
      },


      //
      async execOperacion(evt, directOperacion= null) {
        console.log('execOperacion== ', evt, this.operacion, directOperacion);

        // si recibo una operacion directa, usamos esa
        // si no, usamos la operación recibida como propiedad
        let operacion= directOperacion? directOperacion : this.operacion;


        // entramos siempre como un nuevo registro
        await this.nuevo();

        // inicializa variables
        this.iniVars();

        // si es una venta marcamos la fra como aceptada
        // marcamos la factura como NO bloqueada
        this.$set(this.ct.aceptada,  2, operacion.op=='V'? 1 : 0);
        this.$set(this.ct.bloqueada, 2, 0);



        // IVA GENERAL
        this.iniIvaOperacion(operacion);

        // datos operacion
        // pendiente de que los valores sean correctos (suplido)
        this.$set(this.ct.tipo_ope, 2, operacion.op);
        this.$set(this.ct.suplido, 2,  operacion.suplido);
        this.$set(this.ct.tipo_cta, 2, operacion.cuentaTipo);
        this.$set(this.ct.cta_id, 2, operacion.cuentaId);

        // si es una venta obtengo el importe de los APDS
        if (operacion.op== 'V') this.apdSet();

        // asigno valor al uiMultiple
        if (operacion.cuentaId && operacion.cuentaTipo) {
            let tmpRecord= { tipo_cta:operacion.cuentaTipo, cta_id:operacion.cuentaId };
            let x= await this[this.ct.cta_id[4].comp.type]().set({ ctrl:this.ct.cta_id, record:tmpRecord });

            // Pendiente comprobar pdi
            if (x!== undefined) {
              if (x.pdi!==undefined) this.$set(this.ct.pdi, 2, x.pdi);
            }
        }


        // COMPRA a VENTA (transformar lineas)
        // fusionVenta
        let lineas= operacion.fusionRecords;
        if (operacion.accion== 'fusionV') {

          // obtengo iva general
          //let ivaGeneral= this.$store.state.datos_iniciales.rTribut[this.ct.rt[2]].tipos[0].value;

          // transformo lineas a venta
          let rTributName= this.$store.state.datos_iniciales.rTribut[this.ct.rt[2]].name;
          let rTributPorcentaje= this.$store.state.datos_iniciales.rTribut[this.ct.rt[2]].tipos[0].value;
          lineas= lineasOp.pasoVentaRecords(operacion.fusionRecords, operacion.suplido, rTributName, rTributPorcentaje, this.iva10);

        }

        // configuro botonera
        this.botonerasSet();

        // actualizo lineas Detalle y recalculo
        this.lineas2records(lineas);
      },


      // inicializa variables
      iniVars() {
        this.presImportDirectos= [];
        this.plantillaValue= '0';
      },


      // actualizo records de lineas y vuelvo a calcular
      lineas2records(lins) {
        if (Array.isArray(lins) && lins.length) {
          this.$store.commit(this.storeName + "/recordSet1", lins);
          this.calculo();
        }
      },


      iniIvaOperacion(operacion) {

        // VENTA. Si enterraloc es de Canarias asigno como iva el elemento 0 de 'IGIC'
        switch (operacion.op) {
          case 'V':

            // si se entierra en Canarias, asigno regimen tributario IGIC
            if (this.isFromCanarias(this.expediente, 'falledestloc')) {
              this.iniIvas('1'); // IGIC
              break;
            }

            // no se entierra en Canarias. Cojo datos del expediente
            this.iniIvas(); // IVA
            break;

          default:
            // obtengo el regimen tributario segun los ivas de las lineas
            // por defecto es '0' (IVA)
            this.iniIvas(this.getLineasRt(operacion.fusionRecords));

        }

      },

      // devuelvo true/false si enterraloc es de Canarias
      isFromCanarias(record, fieldLoc) {
        if (!record || !record[fieldLoc]) return false;
        if ((record[fieldLoc]).length< 5) return false;
        return (record[fieldLoc]).substring(0,5)== this.locCanarias? true : false;
      },

      // ACCIONES QUE SE REALIZAN CUANDO HAY UNA MODIFICACION EN EL DETALLE
      /* detalle_modificado() {
        this.calculo();
      }, */


      // entro al modificar / eliminar MX
      updatedMX() {
        this.calculo();
      },


      calculo(){
        this.pres_calculo(this.records1, this.ct, this.ct.tipo_ope[2]);
        this.calculo_capitales();
      },


      calculo_capitales() {
        let capitales= {
          servpm:this.expediente.servpm,
          servnicho:this.expediente.servnicho,
          servincinera:this.expediente.servincinera,
          servlapida:this.expediente.servlapida,
          servtana:this.expediente.servtana,
          servcomplemento:this.expediente.servcomplemento
        };

        this.calculo_claves(this.records1, capitales);
      },


      calculoAPDManual(){
        this.$set(this.ct.tmt3, 2, (Number(this.ct.apd1[2]) + Number(this.ct.apd2[2])));
        this.calculo();
      },


      getLineasRt(lineas){
        console.log("lineas analizando:",lineas)
        if (!lineas.length) return "0";

        // Según iva de lineas de detalle devuelve el  indice de rTtribut al que pertenece
        let a=[0,0,0,0];
        let tipos= this.$store.state.datos_iniciales.rTribut.map(i=>i.tipos.map((j)=>Number(j.value)));

        lineas.filter(c=>tipos.filter((t,index)=> {
          if (t.includes(Number(c.iva))) a[index]++
        }));

        return a.indexOf(Math.max(...a)).toString();
      },


      iniIvas(rt="0") {
        this.$set(this.ct.rt, 2, rt);
        this.$set(this.ct.iva_general, 2, this.$store.state.datos_iniciales.rTribut[this.ct.rt[2]].tipos[0].value);
      },


      async validateParticular() {

        // chequear cuenta obligatoria
        /* if (!this.conDato(this.ct.cta_id[2])) {
          this.$root.$alert.open("Debe introducir una Cuenta/Proveedor", "error");
          return false;
        } */

        // chequear que tenga importe
        // deshabilitado a petición de Yolanda
        /* if (!this.conDato(this.ct.imp[2])) {
          this.$root.$alert.open("La Factura debe tener IMPORTE", "error");
          return false;
        } */

        // chequea que un SUPLIDO no lleve IVA
        if (this.ct.suplido[2]==1) {
          if (Number(this.ct.iva[2])!= 0) {
            this.$root.$alert.open('Imposible guardar cabecera Factura SUPLIDO con IVA', 'error');
            return false;
          }

          let detalleIVA= false;
          this.records1.forEach(lin=> { if (lin.iva> 0) detalleIVA= true; });
          if (detalleIVA) {
            this.$root.$alert.open('Imposible guardar detalle Factura SUPLIDO con IVA', 'error');
            return false;
          }
        }



        //if (Number(this.ct.exp_id[2])>0 ) {
          let err='';

          //si negativo debe seleccionar tipo c/a
          if (Number(this.ct.imp[2]<0)) {
            //if (Number(this.ct.rel_tp[2])==0) err+="* DEBES MARCAR TIPO CARGO O ABONO (C/A)";
          }

          //si cargo/abono
          if (Number(this.ct.rel_tp[2])==1 && !this.ct.relacion[2]) {
            err+="* PARA `PARCIAL´ DEBES INTRODUCIR FACTURA RELACIONADA";
          }

          if (err>"") {
            this.$root.$alert.open(err, "error");
            return false
          }

        //}

        return true;
      },


      async apdSet() {

        // si NO es una VENTA, sale
        if (this.ct.tipo_ope[2]== 'C') return;

        // inicializo importes APDS
        if (this.ct.tmt3[2]> 0) {

          this.iniImpAPD();
          return;
        }

        // Buscar tarifas APD
        let mysqlProc= [];
        let apd1=0;
        let apd2=0;

        let otc=(this.expediente.callemp=='83' || this.expediente.callemp=='84' ? 0 : 1);
        let yy= this.expediente.fhhr.substr(0,4);
        if (this.conDato(this.expediente.apd1)) mysqlProc.push({ call:"tarifaapdV",args:{ apd:this.expediente.apd1, y:yy, otc: otc }})
        if (this.conDato(this.expediente.apd2)) mysqlProc.push({ call:"tarifaapdV",args:{ apd:this.expediente.apd2, y:yy, otc: otc }})


        // llamada API
        let param= { apiArg:this.apiArgs.apdCalculo, args:mysqlProc };
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult apdSet: ', apiResult);


        //pendiente no devuelve error en sql
        // tambien devuelve 0 cuando no encuentra el apd y no []
        //if (this.conDato(this.expediente.apd1)) args.fn_args.push({call:"tarifaapd",args:{}})
       /*  var apiResult = await this.$store.dispatch("ajaxRequest", { args: args }); */
        /* console.log("resultado apd3:", apiResult); */

        if (apiResult.sql.error) {
          // alert("error en tarifas de apd")
        }


        // APD 1
        if (this.conDato(this.expediente.apd1)) {
          if( apiResult.r==[] || apiResult.r[0]==0 ) {
            this.$root.$alert.open("No se encuentra tarifa de apd1: CD" + this.expediente.apd1, "error");

          } else {
            apd1=(this.conDato(this.expediente.apd2) || this.expediente.p_nacional=='1' || this.expediente.p_internacional=='1')
              ? this.round(Number(apiResult.r[0][0])/2) : Number(apiResult.r[0][0] );
          }
        }

        // APD 2
        if (this.conDato(this.expediente.apd2)) {
          if (( apiResult.r==[] || apiResult.r[1]==0 )
            || (!this.conDato(this.expediente.apd1) && apiResult.r[0][0]==0)
            || (!this.conDato(this.expediente.apd1) && apiResult.r[0][1]==0)) {
              this.$root.$alert.open("No se encuentra tarifa de apd2: CD"+this.expediente.apd2, "error");

          } else {
            apd2=(this.conDato(this.expediente.apd1)? this.round(Number(apiResult.r[0][1])/2) : Number(apiResult.r[0][0])/2);
          }
        }

        this.$set(this.ct.apd1, 2, apd1);
        this.$set(this.ct.apd2, 2, apd2);
        this.$set(this.ct.tmt3, 2, Number(apd1) + Number(apd2));
        this.calculo();

        // pasamos APDS a negativo (si corresponde)
        this.apdNegativo();
      },


      // acciones botones----------------------------------------
      async acepto() {
        if (!this.conDato(this.ct.cta_id[2])) {
          this.$root.$alert.open("Debe introducir una Cuenta/Proveedor", "error");
          return;
        }

        this.$root.$alert.open("Elija estado:","confirm",null,["ACEPTADA","RECHAZADA","CANCELAR"]).then((r)=>{ if (r!= 2) this.aceptoRespuesta(r); });
      },

      async aceptoRespuesta(r) {

        r= Number(r)+1;

        // llamada API
        let param= { apiArg:this.apiArgs.estado, args:{ id: this.record.id, aceptada:r } };
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult Acepto Estado: ', apiResult);

        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // hago un 'ver' del MD
        this.execAccion({ accion:0 });

        // recargo el padre
        await this.masterStoreUpdate('refresh');
      },


      async bloqueo(){
        if (!this.conDato(this.ct.cta_id[2])) {
          this.$root.$alert.open("Debe introducir una Cuenta/Proveedor", "error");
          return;
        }

        if (this.app==2 && this.ct.bloqueada[2]==0 && this.ct.aceptada[2]==0){
          this.$root.$alert.open("Antes de bloquear el presupuesto debe aceptarlo o rechazarlo")
          return;
        }

        if (this.app==2 && this.ct.suplido[2]==1 && this.ct.bloqueada[2]==1){
            this.$root.$alert.open("Para desbloquear un Pago directo debe llamar al CTD");
              return;
        }

        this.$root.$alert.open("OPCIONES DE BLOQUEO:","confirm",null,["Desbloquear", "Bloquear", "CANCELAR"]).then(r=> this.bloqueoRespuesta(r) );
      },


      async bloqueoRespuesta(r){
        if (r==2) return;

        // llamada API
        let param= { apiArg:this.apiArgs.estado, args:{ id: this.record.id, bloqueada:r } };
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult Bloqueo Estado: ', apiResult);

        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // hago un 'ver' del MD
        this.execAccion({ accion:0 });

        // recargo el padre
        await this.masterStoreUpdate('refresh');
      },


      async pasoFactura() {
        //comprobamos antes de que este cerrado por el ctd
        if (this.expediente.chkconf24!='1') {
          this.$root.$alert.open("Imposible Asignación de Factura<br>Expediente Abierto en el CTD", "error");
          return;
        }
        //
        this.$root.$alert.open("¿ Generamos/Asignamos Numeración <b>FACTURA VENTA</b> ?<br>" +
            "<br>Se genera Número de Factura y Fecha.<br>Después de Aceptar se Emite Factura en PDF.<br>Recuerda que debes contabilizar el expediente.<br><br>"
            ,"confirm")
          .then(r=> { if (r) this.pasoFacturaRespuesta(); }
        );
      },


      async pasoFacturaRespuesta() {

        // llamada API
        let param= { apiArg:this.apiArgs.pasoFactura, args:{ id: this.record.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult paso Factura: ', apiResult);

        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // muestro pdf factura
        await this.pdf();

        // hago un 'ver' del MD
        await this.execAccion({ accion:0 });

        // recargo el padre
        this.masterStoreUpdate('refresh');
      },


      async pasoTramitacion(){
        //if (!this.noEdit || this.ct.tipo_ope[2]!='C') return;
        this.$root.$alert.open("¿ Crear VENTA de TRAMITACIÓN ?","confirm").then(r=> { if (r) this.pasoTramitacionRespuesta(); });
      },

      async pasoTramitacionRespuesta() {

        let operacion= await this.operacionFromMD('fusionV');
        if (operacion=== false) return;

        // modifico objeto operación
        operacion.cuentaTipo= this.ct.tipo_cta[2];
        operacion.cuentaId= this.ct.cta_id[2];
        operacion.iva= this.ct.iva_general[2];
        operacion.fusionRecords= [];

        // datos extra tramitacion
        operacion.relacion= this.ct.fra_doc[2];
        operacion.tmt1= (Number(this.ct.base[2])*5/100).toFixed(2);

        // ejecuto Operación
        this.execOperacion({}, operacion);
      },


      async pasoVenta(){
        //if (!this.noEdit || this.ct.tipo_ope[2]!='C') return;
        this.$root.$alert.open("¿Generamos NUEVA factura de <b>VENTA</b> ?:","confirm").then(r=> { if (r) this.pasoVentaRespuesta(); });
      },

      async pasoVentaRespuesta() {

        let operacion= await this.operacionFromMD('fusionV');
        if (operacion=== false) return;

        operacion.iva= this.ct.iva_general[2];
        operacion.suplido= 0;

        // añade suplido a cada linea
        operacion.fusionRecords.map(lin=> lin.suplido= this.ct.suplido[2]);

        // ejecuto Operación
        this.execOperacion({}, operacion);
      },


      // COMPONENTES DINAMICOS (asignación)
      documentos() {
        this.fieldAux= "id";
        this.tipoView= "FP";
        this.componenteDinamico= 'expDocsF';
      },


      // componente dinámico suplidos
      suplidos() {
        this.syncAccionSend= "buscar";
        this.fieldAux= "id";
        this.componenteDinamico= "suplidosF";
      },


      // Importacion de presupuestos funeraria
      impPrestos() {
        if (this.noEdit) return;

        this.fieldAux= 'cta_id';
        this.tipoView= "FC";
        this.componenteDinamico= "presImportF";
        this.filtroImportServicio.exp_id= this.expediente.id;
        this.filtroImportServicio.cta_id= this.ct.cta_id[2];
        /* this.filtroImportServicio.cia_id= this.expediente.callemp;
        if (this.expediente.callemp==53258) this.filtroImportServicio.cia_id='50165';

        // añadida nueva linea para añadir año a importacion
        this.filtroImportServicio.fhhr=this.expediente.fhhr; */
      },


      async impPrestosExtraer(evt) {
        console.log('EVENTO impPrestosExtraer: ', evt);

        // eliminamos componente Dinámico
        // al eliminarlo se cierra automáticamente la ventana modal
        this.componenteDinamico= null;

        // si la acción es cerrar no continuo
        if (evt.accion == "cerrar" || evt.accion== 6) return;

        // si llega aquí llegamos con una accion=extraer
        this.presImportID= evt.item.id;

        // llamada API para importar presupuesto
        let param= { apiArg:this.apiArgs.importar, args:{ id: this.presImportID, fnt:'get' } };
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult importLINS: ', apiResult);

        //pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open('Error al importar presupuesto', 'error');
          return;
        }


        // actualizo schema
        this.$set(this.ct.id_presciac, 2, evt.item.id_presciac);
        this.$set(this.ct.presimp, 2, evt.item.imp);
        this.$set(this.ct.presbase, 2, evt.item.pnfsuma);
        this.$set(this.ct.presid, 2, evt.item.id);
        this.$set(this.ct.presmunicipio, 2, evt.item.municipio);
        this.$set(this.ct.presfun, 2, evt.item.fun_id);

        // si no hay una cuenta definida, asigno la recibida
        if (!this.ct.cta_id[2]) {
          this.$set(this.ct.tipo_cta, 2, '1');
          this.$set(this.ct.cta_id, 2, evt.item.fun_id);
          this.$set(this.ct.cta_id[4].comp, 'valueAux', evt.item.fune);
        }

        let lins= [];
        lins= [...apiResult.r[0],...apiResult.r[1]];
        if (!lins.length) return;

        console.log("lins", lins);
        this.$set(this.ct.presdetalle, 2, this.presPackEncode(lins));

        // actualizo array lineas
        this.lineas2records(apiResult.r[0]);

        // si el presupuesto es un SUPLIDO, pone las lineas como 'S'
        // si NO lo es, recalculas IVA de esas lineas
        if (this.ct.suplido[2]=='1') {
          this.cambioTipoLins(this.ct.suplido[2]);

        }else {
          this.recalcularIva(this.ct.iva_general[2]);
        }


        // guardo la array de directos para mostrarla
        this.presImportDirectos= apiResult.r[1];
        console.log("this.presImportDirectos: ", this.pres_import_directos);

      },


      // BOTONERA COMUN--------------------------------------------------------------------------
      async generarPresupuestos() {
        this.$root.$alert.open("¿Crear presupuestos relacionados?:", "confirm").then(r=> { if (r) this.generarPresupuestosFin(); });
      },

      async generarPresupuestosFin() {

        let param= { apiArg:this.apiArgs.importar, args:{ id:this.presImportID, fnt:'set', exp_id:this.expediente.id } };
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult generar Presupuestos: ', apiResult);

        //pendiente: controlar error
        if (apiResult.sql.error==true) return;

        // reseteo presImportDirectos para que desaparezca generar presupuestos asocioados de pantalla
        this.presImportDirectos=[];
        this.presImportID= 0;

        // Paso records de presupuestos a expediente
        this.$store.commit(this.masterStore + '/recordReadSet', apiResult.r);
      },

      // funescom
      //     presupuestos
      //         accion: importar,
      //         id: (presto),
      //         gen: 0        (Generar Compras si/no)
      //         exp_id: (expediente) * Solo si gen=1

      // gen=0    devuelve 2 arrays con  lC-neas
      // gen=1   devuelve array con los presupuestos del exped.

      changePlantilla() {
        if (this.plantillaValue=="0") return;
        this.$root.$alert.open("¿Importar  plantilla?:","confirm").then(r=> { if (r) this.changePlantillaRespuesta(); });
      },

      async changePlantillaRespuesta() {
        let param= { apiArg:this.apiArgs.plantilla, args:{ rt:this.ct.rt[2], id:this.plantillaValue }};
        console.log("changePlantillaRespuesta *************************:", this.ct.rt[2], this.plantillaValue);

        this.plantillaValue = "0";
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log("plantilla Result:", apiResult)

        // Pendiente: controlar error generico
        if (apiResult.sql.error) return;

        // actualizo lineas
        this.lineas2records(apiResult.r[0]);
      },

      changeTipoCargoAbono() {

        let tipo= this.ct.rel_tp[2];

        // tipo 'PARCIAL' y 'VENTA', inicializa importes APDSAPD a 0 y sale
        if (tipo=='1' && this.ct.tipo_ope[2]=='V') {
          this.iniImpAPD();
          return;
        }

        // si no tipo 'NULA' o 'TOTAL', sale
        //if ((tipo!='2' && tipo!='3')) return;

        //si nulo -> pasamos importes a negativo
        if (tipo=='2') {
          let records= JSON.parse(JSON.stringify(this.records1));
          records.forEach(item => {
              if (item.und>0) item.und=-item.und; else
              if (item.pvp<0) item.pvp=-item.pvp;
              item.imp=this.round(item.und*item.pvp);
          });
          this.$store.commit(this.storeName + '/recordSet1', records);
          this.calculo();

          // pasamos APDS a negativo (si corresponde)
          this.apdNegativo();
        }


      },


      cambioTipo() {
        if (this.record.tipo_ope=='V') return;

        let tipo= (this.record.suplido=='1' ? 'COMPRA' : 'SUPLIDO');

        this.$root.$alert.open('CAMBIAMOS FACTURA seleccionada a <b> ' + tipo + '</b> ?<br>Acuerdate de revisar todas las lineas con sus conceptos e importes/suplidos.<br><br>', 'confirm')
        .then(r=> { if (r) this.cambioTipoFra(); });
      },


      async pdf() {
        if (!this.ID) return;
        let param= { apiArg:this.apiArgs.pdf, args:{ id: this.ID }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // abro documento
        window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");
      },


      copy() {
        const records = JSON.parse(JSON.stringify(this.records1));
        if (!records.length) {
          this.$root.$alert.open("No hay datos para copiar", "error");
          return;
        }

        this.$store.commit("data2State", { modulo: "datos_iniciales", prop: "recordsCopy", value: records });
        this.$root.$alert.open("Datos copiados", "info");
        this.botonPega();
      },


      pega() {

        const records = JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.recordsCopy));
        if (!records.length) {
          this.$root.$alert.open("No hay datos para pegar", "error");
          return;
        }

        if (records.length && !this.noEdit) {
          this.$store.commit(this.storeName + '/recordSet1', records);

          // si el presupuesto es un SUPLIDO, pone las lineas como 'S'
          // si NO lo es, recalculas IVA de esas lineas
          if (this.ct.suplido[2]=='1') {
            this.cambioTipoLins(this.ct.suplido[2]);

          }else {
            this.recalcularIva(this.ct.iva_general[2]);
          }

          this.$root.$alert.open("Datos pegados", "info");
        }

      },


      //
      changeRt(val) {
        let ivaStr= this.$store.state.datos_iniciales.rTribut[val].tipos[0].value;
        this.recalcularIva(ivaStr);
      },

      changeIvaGeneral(val) {
        this.recalcularIva(val);
      },

      recalcularIva(iva) {
        this.$set(this.ct.iva_general, 2, iva);

        let records1= JSON.parse(JSON.stringify(this.records1));
        records1.forEach(item => {
          if(item.sup=='N') {
            item.iva= iva;
            // se quita que ponga el IVA al 10% SOLO en las Ventas
            if (this.rTributName=="IVA" && this.iva10.includes(Number(item.prod_id))) {
              item.iva="10.0";
            }
          } else
            item.iva=0;

        });

        this.$store.commit(this.storeName + '/recordSet1', records1);
        this.calculo();
      },


      // si la cuenta seleccionada es de Canarias, cambio el regimen tributario a IGIC
      // incluido el de las lineas
      changeCta(evt) {
        if (!evt[4].comp) return;
        if (evt[4].comp.record) {
          this.changeSuplidos(evt);
          this.rtCanarias(evt);
        }

        // si no es una factura de Venta, no calculo tramitación
        if (this.ct.tipo_ope[2]!='V' || this.noEdit) return;

        // obtiene el tipo de tramitación
        this.getTramitacion();
        this.changeTramitacion();
      },


      changeSuplidos(evt) {
        let tp= this.ct.tipo_cta[2];
        let sup= this.ct.suplido[2];
        let pdi= this.ct.pdi[2];

        // misc y ayuntamiento pasamos a +S
        if (tp=='3' && evt[4].comp.record.tipo=='AYUNTAMIENTO' && sup=='0') {
          this.cambioTipoFra();
          this.$root.$alert.open('Factura ha sido convertida a +SUPLIDO', 'success', 1800);
          return;
        }

        // pdi
        if (sup=='0' && evt[4].comp.record.pdi!= pdi) this.$set(this.ct.pdi, 2, evt[4].comp.record.pdi);
      },


      // asigno rt a IVA o IGIC dependiendo de:
      // Si es una venta y se entierra en Canarias => IGIC
      // Si es una compra compruebo la localidad de la funeraria/cia/misc para ver su origen
      //   si el origen es Canarias => IGIC, si no => IVA
      // después recalculo lineas
      rtCanarias(evt) {
        let rt= '0';
        if (this.ct.tipo_ope[2]== 'V' && this.isFromCanarias(this.expediente, 'falledestloc')) {
          rt= '1';

        }else {
          // si funeraria es de canarias
          rt= (this.isFromCanarias(evt[4].comp.record, 'loc'))? '1' : '0';
        }

        this.iniIvas(rt);
        this.changeRt(rt);
      },


      //
      async cambioTipoFra() {
        let ss= (this.ct.suplido[2]=='1' ? 0 : 1);
        this.$set(this.ct.suplido, 2, ss);

        this.$set(this.ct.pdi, 2, (ss==1?ss:this.record.pdi_cta_id));
        this.cambioTipoLins(ss);
      },

      cambioTipoLins(ss) {
        let records= JSON.parse(JSON.stringify(this.records1));
        records.forEach(item => {
            if (ss==1 && item.sup!='S') item.sup='S';
            if (ss==1 && item.sup=='S') item.iva=0;
        });

        this.$store.commit(this.storeName + '/recordReadSet1', records);
        this.calculo();
      },



      //
      getTramitacion() {
        console.log('getTramitacion...', this.ct.tipo_cta[2], this.ct.tipo_ope[2], this.ct.tipo_cta[2]!=1, this.ct.tipo_ope[2]!='V');

        // si no es una VENTA no obtiene la tramitación
        if (this.ct.tipo_ope[2]!= 'V') return;

        // venta de tramitación
        // importes APDS a 0, tramitación a 'VARIOS'
        if (this.ct.tipo_cta[2]==1 && this.ct.tipo_ope[2]=='V') {
          this.iniImpAPD();
          this.setTramitación('9');
          return;
        }


        //
        let cta= this.ct.cta_id[2] || this.expediente.callemp || 0;
        switch (Number(cta)) {
          case 0:
          case 83: // Mapfre España
          case 86: // Bankinter Seguros Generales
            this.setTramitación('1');
            return;

          case 84: // Mapfre Asistencia
            this.setTramitación('2');
            return;

          case 53258: // Kutxabank
            this.setTramitación('3');
            return;

          default:
            // miscelanea y expediente de Mapfre
            // importes APDS a 0, tramitación a 'VARIOS'
            if (this.ct.tipo_cta[2]== 3 && this.expediente.callemp==83) {
              this.iniImpAPD();
              this.setTramitación('9');
              return;
            }

            // expediente con encargo
            // importes APDS a 0, tramitación a 'ENCARGO'
            if (this.expediente.encargo== 1) {
              this.iniImpAPD();
              this.setTramitación('4');
              return;
            }

            // tramitación particular
            if (Number(this.expediente.servtipo)== 3) {
              switch (Number(this.expediente.servparticular)) {
              case 1:
                this.setTramitación('5'); // RECOMENDADO
                return;

              case 2:
                this.setTramitación('6'); // SI24
                return;
              }

            }

            // tramitación a 'VARIOS'
            this.setTramitación('9');
        }

      },


      // asgina tarifa de tramitación
      setTramitación(tramId) {
        this.$set(this.ct.tmttipo1, 2, tramId);
      },

      // actualiza porcentaje de tramitación y recalcula
      changeTramitacion() {
        let tram= this.itemsTramitacion.filter(elem=> elem.id== this.ct.tmttipo1[2]);
        if (!tram.length) return;

        // actualiza porcentaje tramitación
        this.$set(this.ct.tmtpor1, 2, tram[0].fn? tram[0].fn(tram[0]) : tram[0].por);

        // si porcentaje es 0, resetea importe tramitación 1 y recalcula
        if (tram[0].por== 0) this.$set(this.ct.tmt1, 2, 0);
        this.calculo();
      },


      // inicializa importes de APDS
      iniImpAPD() {
        this.$set(this.ct.apd1, 2, 0);
        this.$set(this.ct.apd2, 2, 0);
        this.$set(this.ct.tmt3, 2, 0);
        this.calculo();
      },



      //------------------------------------------------------------------------------------------------
      // PENDIENTE: meter en mixinctrls
      conDato(i) {
        if (i == "0" || i == "" || typeof i === "undefined" || i == null) return false;
        return true;
      },


      // pasamos APDS a negativo si es tipo cargo 'NULO' o 'TOTAL' y una 'VENTA'
      apdNegativo() {
        let VENTA= this.ct.tipo_ope[2]== 'V';
        if (!VENTA) return;

        // tipo de abono: 'PARCIAL', 'NULO', 'TOTAL'
        let tipo= this.ct.rel_tp[2];
        if (tipo!='2' && tipo!='3') return;

        // si el importe del APD ya es negativo, sale
        if (Number(this.ct.tmt3[2])< 0) return;

        this.$set(this.ct.apd1, 2, -this.ct.apd1[2]);
        this.$set(this.ct.apd2, 2, -this.ct.apd2[2]);
        this.$set(this.ct.tmt3, 2, Number(this.ct.apd1[2]) + Number(this.ct.apd2[2]));
        this.calculo();
      },


      // obtiene porcentaje de Pólizas Mapfre
      // si la póliza es de la Gomera, devuelve 3%, a partir de 2024 = mapfre (2.7)
      // si no lo es, devuelve el porcentaje definido
      getMapfrePor(tram) {
        let y=this.expediente.fhhr.substr(0,4);
        //alert(this.expediente.fhhr + " - " + y );

        // a partir de 2024 = 2.7, antes 4.7 - mapfre, mapfre asis y encargos
        let tra= (y<'2024' ? 4.7 : tram.por);
        // gomera
        tra=this.expediente.poliza=='0802280025319' && y<'2024' ? 3 : tra;

        return tra;
      },

      // SAP Restaura Factura (Modificada o Anulada)
      //
      restauraFactura() {
        //if (this.record.tipo_ope=='V') return;

        this.$root.$alert.open("<b><u>CAMBIOS CONTABILIDAD SAP</u></b><br>FACTURA MODIFICADA: <br><b>" + this.record.fra_doc + "</b><br>",
            "confirm",null,["Cancelar", "PRECIOS (M)", "PROVEEDOR,NUMERO (A)"])
          .then(r=> this.restauraFacturaRespuesta(r) );
      },

      async restauraFacturaRespuesta(r=0) {
        if (!r) return;

        /* if (r==1) {
          alert('Todavia No: ' + this.record.id);
          //this.$root.$alert.open('Operación realizada correctamente', 'success', 1800);
          return;
        } */

        //1=precios (Modif),2=Proveedor (Anular)
        let ope= Number(r);

        // llamada API
        let param= { apiArg:this.apiArgs.restauraFactura, args:{ fra: this.record.id, ope } };
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult *restauraFactura: ', param, apiResult);

        // Pendiente: controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Operación realizada correctamente', 'success', 1800);

        // hago un 'ver' del MD
        this.execAccion({ accion:0 });

        // recargo el padre
        await this.masterStoreUpdate('refresh');
      },

      // Reeditar Suplido
      reeditarSuplido() {
        if (this.record.tipo_ope=='V' || this.record.suplido=='0') return;

        this.$root.$alert.open("<b><u>REEDITAMOS SUPLIDO PARA CAMBIO NUMERO DE FACTURA</u></b><br>FACTURA A MODIFICAR:<br> <b>" +
            this.record.fra_doc + "</b><br>","confirm",null,["Cancelar", "Aceptar"])
          .then(r=> this.reeditarSuplidoRespuesta(r) );
      },

      async reeditarSuplidoRespuesta(r=0) {
        if (!r) return;

        //this.botonEditar();
        this.execAccion({ accion:2 });
      },


    },

    computed: {
      expediente() {
        // compruebo si tenemos los datos del expendiente en el array de datos del MD
        if (!this.recordRead[2]) {
          if (this.recordAux) return this.recordAux;

          this.$root.$alert.open('expte computed: Faltan datos expediente', 'error');
          return {};
        }

        return this.recordRead[2];
      },


      tipo_ope(){
        let tipo= this.ct.tipo_ope[2];
        if (this.ct.suplido[2]==1) tipo="SUP";
        let obj= this.itemsTipoOpe.find(item =>item.tipo_ope==tipo);
        return obj?obj:this.itemsTipoOpe[0];
      },


      apdComputed:{
        get: function() {
          return this.ct.tmt3[2]!= 0;
        },

        set: function() {
          this.apdSet();
        }
      },


      getIvas() {
        if (!this.$store.state.datos_iniciales) return [];
        if (this.ct.rt[2]) return this.$store.state.datos_iniciales.rTribut[Number(this.ct.rt[2])].tipos;

        return[];
      },


      rTributName(){
        if (this.ct.rt[2]!='') return this.$store.state.datos_iniciales.rTribut[Number(this.ct.rt[2])].name;
        return '';
      },


      modalComponenteDinamico() {
        return this.componenteDinamico!= null? true : false;
      },


      // Campo relacionado
      // Se utiliza sólo cuando se hace un nuevo
      relationID() {
        return this.recordAux.id;
      },


    },


    watch: {
      noEdit1() {
        this.botonPega();
      }
    }


  };

</script>



<style scoped>
.v-input--selection-controls {
  margin-top: 0;
}
.select {
  /* outline: 1px solid red; */
  border: 1px solid rgb(94, 114, 134, 0.60);
  border-radius: 18px 18px 18px 18px;
  font: 1rem;
  width: 200px;
  height: 25px;
  outline: none;

  margin-right: 2px;
  margin-top:4px;
  text-align-last: center;

  /* background-color: rgb(236, 182, 157); */
   /* background-color: rgb(10, 173, 179); */
  background-image: none;
  cursor: pointer;
  /* background: url(assets/selectArrow.png) no-repeat right #BADA55; */
  /* clip-path: polygon(100% 0%, 0 0%, 50% 100%); */
}
</style>
