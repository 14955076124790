<template>
  <div class="pres_sumas conflex">
   
      <vtlabel style="flex:1 1 19%"
        label="Bases"
        :txt="ct.base[2] | num(2)"
        stilot="text-align:right"
      >
      </vtlabel>

      <vtlabel style="flex:1 1 19%"
        label="IVA"
        :txt="ct.iva[2] | num(2)"
        stilot="text-align:right"
      >
      </vtlabel>

      <vtlabel style="flex:1 1 19%"
        label="Suplidos"
        :txt="ct.sup[2] | num(2)"
        stilot="text-align:right">     
      </vtlabel>

      <div v-if="ct.irpf">
        <vtlabel style="flex:1 1 20%"        
          label="I.R.P.F"
          :txt="ct.irpf[2] | num(2)"
          stilot="text-align:right"
        />      
      </div>

      <vtlabel style="flex:1 1 20%"
        label="Total"
        :txt="ct.imp[2] | num(2)"
        stilot="font-weight:bold;text-align:right;color:#01579B"
              >
       </vtlabel>
    
  </div>
</template>

<script>
export default {
  props: {
    ct: { type: Object, default: null }    
  }
};
</script>
