<!-- añadir / eliminar zonas apds  -->

<template>
  <div class="apdMZonas">
    <v-dialog
      content-class="modal"
      v-model="modal"
      persistent>

      <div>
        <v-sheet :elevation="4">
          <!-- Cabecera -->
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.mto"
              :titulo='titulo'
              @onEvent="$emit('onEvent', { accion:6 })">
            </baseHeader>
          </div>


          <!-- Contenido -->
          <div class="contenedor" style="width:500px">
            <div class="columna">
              <baseBtraExtra class="conflex"
                :modulo="btExtCfg"
                @onEvent="execAccion">
              </baseBtraExtra>

              <v-textarea
                v-bind="$textarea"
                style="padding-top:10px"
                v-model="txt"
                label="Introduce el código postal o parte del mismo separado por comas"
                rows="3"
                no-resize>
              </v-textarea>

            </div>
          </div>
        </v-sheet>
      </div>
    </v-dialog>
  </div>
</template>



<script>

  import { mixBtras } from "@/mixins/mixBtras";
  import baseHeader from "@/base/baseHeader";
  import baseBtraExtra from "@/base/baseBtraExtra";

  export default {
    mixins: [mixBtras],
    components: { baseHeader, baseBtraExtra },
    props: {
      accionZona: { type:Number, default: 0},
      tipoZona: { type:Number, default: 0},
      cps: { type:String, default: ""},
      masterStore: { type: String, default: '' },
      masterRecordField: { type: String, default: 'id' },
    },

    data() {
      return {
        modal:true,
        titulo:'',
        btExtCfg: JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA)),
        txt:'',
        apiArgs:{}
      };
    },


    created() {
      this.iniDataParticular();
      this.botonerasSet();
      this.iniDataApi();
    },


    methods: {
      iniDataParticular() {
        console.log("DEV apdMZonas ********************** (iniDataParticular)");

        // título ventana
        this.titulo = (this.accionZona==1? 'Añadir' : 'Eliminar') + ' Zonas';

        // añade botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'guardar', btn: "guardar" },
          { accion:'pegar', btn: "pegar" }
        ];
      },


      // defino todas las API calls
      iniDataApi() {
        this.apiArgs= {
          codPostales: ['', 'codPostales', { fnt:'', apd:0, cpo:'', tp:0 }, "apds"],
        }
      },


      // configura botonera
      botonerasSet() {
        this.btnSet(this.btExtCfg, "guardar",{ view:true, disabled: false });
        this.btnSet(this.btExtCfg, "pegar",{ view:true, disabled: this.cps? false:true  });
      },


      // acción botón guardar
      async guardar() {
        if (!this.txt) {
          this.$root.$alert.open('Debe introducir uno o varios códigos postales', 'error');
          return;
        }

        // llamada API
        let param= { apiArg:this.apiArgs.codPostales, args:{
          fnt:this.accionZona==1? 'set' : 'anular',
          apd:this.masterField,
          cpo:this.txt,
          tp:this.tipoZona
        }};

        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }


        this.$root.$alert.open('Operación realizada correctamente', 'success', 1800);

        // actualizo lista de zonas y salgo
        this.$emit('updateZonas', { r:apiResult.r, tipo:this.tipoZona });
        this.$emit('onEvent', { accion:6 });
      },


      pegar() {
        this.txt= this.cps;
      },


      execAccion(evt) {
        let accion = evt.accion;
        if (this.$isFunction(this[accion])) this[accion](evt);
      }

    },


    computed: {
      // devuelvo el valor del campo masterRecordField del record sincronizado
      masterField() {
        if (!this.masterStore) return null;
        if (!this.masterSyncRecord) return null;
        if (typeof this.masterSyncRecord[this.masterRecordField] === 'undefined') return 0;
        return this.masterSyncRecord[this.masterRecordField];
      },

      // records seleccionados del componente maestro asociado
      masterSyncRecord() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      },

    }

  };
</script>
