<!-- Cierres de Expedientes -->

<template>
  <div class="expCierres" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">
          <div class="columna">
                 
            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div> 

            <v-sheet :elevation="4">
              <!-- Controles del Mto -->
              <div class="contenedor" style="width:750px">
                <!-- <div v-if="record" class="contenedor" > -->
                        
                <!-- Botonera -->
                <div class="conflex">
                  <baseBtraExtra class="conflex"
                    :permExtra="permExtra"
                    :modulo="btExtCfg"
                    @onEvent="execAccion">
                  </baseBtraExtra>                
                </div>
            
                <!-- Controles -->
                <div>
                  <div class="conflex" style="padding-top:10px"> 
                    <div class="columna" style="width:55%">
                      
                      <div class="cab">Expediente<noscript></noscript></div>
                      <v-sheet v-bind="$cfg.styles.marco"> 
                        <div class="conflex">
                          <uiText
                            style="width:40%"
                            v-model="record.control"                            
                            label="Control"
                            readonly>              
                          </uiText>

                          <uiText
                            style="width:60%"
                            v-model="record.callref1"                            
                            label="Referencia"
                            readonly>              
                          </uiText>
                        </div>
                          
                        <uiText
                          v-model="record.fallename"
                          label="Nombre"
                          readonly>              
                        </uiText>                                                  
                      </v-sheet>                    
                    </div>
                        
                    <div class="columna" style="width:45%">                
                      <div class="cab" style="width:90px">Cierres</div>
                      <v-sheet v-bind="$cfg.styles.marco"> 
                        
                        <div class="conflex" style="align-items:center">                   
                          <v-checkbox
                            style="width:30%"
                            v-bind="$checkbox"
                            v-model="ct.chk1[2]"                            
                            :label="ct.chk1[1]"
                            :disabled="ct.chk2[2]=='1' || editado || (ct.chk1[2]=='1' && ndep==1)"
                            dense
                            @change="showCierreApertura(1)">
                          </v-checkbox>
                        
                          <div class="conflex" style="width:70%">
                            <v-icon :style="ct.chk1[2]=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                            <span style="padding-left:10px">{{ ct.chk1m[2] }}</span>                        
                          </div>
                        </div>

                        <div class="conflex" style="align-items:center">                   
                          <v-checkbox
                            style="width:30%"
                            v-bind="$checkbox"
                            v-model="ct.chk2[2]"
                            :label="ct.chk2[1]"                       
                            :disabled="ct.chk1[2]=='0' || ct.chk3[2]=='1' || editado || ndep<3 "
                            dense
                            @change="showCierreApertura(2)">
                          </v-checkbox>
                        
                          <div class="conflex" style="width:70%">
                            <v-icon :style="ct.chk2[2]=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                            <span style="padding-left:10px">{{ ct.chk2m[2] }}</span>                       
                          </div>
                        </div>

                        <div class="conflex" style="align-items:center">                   
                          <v-checkbox
                            style="width:30%"
                            v-bind="$checkbox"
                            v-model="ct.chk3[2]"
                            :label="ct.chk3[1]"     
                            :disabled="ct.chk2[2]=='0' || editado || (ndep<3 && ct.chk3[2]=='1') || (ndep<6 && ct.chk3[2]=='0')"
                            dense
                            @change="showCierreApertura(3)">
                          </v-checkbox>
                        
                          <div class="conflex" style="width:70%">
                            <v-icon :style="ct.chk3[2]=='1'? 'color:red' : 'color:green'" small>mdi-account</v-icon>
                            <span style="padding-left:10px">{{ ct.chk3m[2] }}</span>                       
                          </div>
                        </div>
                                        
                      </v-sheet>  
                    </div>
                  </div>

                  <div class="conflex" style="align-items: flex-end;">
                    <div class="cab" :style="`cursor:pointer;height:20px;${tab==1? 'background-color:red':''}`" @click="tab=1">Por cumplimentar para cierre</div>
                    <div v-show="!chkPulsado" class="cab" :style="`margin-left:2px;cursor:pointer;height:20px;${tab==2? 'background-color:red':''}`" @click="tab=2">Observaciones CTD</div>
                  </div>

                  <v-sheet v-bind="$cfg.styles.marco"> 
                    <div v-if="tab==1">
                      <div v-if="!chkPulsado">                    
                        <uiObs3                       
                          :record="record"
                          :schema="ct"
                          :schemaComponente="ct.chkdef"
                          :edicion="ct.chk3[2]=='0'">                                              
                        </uiObs3>
                      </div>

                      <div v-else>       
                        <h2 style="color:red">{{ titulo }}</h2>

                        <v-select
                          v-bind="$select"
                          v-model="ct.motivo[2]"                        
                          :label="ct.motivo[1]"
                          :items="itemsCierreApertura"
                          dense
                          item-value="name"
                          item-text="name">
                        </v-select>
            
                        <v-textarea
                          v-bind="$textarea"
                          v-model="ct.comentario[2]"
                          :label="ct.comentario[1]"
                          rows="3"
                          no-resize>                      
                        </v-textarea>
                      </div>
                    </div>

                    <div v-else>
                      <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="ct.obs3_incidencias"
                        :edicion="ct.chk3[2]=='0'">
                      </uiObs3>
                    </div>
                  </v-sheet> 
                </div>
              </div>
            </v-sheet> 
          </div>
        </template>
      </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseHeader from "@/base/baseHeader"; 
  import baseBtraExtra from "@/base/baseBtraExtra";
  import dualTemplate from "@/components/dualTemplate";   
    
  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraExtra, dualTemplate },    

    data() {
      return {
        stIni: {
          api: "expCierresM",
          name:"expCierresM",
          titulo:"Configuración Cierres",
          mView:'',
          pView:[]
        },
        
        itemsRecepcion: [],
        itemsCierreApertura:[],        
        chkPulsado:0,
        titulo:'',
        tab:1
      };
    },
  

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:'guardar', btn: "guardar" },
          { accion:'getDatos', btn: "cancelar" },
        ];
      
        // items adicionales
        this.itemsRecepcion= [
          { id:"0", name:"Ordinario"},
          { id:"1", name:"Certificado"},
          { id:"2", name:"Mensajeria"},
          { id:"3", name:"Otros"},
          { id:"4", name:"Firma Digital"},
        ];
      },


      iniDataApi() { 
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'cierre', { id:0, fnt:'get' }, "expediente"];
        this.apiArgs.set= ["", 'cierre', { id:0, fnt:'set', txt:'' }, "expediente"];
        this.apiArgs.obs= ["", 'cierre', { id:0, fnt:'obs', tip:0, obs:'', obs3:'', chk:0, mot:'' }, "expediente"];
      },


      crudFin() {
        this.botonerasSet();
      },


      botonerasSet() {
        this.btnSet(this.btExtCfg, 'guardar', { view:true, disabled:!this.editado });
        this.btnSet(this.btExtCfg, 'getDatos', { view:true, disabled:!this.editado });
      },


      // inicializo variables y ejecuto acción 'ver'
      async getDatos() {        
        this.itemsCierreApertura= [];     
        this.chkPulsado= 0;

        this.execAccion({ accion:0 });
      },


      // muestro opciones de Cierre / Apertura
      showCierreApertura(chk) {
        //let V=(this.ct['chk' + chk][2]=='0' ? '1' : '0');
        let V=Number(this.ct['chk' + chk][2]);

        console.log('*** showCierreApertura ', chk, V, this.ctb);

        // cierre ctd si validamos
        if (chk==1 && V==1) {          
          if (!this.validarCierreCTD()) {
            this.getDatos(); 
            return;
          } 
        }

        //cierre admon sino contabilizada
        if (chk==2 && V==1 && !this.ctb) {          
          this.$root.$alert.open('<br>IMPOSIBLE CIERRE ADMON<hr><br><b>EXISTEN FACTURAS SIN CONTABILIZAR</b><br>', 'error');   
          
          this.getDatos(); 
          return;
        }

        this.tab= 1; // activo pestaña observaciones de cierre
        this.itemsCierreApertura= (V==1 ? this.$store.state.datos_iniciales.motivos_cierre : this.$store.state.datos_iniciales.motivos_reapertura);       
        this.chkPulsado= chk;
        this.titulo= (V==1? "CIERRE " : "APERTURA ") + (chk==1? "CTD" : chk==2? "ADMON" : "") + " EXPEDIENTE";
      },
     

      // guardar observaciones / Cierre
      guardar() {        
        if (!this.chkPulsado) return this.guardarObs();
        this.guardarCierre();
      },


      // guardar Observaciones
      guardarObs() {
        if (!this.ct.chkdef[4].comp.valueAux && !this.ct.obs3_incidencias[4].comp.valueAux) {
          this.$root.$alert.open('Debe introducir una observación', 'error');
          return;
        }

        this.guardarObsFin();
      },

      async guardarObsFin() {
                        
        // apiCall
        let param = { apiArg: this.apiArgs.obs, args: { id:this.ID, obs: this.ct.chkdef[4].comp.valueAux, obs3:this.ct.obs3_incidencias[4].comp.valueAux }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }          
        
        // recargo datos
        this.getDatos();
      },


      // guardar Cierre
      async guardarCierre() {
         if (!this.ct.motivo[2]) {
          this.$root.$alert.open('Debe introducir un MOTIVO', 'error');
          return;
        }

        let val= this.ct['chk' + this.chkPulsado][2];
        
        await this.$root.$alert.open('Deseas ' + (val=="1"? "ACEPTAR":"CANCELAR") + ' el cierre ' + (this.chkPulsado==1? "CTD":this.chkPulsado==2? "ADMON": "") + " del expediente?", "confirm")
          .then(r=> { if (r) { this.cierreAPI(); }          
        });
      },


      // 
      async cierreAPI() { 
        let param = { apiArg: this.apiArgs.set, args: { 
          id:this.ID, 
          tip:this.chkPulsado, 
          txt:this.ct.comentario[2],
          chk:this.ct['chk' + this.chkPulsado][2]=='1'? 1 : 0,
          mot:this.ct.motivo[2]
        }};

        let apiResult = await this.$store.dispatch("apiCall", param);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }         
        
        // recargo el padre y salgo
        this.$store.commit(this.masterStore + '/disparoSet');
        this.$emit('onEvent', { accion:6 });
      },


      validarCierreCTD() {        
        let msg='';
        var F= this.record;         
        console.log('******************** validarCierre',F);

        //si bloqueado por Jefe de zona (chkbloq)
        if (F.chkbloq=='1') {
          msg+="* BLOQUEADO POR COORDINADOR DE SERVICIO<br>";
        }            

        //si prestamos apd y no es renuncia comprobamos conformidad funeraria por cada apd
        if (F.chkrenuncia=='0' && F.p_apd=='1') {
            if (F.apd1>'0' && F.chkfun1=='') msg+="* FALTA CONFORMIDAD FUNERARIA (1)<br>";
            if (F.apd2>'0' && F.chkfun2=='') msg+="* FALTA CONFORMIDAD FUNERARIA (2)<br>";
        }
        
        //insuf.capital
        if (F.chkcapital=='1' && F.chkautoriza=='0' && F.chkautoriza_no=='0') 
            msg+="* INSUFICIENCIA DE CAPITAL SIN AUTORIZAR<br>";
        
        //cs aplicado
        if (F.cs_aplica=='1' && F.cs_aplicajz=='0') 
            msg+="* CS SIN AUTORIZAR POR COORDINADOR DE SERVICIO<br>";   

        //fin de compras y que haya facturas (excepcion mapfre asistencia)            
        if (F.callemp!='84' && F.chkpresapd=='0' && F.fras>'0') {
          msg+="* FIN DE COMPRAS NO MARCADO<br>";  
        }              

        //que tenga algun traslado ó renuncia/ocultacion
        if (F.tras=='0') {          
          msg+="* DEBE HABER AL MENOS UNA LINEA DE TRASLADOS Ó (RENUNCIA/OCULTACIÓN)<br>";
        }
            
        ///////////////////////////////
        //mostramos ERRORES producidos
        if (msg) {
          this.$root.$alert.open("IMPOSIBLE CIERRE CTD<hr><br>" + msg, 'error');          
        }        
        return (msg=='');

      },

    },

    
    computed: {

      // devuelve schema particular
      sch() {      
        return {
          chk1: [ 'chk1', 'CTD', "0" ],
          chk2: [ 'chk2', 'ADMON', "0" ],
          chk3: [ 'chk3', 'CIERRE', "0" ],
          chk1f: [ 'chk1f', 'Conf. CTD', "0" ],
          chk2f: [ 'chk2f', 'Conf. ADMON', "0" ],
          chk3f: [ 'chk3f', 'CIERRE', "0" ],
          chk1u: [ 'chk1u', 'Conf. CTD', "0" ],
          chk2u: [ 'chk2u', 'Conf. ADMON', "0" ],            
          chk3u: [ 'chk3u', 'CIERRE', "0" ],
          chk1m: [ 'chk1m', 'Conf. CTD', "0" ],
          chk2m: [ 'chk2m', 'Conf. ADMON', "0" ],
          chk3m: [ 'chk3m', 'CIERRE', "0" ],
          chkdef: [ '', '', "", [],
            {comp: { 
              type:"obs3",
              fields:{ gestion:"chkdef" },
              valueAux:""                            
            }}
          ],
          
          digf_rec: [ 'digf_rec', 'Recepción', "Ordinario" ],
          digf_obs: [ 'digf_obs', 'Observaciones', "" ],
          motivo: [ 'motivo', 'Motivo', "" ],
          comentario: [ 'comentario', 'Comentario', "" ],
          obs3_incidencias: ["", "", "", [],
            {comp: {
                type:"obs3",
                fields:{gestion:"obs_ca24", jz:"obs_jz", apds:"obs_apd"},
                valueAux:"",
                valueAux1:""
            }}
          ], 
          obs_ca24:["obs_ca24","","", ["nosave"]],
          obs_jz:["obs_jz","","", ["nosave"]],
          obs_apd:["obs_apd","","", ["nosave"]]
        }
      },


      chk2() {
        return (this.ct.chk2[2]=='1');
      },    

      ctb() {
        return (this.record.ctb=='1');
      },

      editado() {
        if (!this.ct) return;
        return (this.ct.chkdef[4].comp.valueAux>'' || this.ct.obs3_incidencias[4].comp.valueAux>'' || this.chkPulsado>0);
      }

    },    


    watch: {
      editado() {
        this.botonerasSet();
      }
    }

  };
</script>
