<!-- Finder Facturas del Expediente asociado -->

<template>
  <div class="frasExpFF" v-if="loaded && masterSyncRecord">
    <div class="contenedor">
      <div class="cab">FACTURAS</div>
      <v-sheet :elevation="4">

        <!-- Contenido -->
        <div class="contenedor">
          <!-- Filtro de Busqueda -->
          <div>
            <div style="align-items:center">
              <v-select
                style="width:30%"
                v-bind="$select"
                v-model="ct.tipo_facturas[2]"
                :label="ct.tipo_facturas[1]"
                :items="itemsTipoFras"
                item-value="id"
                item-text="name"
                show-select
                @change="changeTipoFra">
              </v-select>
            </div>
          </div>

          <!-- Grid -->
          <baseGridMD
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="-1"
            :multipleSelect="multipleSelect"
            fixed-header
            hide-default-footer
            show-select
            dense
            @onEvent="execAccion">

              <!-- Slot botonera Top -->
              <template v-slot:gridTop="{}">
                <div class="pt-2" style="display:flex;width:100%;">

                  <!-- Botones Generales -->
                  <baseBtraExtra
                    :modulo="btExtCfgGen"
                    @onEvent="execAccion">
                  </baseBtraExtra>

                  <!-- Botones Facturacion -->
                  <baseBtraExtra
                    style="padding-left:25px"
                    :modulo="btExtCfgFacturacion"
                    @onEvent="execAccion">
                  </baseBtraExtra>

                  <!-- Resto Botones Generales  -->
                  <baseBtraExtra
                    style="padding-left:25px"
                    :modulo="btExtCfgGen2"
                    @onEvent="execAccion">
                  </baseBtraExtra>

                </div>
              </template>

              <!-- Slot CheckBox Selección Simple/Múltiple -->
              <template v-slot:selectMultiple="{}">
                <v-checkbox
                  style="padding-top:10px"
                  v-model="multipleSelect"
                  label="M"
                  :title="multipleSelect? 'Selección Simple' : 'Selección Múltiple'"
                  @change="changeGridSelection">
                </v-checkbox>
              </template>
          </baseGridMD>
        </div>
      </v-sheet>

      <div class="conflex" style="padding-top:20px">
        <div class="columna" style="width:32%">
          <div style="width:80px">

            <!-- Botones Ventas -->
            <baseBtraExtra
              :modulo="btExtCfgVentas"
              @onEvent="execAccion">
            </baseBtraExtra>

          </div>

          <div class="cab" style="margin-top:10px">Ventas
            <span style="color: gray; padding-left: 45px">{{ syncRecord.exp_id }}</span>
          </div>
          <v-sheet v-bind="$cfg.styles.marco">
            <div class="columna">
              <uiText
                styleLabel="width:100px"
                v-model="ct.serv_v[2]"
                label="Servicios"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.tram_v[2]"
                label="Tramitación"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.apd_v[2]"
                label="APD"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.total_v[2]"
                label="Total"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>
            </div>
          </v-sheet>
        </div>

        <div class="columna" style="width:32%">
          <div class="cab" style="margin-top:43px">Compras
            <span style="color: gray; padding-left: 45px">{{ syncRecord.tipo_cta + '-' + syncRecord.cta_id }}</span>
          </div>
          <v-sheet v-bind="$cfg.styles.marco">
            <div class="columna">
              <uiText
                styleLabel="width:100px"
                v-model="ct.serv_c[2]"
                label="Servicios"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.sup[2]"
                label="Suplidos"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.sup_fac[2]"
                label="Sup. Fact."
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.pd[2]"
                label="PD"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.dif_cv[2]"
                label="Dif. CV"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.bp[2]"
                label="B/P"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                v-model="ct.dif_sup_fac[2]"
                label="Dif. SF"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>

              <uiText
                styleLabel="width:100px"
                styleInput="color:red;font-weight:bold"
                v-model="ct.dif[2]"
                label="Diferencia"
                labelSide
                format="money"
                :ndecimals=2
                readonly>
              </uiText>
            </div>
          </v-sheet>
        </div>


        <div class="columna" style="width:36%">
          <div style="width:80px">
            <!-- Botones Factura -->
            <baseBtraExtra
              :modulo="btExtCfgFra"
              @onEvent="execAccion">
            </baseBtraExtra>
          </div>

          <div class="cab" style="margin-top:10px">Factura
            <span style="color: gray; padding-left: 45px">{{ syncRecord.id }}</span>
          </div>

          <v-sheet v-bind="$cfg.styles.marco">
            <div v-show="syncRecord.tipo_ope!='C'">
              <button @dblclick="changeEmitida"
                v-show="new Date(syncRecord.fra_emision).getTime() && ndep>=6"
                style="text-align:right;color:maroon"><i><small>Eliminar/Emitida</small></i>
              </button>

              <uiDate
                styleLabel="width:100px"
                styleInput="font-size: 11px;"
                v-model="syncRecord.fra_emision"
                label="Emitida"
                labelSide
                type="datetime-local"
                readonly>
              </uiDate>

              <uiText
                styleLabel="width:100px"
                v-model="syncRecord.fra_emision_usu"
                label="Ope"
                labelSide
                readonly>
              </uiText>
            </div>

            <div>
              <uiText
                styleLabel="width:100px"
                styleInput="font-weight:bold;text-align:left;color:green"
                v-model="syncRecord.fra_conta_asi"
                label="Asiento"
                labelSide
                readonly>
              </uiText>

              <uiDate
                styleLabel="width:100px"
                styleInput="font-size: 12px;"
                v-model="syncRecord.fra_conta_fh"
                label="Contabilizada"
                type="datetime-local"
                readonly>
              </uiDate>

              <!-- ReContabilizar -->
              <!-- {{ accionRow.fra_conta_asi }} {{ accionRow.idRR }} -->
              <button @dblclick="recontabilizar"
                v-show="syncRecord.fra_conta_fh>'2021' && syncRecord.fra_conta_asi>0 && ((syncRecord.idRR==0 && ndep>=6) || ndep==9)"
                style="text-align:right;color:maroon">
                <i><small>Recontabilizar</small></i>
              </button>

              <uiText
                styleLabel="width:100px"
                v-model="syncRecord.fra_conta_usu"
                label="Ope"
                labelSide
                readonly>
              </uiText>

              <uiDate
                styleLabel="width:100px"
                styleInput="font-size: 12px;"
                v-model="syncRecord.fra_vto"
                label="Vencimiento"
                labelSide
                readonly>
              </uiDate>

              <uiDate
                styleLabel="width:100px"
                v-model="syncRecord.fra_pago_fh"
                label="Fecha Pago"
                labelSide
                readonly>
              </uiDate>

              <uiText
                styleLabel="width:100px"
                styleInput="font-weight:bold;text-align:left"
                v-model="syncRecord.fra_pago"
                :label="syncRecord.pagada"
                labelSide
                format="money"
                readonly>
              </uiText>

              <!-- <uiDate
                styleLabel="width:100px"
                styleInput="font-size: 11px;"
                v-model="syncRecord.digf_fh"
                v-show="syncRecord.tipo_ope=='C'"
                label="Digitalizada"
                type="datetime-local"
                readonly>
              </uiDate> -->

              <uiText
                styleLabel="width:100px"
                styleInput="font-weight:bold;text-align:center;color:red"
                v-show="syncRecord.ptedoc> 0"
                v-model="syncRecord.xptedoc"
                label="Pte.DOC."
                labelSide
                readonly>
              </uiText>
            </div>
          </v-sheet>
        </div>
      </div>
    </div>


    <!-- Componentes virtuales  -->
    <component
      :is="componenteDinamico"
      syncUpdate
      :syncDisparo="syncDisparoSend"
      :syncAccion="syncAccionSend"
      :storeRaiz="storeName"
      :masterStore="masterStoreAux"
      :masterRecordField="fieldAux"
      :readOnly="readOnly"
      :viewMtoOnly="viewMtoOnly"
      :fra="syncRecord.id"
      :tip=2
      :recordAux="expediente"
      :operacion="newOperacion"
      @refresh="refresh"
      @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
    </component>

  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import { mixOperacion } from "@/mixins/mixOperacion.js";
  import { mixCalculosFras } from "@/mixins/mixCalculosFras.js";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import frasRecepcion from "@/components/frasRecepcion";
  import frasExpEmail from "@/components/frasExpEmail";
  import expDocsF from "@/components/expDocsF.vue";
  import suplidosF from "@/components/suplidosF.vue";
  import expPresMD from "@/components/expPresMD.vue";
  import expCierres from "@/components/expCierres.vue";
  /* import frasDigi from "@/components/frasDigi"; */
  import frasPagos from "@/components/frasPagos";
  import frasPteDoc from "@/components/frasPteDoc";


  export default {
    mixins: [mixF, mixOperacion, mixCalculosFras],
    components: {
      baseGridMD,
      baseBtraExtra,
      frasRecepcion,
      frasExpEmail,
      expDocsF,
      suplidosF,
      expPresMD,
      expCierres,
      frasPagos,
      frasPteDoc
    },
    props: {},

    data() {
      return {
        stIni: {
          api: "frasExpFF",
          titulo:"",
          name:"frasExpFF",
          mView:'expPresMD',
          pView:[],
          selection:'both' // first (selecciona 1er registro), preserve (conserva registros seleccionados), both (ambos)
        },

        fieldAux:'id',
        masterStoreAux:'',
        itemsTipoFras: [],              // array de Tipo de Facturas
        btExtCfgGen: null,              // botonera EXTRA de botones Generales
        btExtCfgGen2: null,             // botonera EXTRA del resto de botones Generales
        btExtCfgFacturacion: null,      // botonera EXTRA de botones de Facturación
        btExtCfgVentas: null,           // botonera EXTRA de ventas
        btExtCfgFra: null,              // botonera EXTRA de factura
        newOperacion: {},               //
        multipleSelect:false
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // botones Extra Generales
        this.btExtCfgGen.btnsAccion= [
          { accion:'verFra', icono: "mdi-receipt", title:'Ver Factura' },
          { accion:'recepcionFras', icono: "mdi-archive-arrow-down-outline", title:"Recepción Facturas" },
          { accion:'documentos', btn: "docs" },
          { accion:'fichero', btn: "pdf" },
          { accion:'envioFras', btn: "sendEmail" },
          { accion:'envioGd', icono: "mdi-arrow-up-thick", title:'Envio Factura MF (Gestor Documental)' },
          { accion:'pasoFactura', texto:"F", styT:'color:green;font-weight:bold;font-size:1rem;' },
          { accion:'suplidos', texto: "SUP", title:'Suplidos' },
        ];

        // resto botones Extra Generales
        this.btExtCfgGen2.btnsAccion= [
          { accion:'refresh', btn:'refresh', title:'Recargar', badge:this.totalExps }
        ];

        // botones Extra Facturación
        this.btExtCfgFacturacion.btnsAccion= [
          { accion:'compra', svg:require('@/assets/svg/compras.svg'), title:"Nueva Compra" },
          { accion:'venta', svg:require('@/assets/svg/ventas.svg'), title:"Nueva Venta" },
          { accion:'compraS', svg:require('@/assets/svg/suplidos.svg'), title:"Nuevo Suplido" },
          { accion:'fusionC', svg:require('@/assets/svg/fusionC.svg'), title:"Nueva Fusión Compra" },
          { accion:'fusionV', svg:require('@/assets/svg/fusionV.svg'), title:"Nueva Fusión Venta" },
          { accion:'fusionS', svg:require('@/assets/svg/fusionS.svg'), title:"Nueva Fusión Suplido" },
        ];

        //
        this.btExtCfgVentas.btnsAccion= [
          { accion:'cierre', icono: "mdi-lock-outline", title:'Cierres' },
          { accion:'contabilizar', icono: "mdi-notebook-plus-outline", title:"Contabilizar Facturas", styB:this.styleBtnContabilizar },
        ];

        //
        this.btExtCfgFra.btnsAccion= [
          /* { accion:'frasDigi', texto: "DIG", title:"Digitalización de Facturas", styB:this.styleBtnDigi }, */
          { accion:'frasPagos', svg:require('@/assets/svg/frasPagos.svg'), title:"Pagos Factura", styB:this.styleBtnfrasPagos },
          { accion:'frasPte', texto: "PTE", title:"Pendiente Documentación", styB:this.styleBtnfrasPte }
        ];


        this.headers = [{
          name:[],
          fields:[
            { text: "T", value: "tipo_fra", witdh: "1%" },
            { text: "Factura", value: "fra_doc", witdh: "9%" },
            { text: "Fecha", value: "fra_fh", witdh: "9%", filtro:"fecha" },
            { text: "*Cuenta Fra.", value: "name_cta_id",  width: "65%" },
            { text: "Importe", value: "imp", witdh: "9%" },
            { text: "*", value: "xpte", witdh: "1%" },

          ]}
        ];

        this.headerGrid= this.headerSet();


        // items adicionales
        // array de tipo de facturas
        this.itemsTipoFras= [
          { id:"0", name:"Pendientes" },
          { id:"1", name:"Aceptadas" },
          { id:"2", name:"Rechazadas" },
          { id:"3", name:"Todas" }
        ];

      },


      // inicializo Btras
      iniBtra() {
        this.btExtCfgGen= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
        this.btExtCfgGen2= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
        this.btExtCfgFacturacion= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
        this.btExtCfgVentas= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
        this.btExtCfgFra= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));
      },


      //
      iniDataApi() {
        this.apiArgs.fichero= ["", 'factura', { id:0, opc:1 }, "expediente"];
        this.apiArgs.envioMF= ["", 'envio_mf', { id:0, tp:3 }, "documento"];
        //this.apiArgs.envioMF= ["", 'emailGestorFacturaVenta', { id:0 }, "facturas"];
        this.apiArgs.nFactura= ["", 'facturaNumero', { id:0 }, "facturas"];
        this.apiArgs.fusion= ['', 'fusionLineas', { id:0, tipo:"fras" }, 'presupuesto'];
        this.apiArgs.estado= ["", 'estado', { id:0, fra_emision:'null' }, "facturas"];
        this.apiArgs.conta= ["", 'conta', { id:0, exp_id:0 }, "facturas"];
      },


      // configuro estado botones
      botonerasSet() {
        let NS=(!this.syncRecord.id || this.syncRecord.id==0);
        let selected= Array.isArray(this.syncRecord)? true : (Object.keys(this.syncRecord).length? true:false);

        this.btnSet(this.btExtCfgGen, "verFra", { disabled:NS });
        this.btnSet(this.btExtCfgGen, "recepcionFras", { disabled:(!this.COMPRA && !this.SUPLIDO) });
        this.btnSet(this.btExtCfgGen, "documentos", { disabled:(!this.COMPRA && !this.SUPLIDO) });
        this.btnSet(this.btExtCfgGen, "fichero", { disabled:NS });
        this.btnSet(this.btExtCfgGen, "envioFras", { view:this.VENTA && !NS });
        this.btnSet(this.btExtCfgGen, "envioGd", { view:this.VENTA && !NS});
        this.btnSet(this.btExtCfgGen, "pasoFactura",{ view:(this.VENTA || this.TRAMITACION) && !this.syncRecord.fra_doc });
        this.btnSet(this.btExtCfgGen, "suplidos", { view:this.COMPRA && !NS, disabled: Number(this.syncRecord.sup)==0 || Number(this.syncRecord.suplido)==1 });

        this.btnSet(this.btExtCfgFacturacion, "compra", { disabled:!this.expediente.id });
        this.btnSet(this.btExtCfgFacturacion, "venta", { disabled:!this.expediente.id });
        this.btnSet(this.btExtCfgFacturacion, "compraS", { disabled:!this.expediente.id });
        this.btnSet(this.btExtCfgFacturacion, "fusionC", { disabled:!selected });
        this.btnSet(this.btExtCfgFacturacion, "fusionV", { disabled:!selected });
        this.btnSet(this.btExtCfgFacturacion, "fusionS", { disabled:!selected });
        this.btnSet(this.btExtCfgGen2, "refresh", { disabled:!this.expediente.id });

        this.btnSet(this.btExtCfgVentas, "cierre", { disabled:!this.expediente.id });
        this.btnSet(this.btExtCfgVentas, "contabilizar", { disabled:!this.CONTABILIZO || this.CERRADO || !this.expediente.id });

        /* this.btnSet(this.btExtCfgFra, "frasDigi", { disabled:!this.COMPRA && !this.SUPLIDO }); */
        this.btnSet(this.btExtCfgFra, "frasPagos", { disabled:NS });
        this.btnSet(this.btExtCfgFra, "frasPte", { disabled:NS });
      },


      // si el masterID es nulo, no hago la llamada para obtener los registros y
      // blanqueo el GRID
      recordsGetBefore() {
        if (this.masterField== null) {
          this.$store.commit(this.storeName + '/recordSet', []);
          this.calcFras(this.ct, []);
          return false;
        }

        return true;
      },


      // deseleccionada selección múltiple de lineas
      // recalcula campos compra / venta y filtra por tipo Factura
      recordsGetAfter() {
        this.multipleSelect= false;

        this.recalcula();
        this.filterTipoFra(this.ct.tipo_facturas[2]);
      },


      // fn en mixinCtrls (recalculo totales de fras)
      recalcula() {
        this.calcFras(this.ct, this.records);
      },


      // evento al cambiar el select de tipo de facturas
      changeTipoFra(id) {
        this.filterTipoFra(id);
      },


      // filtro por el tipo de fra recibido
      filterTipoFra(id) {
        let records= JSON.parse(JSON.stringify(this.recordsRead[0]));
        let recordsFiltered= id== "3"? records : records.filter(elem=> elem.aceptada== id);

        this.$store.dispatch(this.storeName + '/recordSetPreserve', recordsFiltered);
      },


      // API para obtener el fichero (fra/doc) asociado al id recibido
      async fichero() {
        let param = { apiArg: this.apiArgs.fichero, args: { id:this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // abro documento
        window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");
      },


      // componente dinámico de Recepción de Facturas
      recepcionFras() {
        this.syncAccionSend= "0";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= 'frasRecepcion';
      },


      // componente dinámico de Documentos de Expedientes
      documentos() {
        //this.syncAccionSend= "buscar";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= 'expDocsF';
      },


      // componente dinámico de Envio de Facturas
      envioFras() {
        this.syncAccionSend= "0";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= 'frasExpEmail';
      },


      // ver presupuesto/factura seleccionada
      verFra() {
        this.syncDisparoSend= !this.syncDisparoSend;
        this.syncAccionSend= "0";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= 'expPresMD';
      },


      // componente dinámico nueva fra compra expediente
      compra() {
        this.clickOperacion('compra');
      },


      // componente dinámico nueva fra venta expediente
      venta() {
        this.clickOperacion('venta');
      },


      // componente dinámico nueva fra compra suplido expediente
      compraS() {
        this.clickOperacion('compraS');
      },


      // componente dinámico nueva fusión compra expediente
      fusionC() {
        this.clickOperacion('fusionC');
      },


      // componente dinámico nueva fusión venta expediente
      fusionV() {
        this.clickOperacion('fusionV');
      },


      // componente dinámico nueva fusión suplidos expediente
      fusionS() {
        this.clickOperacion('fusionS');
      },


      // componente dinámico cierre de expediente
      cierre() {
        this.syncAccionSend= "0";
        this.masterStoreAux= this.masterStore;
        this.componenteDinamico= 'expCierres';
      },


      // componente dinámico suplidos
      suplidos() {
        this.syncAccionSend= "buscar";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= "suplidosF";
      },


      // componente dinámico digitalización de facturas
      /* frasDigi() {
        this.syncAccionSend= "0";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= "frasDigi";
      }, */


      // componente dinámico pago facturas
      frasPagos() {
        this.syncAccionSend= "0";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= "frasPagos";
      },


      // componente dinámico facturas pendientes de documentación
      frasPte() {
        this.syncAccionSend= "0";
        this.fieldAux= "id";
        this.masterStoreAux= this.storeName;
        this.componenteDinamico= "frasPteDoc";
      },


      // recargo finder
      async refresh () {
        this.storeUpdate('refresh');
      },


      // registros totales
      totalExps() {
        return this.recordCount;
      },


      // style botón Contabilizar Facturas
      styleBtnContabilizar() {
        return `background-color:${this.CONTABILIZADO? '#a5d6a7' : '#ef9a9a'} !important;`;
      },


      // style botón Digitalización Facturas
      styleBtnDigi() {
        return `background-color:${new Date(this.syncRecord.digf_fh).getTime()? '#a5d6a7' : '#ef9a9a'} !important;`;
      },


      // style botón Pagos Facturas
      styleBtnfrasPagos() {
        return `background-color:${this.PAGADA? '#a5d6a7' : '#ef9a9a'} !important;`;
      },


      // style botón Facturas pendiente Documentación
      styleBtnfrasPte() {
        return `background-color:${this.PENDIENTE? '#ef9a9a' : '#a5d6a7'} !important;`;
      },


      // recolecto datos de la operación y la ejecuto
      // pasamos objeto operacion al MD como propiedad llamada 'operacion'
      async clickOperacion(accion) {
        // recolectaF en mixOperacion
        console.log('clickOperacion: ', accion);
        let operacion= await this.operacionFromFinder(accion);
        if (operacion=== false) return;


        // ejecuto la funcion 'operacion' en el MD
        this.masterStoreAux= this.storeName;
        this.newOperacion= operacion;
        this.execAccion({ accion:'execOperacion' });
      },


      // pregunto si enviamos factura seleccionada al Gestor Documental
      envioGd() {
        this.$root.$alert.open('ATENCION<br>Enviamos Factura Venta (pdf) a MAPFRE (Gestor Documental)?<br>Factura: ' + this.syncRecord.fra_doc + ', Fecha: ' + this.$options.filters['fecha'](this.syncRecord.fra_fh), 'confirm')
        .then(r=> { if (r) this.envioEmailGD(); });
      },


      // API envio email a Gestor Documental
      async envioEmailGD() {
        let param = { apiArg: this.apiArgs.envioMF, args: { id:this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Factura enviada correctamente', 'success', 1800);
      },


      // contabilizar facturas
      contabilizar() {
        if (this.CONTABILIZADO) {
            this.$root.$alert.open('<br>NO HAY FACTURAS ACEPTADAS PENDIENTES DE CONTABILIZAR<br>', 'error');
            return;
        }

        let msg='';

        // si diferencias C/V añadimos msg
        let dif=Number(this.ct.dif[2]);
        if (dif!=0) {
          msg="<br>ATENCION<br>EXISTEN DIFERENCIAS DE COMPRAS Y VENTAS:<br><b>" + dif + "</b><hr>";
        }

        this.$root.$alert.open(msg + '<br>CONTABILIZAMOS Facturas del Expediente Control Nº ' + this.expediente.control + '?<br><br>', 'confirm')
          .then(r=> { if (r) this.contabilizarFra(0); });
      },


      //
      recontabilizar() {
        this.$root.$alert.open('RECONTABILIZAMOS FACTURA <b>' + this.syncRecord.fra_doc + '</b>?', 'confirm')
          .then(r=> { if (r) this.contabilizarFra(this.syncRecord.id); });
      },

      async contabilizarFra(id) {
        let param = { apiArg: this.apiArgs.conta, args: { id, exp_id: this.expediente.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult contabilizo_factura: ', apiResult);

        if (apiResult.sql.error) {
          this.$root.$alert.open('<u>IMPOSIBLE CONTABILIZAR</u><br><br>' + apiResult.sql.msg,'error');
          return;
        }

        // devuelve array [0] -> [datos fras], [1] -> [0]asientos,[1]errores
        let r=apiResult.r[1];
        this.$root.$alert.open('<u>FACTURA CONTABILIZADA</u><br>Asientos: <b>' + r[0] + '</b><br>' + (r[1] ? r[1] : '') , 'success');

        // actualiza records GRID sin ApiCall
        await this.$store.dispatch(this.storeName + '/recordReadSetPreserve', apiResult.r);

        // recalcula campos compra / venta y filtra por tipo Factura
        this.recalcula();
        this.filterTipoFra(this.ct.tipo_facturas[2]);
      },

      reeditar() {
        this.$root.$alert.open('REEDITAMOS FACTURA <b>' + this.syncRecord.fra_doc + '</b>?', 'confirm')
          .then(r=> { if (r) this.editarFra(this.syncRecord.id); });
      },

      async editarFra(id) {
        let param = { apiArg: this.apiArgs.conta, args: { id, exp_id: this.expediente.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult contabilizo_factura: ', apiResult);

        if (apiResult.sql.error) {
          this.$root.$alert.open('<u>IMPOSIBLE CONTABILIZAR</u><br><br>' + apiResult.sql.msg,'error');
          return;
        }

        // devuelve array [0] -> [datos fras], [1] -> [0]asientos,[1]errores
        let r=apiResult.r[1];
        this.$root.$alert.open('<u>FACTURA CONTABILIZADA</u><br>Asientos: <b>' + r[0] + '</b><br>' + (r[1] ? r[1] : '') , 'success');

        // actualiza records GRID sin ApiCall
        await this.$store.dispatch(this.storeName + '/recordReadSetPreserve', apiResult.r);

        // recalcula campos compra / venta y filtra por tipo Factura
        this.recalcula();
        this.filterTipoFra(this.ct.tipo_facturas[2]);
      },

      //
      async pasoFactura(){
        this.$root.$alert.open("¿ Pasar a <b>FACTURA VENTA</b> ?<br>" +
          "<br>Se genera Número de Factura y Fecha.<br>Después de Aceptar se Emite Factura en PDF.<br>Recuerda que debes contabilizar el expediente.<br><br>"
          ,"confirm")
          .then((r)=> { if (r) this.pasoFacturaRespuesta(r); });
      },

      async pasoFacturaRespuesta() {

        // llamada API
        let param= { apiArg:this.apiArgs.nFactura, args:{ id: this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult paso Factura: ', apiResult);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Operación realizada correctamente!', 'success', 1800);

        // actualizo records
        await this.refresh();

        // emitimos factura
        this.fichero();
      },


      // eliminar emitida y pdf de nuevo
      async changeEmitida(){
        this.$root.$alert.open("¿ Cancelamos EMITIDA en Factura de VENTA y generamos PDF de nuevo ?" ,"confirm")
        .then((r)=> { if (r) this.changeEmitidaFra(r) });
      },

      async changeEmitidaFra() {

        // llamada API
        let param= { apiArg:this.apiArgs.estado, args:{ id: this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult paso Factura: ', apiResult);

        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.$root.$alert.open('Operación realizada correctamente!', 'success', 1800);

        // muestro pdf factura
        await this.fichero();

        // actualizo records
        this.refresh();

      },


      // change del checkbox del GRID (selección simple/múltiple de las lineas)
      // al marcarlo selecciona todas la linas del GRID
      // al desmarcarlo selecciona la primera linea
      changeGridSelection(val) {
        let selected= val? this.records : this.records.length? [this.records[0]] : [];
        this.$store.commit(this.storeName + '/recordsSelectedSet', selected);

        this.multipleSelect= val;
      }

    },


    computed: {

      // estado de la fra: COMPRA, VENTA, SUPLIDO
      COMPRA() { return this.syncRecord.tipo_fra=="C"; },
      VENTA() { return this.syncRecord.tipo_fra=="V"; },
      SUPLIDO() { return this.syncRecord.tipo_fra=="S"; },
      CONTABILIZO() { return true; },

      // añadido control de cierres y se pasa como props
      CONTABILIZADO() {
        return this.records.filter(elem=> {
          if (elem.aceptada=="1" && (elem.fra_conta_asi=="" || elem.fra_conta_asi=="0")) return elem.id}).length? false : true;
      },

      TRAMITACION() { return this.syncRecord.tipo_fra=="W"; },
      CERRADO() { return (this.masterSyncRecord.chkconfadmon=='1'); },
      PAGADA() { return Number(this.syncRecord.fra_pago)!=0? true : false },
      PENDIENTE() { return this.syncRecord.ptedoc>'0'? true : false; },

      expediente() { return this.recordAux; },
    },


    watch: {
      syncRecord() {
        this.botonerasSet();
      }
    }

  }
</script>