export var lineasOp= {
   
    pasoVentaRecords(lineas, suplido, rTributName, rTributPorcentaje, iva10) {       
        console.log('PASO VENTA A RECORDS: ', lineas, suplido, rTributName, rTributPorcentaje, iva10);
        
        // directo de una factura de suplido
        if (Array.isArray(lineas) && lineas.length) suplido=Number(suplido); 
                        
        //
        // map
        let recordsVenta=lineas.map(function(item){          
            item.sup="N";
            item.iva= rTributPorcentaje;

            // las flores al 10%
            if (rTributName=="IVA" && iva10.includes(Number(item.prod_id))) {        
                item.iva="10.0"
            }

            // exception tipo_op SUPLIDO
            // viene de una fusion / factura de suplidos (suplido==1)            
            let lineaSuplido=Number(item.suplido || 0);
            if (lineaSuplido==1 || suplido==1) {
              item.sup="S"
              item.iva="0.0"
            }

            return item;
        })
        
        // fin map
        console.log("Venta:", recordsVenta)
        return recordsVenta;
    }

}

