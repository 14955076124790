<!-- Importacion de Presupuestos -->

<template>
  <div class="presImportF" v-if="loaded">

    <v-dialog v-model="modal" content-class="modal" persistent>

      <div style="display:flex;justify-content:center">
        <v-sheet v-if="!viewMtoOnly" :elevation="4">

          <!-- Cabecera -->
          <div class="cabecera">       
            <baseHeader
              :cfg="$cfe.headers.finder"
              :titulo='$store.state[storeName].titulo'
              @onEvent="eventHeader"> 
            </baseHeader>
          </div>

          <!-- Contenido -->
          <div class="contenedor" style="width:1050px">

            <!-- <div v-html="info"></div> -->
            <div style="padding:5px">
              <h3>{{ fune }}</h3>
            </div>
                      
            <div style="display:flex; align-items:center">

              <v-select 
                style="width:200px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-bind="$select"
                v-model="ct.cia_id[2]"
                :items="itemsCia"
                :label="ct.cia_id[1]"
                item-value="id"
                item-text="name"
                @change="eventFiltro('buscar')"
                :disabled="itemsCia.length< 2">            
              </v-select>
          
              <v-select 
                style="width:100px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-bind="$select"
                v-model="ct.ano[2]"
                :items="itemsAno"
                :label="ct.ano[1]"
                item-value="id"
                item-text="name"
                @change="eventFiltro('buscar')"
                :disabled="itemsAno.length< 2">            
              </v-select>

              <v-select 
                style="width:280px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-bind="$select"
                v-model="ct.municipio[2]"
                :items="itemsMuni"
                :label="ct.municipio[1]"
                item-value="id"
                item-text="name"
                @change="filtrar">            
              </v-select>
            
              <v-select
                style="width:220px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-bind="$select"
                v-model="ct.tipo[2]"
                :items="itemsTipo"
                :label="ct.tipo[1]"
                item-value="id"
                item-text="name"
                @change="filtrar">
              </v-select>
      
            </div>


            <!-- Grid -->
            <baseGridMD
              :store="storeName"
              :headers="headerGrid"
              :items-per-page="-1"           
              dense
              @onEvent="execAccion">

                <!-- Slot columna header 'preferente' -->
                <template v-slot:preferente="{ item }">
                  <span style="color:green;font-weight: bold">{{ item["preferente"] === "1" ? "P" : "" }}</span>
                </template> 

                <!-- Slot columna header 'preferente' -->
                <template v-slot:name_id_presciac="{ item }">
                  {{ item.name_id_presciac }}
                  <div class="light-blue--text text--darken-3">
                    {{ item.descrip }}
                  </div>
                </template>              

                <!-- Slot campo acciones del Grid -->
                <template v-slot:acciones="{ item }">               
                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btRowCfg"
                    @onEvent="execAccion({ ...$event, ...{item:item} })">
                  </baseBtraMto>
                </template>
            </baseGridMD>
          </div>
        </v-sheet>
      </div>

    </v-dialog>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";

  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto },
    props: {
      filtroImportServicio:{ type: Object, default:()=> null }
    },

    data() {
      return {
        stIni: {
          api: "funesPresF",
          titulo:"Importación de Presupuestos", 
          name: "funesPresF",
          mView: '',
          pView:[]
        },  
    
        itemsAno: [],
        itemsCia: [],
        itemsTipo: [],
        itemsMuni: [],
        fune:'*'
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
              // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
              // si solo hubiera un elemento la cabecera tomará este por defecto 
              // Si no encontrara el nombre pondría el elemento 0
              name:[],
              fields:[
                { text: "ACCIONES", value: "acciones", sortable: false, slot:true, width: "10%" },
                { text: "AÑO", value: "ano", width:"5%" },
                { text: "CIA", value: "name_cia_id", slot:true, width:"5%" },
                { text: "TIPO", value: "name_id_presciac", slot:true, width:"40%" },
                { text: "MUNICIPIO", value: "name_municipio", width:"25%" },
                { text: "PFT", value: "preferente", slot:true, width:"5%" },
                
                // { text: "AÑO ANT.", value: "imp_ant" },
                // { text: "DIF %", value: "dif" },
                { text: "P.DIRECTO", value: "sup_pd", width:"5%" },
                // { text: "IMP.CIA", value: "imp_cia" },
                { text: "IMPORTE", value: "imp", width:"5%" },               
                
            ]}          
        ];      

        this.headerGrid= this.headerSet();     

        // defino autocarga
        this.autoLoad= 'buscar';

             
        // array de años
        /* this.itemsAno = [
          { id: "", ano: "", label: "TODOS" },
          { id: "1", ano: 2019, label: "2019" },
          { id: "2", ano: 2020, label: "2020" },
          { id: "3", ano: 2021, label: "2021" },
          { id: "4", ano: 2022, label: "2022" }
        ]; */

        // array de compañías
        /* this.itemsCia = [
          { id: "", label: "TODAS LAS CIAS" },
          { id: "83", label: "MAPFRE" },
          { id: "50165", label: "KUTXABANK" },
          // { id: "50508", label: "KUTXABANK" }
        ]; */
    
      },


      iniDataApi() {
        this.apiArgs.get=  ["", 'importar', { fnt:'lista', fun_id:0, exp_id:0, ano:0, cia:0 }, 'presupuesto']
      },


      async stRecordsGet({ origen }) {
        // apiArg: llamada API definida en el particular. 
        // args: argumentos a fusionar con los definidos en 'args' de apiArgs
        let param = { apiArg: this.apiArgs.get, args: {           
          fun_id:this.filtroImportServicio.cta_id,
          exp_id:this.filtroImportServicio.exp_id,
          ano:this.ct.ano[2],
          cia:this.ct.cia_id[2] } 
        };
        
        console.log("stRecordsGet *************************:", param);
        return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore, origen });
      },


      // gancho al final del recordsGet
      recordsGetAfter() {
        console.log('after...', this.$store.state[this.storeName].recordsRead);
        let recordsRead= JSON.parse(JSON.stringify(this.$store.state[this.storeName].recordsRead));

        // nombre funeraria
        if (recordsRead[0].length) this.fune= recordsRead[0][0].fune;
                
        // items Años
        if (recordsRead[1] && recordsRead[1].length) {
          this.itemsAno= recordsRead[1];
          this.$set(this.ct.ano, 2, recordsRead[1][0].id);
        }

        // items Compañias
        if (recordsRead[2] && recordsRead[2].length) {
          this.itemsCia= recordsRead[2];
          this.$set(this.ct.cia_id, 2, recordsRead[2][0].id);
        }

        // items Tipos de Servicio
        if (recordsRead[3] && recordsRead[3].length) {
          this.itemsTipo= recordsRead[3];
          this.$set(this.ct.tipo, 2, '0');
        }

        // items Municipios
        if (recordsRead[4] && recordsRead[4].length) {
          this.itemsMuni= recordsRead[4];
          this.$set(this.ct.municipio, 2, '0');
        }
      },


      // filtrar registros
      async filtrar() {       
        await this.stFiltrar(this.filtrarRecords, { municipio:this.ct.municipio[2], tipo:this.ct.tipo[2] });        
      },


      // filtrar por municipio / tipo servicio    
      filtrarRecords(records, params) {
        if (params.municipio== 0 && params.tipo== 0) return records;
        return records.filter(elem=> {
          if (params.municipio== 0) return elem.id_presciac== params.tipo;
          if (params.tipo== 0) return elem.municipio== params.municipio;
          return elem.municipio== params.municipio && elem.id_presciac== params.tipo;
        });
      },

    },
     
  };

</script>
