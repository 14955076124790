<!-- Documentos Expedientes -->

<template>
  <div class="exp-docs-f" v-if="loaded">

    <v-dialog v-model="modal" content-class="modal" persistent>

        <div>

          <!-- Cabecera -->
          <div class="cabecera">
            <baseHeader
              :cfg="$cfe.headers.finder"
              :titulo='$store.state[storeName].titulo'
              @onEvent="eventHeader">
            </baseHeader>
          </div>

          <v-sheet :elevation="4">
            <div class="contenedor" style="width:900px">

              <!-- <div v-html="info"></div> -->

              <!-- Filtro de Busqueda -->
              <div class="columna">
                <div style="display:flex">
                  <!-- Busqueda documento -->
                  <uiText
                    style="width:40%"
                    v-model="ct.txt[2]"
                    :label="ct.txt[1]"
                    @Enter="buscar">
                  </uiText>
                  <v-btn style="margin-left:5px" v-bind="$cfe.btn.busca" @click="buscar">
                    <v-icon dark>{{ $cfe.btn.busca.icono }}</v-icon>
                  </v-btn>

                  <!-- Ordenado -->
                  <v-select
                    style="width:25%;margin-left:5px"
                    v-bind="$select"
                    v-model="ct.orden[2]"
                    :label="ct.orden[1]"
                    :items="itemsTipoOrden"
                    item-value="d"
                    item-text="n"
                    @change="orden">
                  </v-select>

                  <!-- Filtro Tipo Documentos -->

                  <v-select
                    style="width:35%;margin-left:5px;"
                    v-bind="$select"
                    v-model="ct.filterTipo[2]"
                    :label="ct.filterTipo[1]"
                    :items="$store.getters[storeName + '/recordsAdjuntos'](0)"
                    item-value="d"
                    item-text="n"
                    @change="filtrar">
                  </v-select>
                </div>
              </div>

              <!-- Grid -->
              <baseGridMD
                style="padding-top:10px"
                :store="storeName"
                :headers="headerGrid"
                :items-per-page="-1"
                :expansible="GESTION"
                dense

                @onEvent="execAccion">

                  <!-- Slot botonera Top -->
                  <template v-slot:gridTop="{}">
                    <div v-if="GESTION">
                      <baseBtraMto
                        :perm="permMto"
                        :modulo="btGridCfg"
                        @onEvent="execAccion">
                      </baseBtraMto>
                    </div>
                  </template>

                  <!-- Slot campo acciones del Grid -->
                  <template v-slot:acciones="{ item }">
                    <baseBtraMto
                      :perm="permMto"
                      :modulo="btRowCfg"
                      @onEvent="execAccion({ ...$event, ...{item:item} })">
                    </baseBtraMto>
                  </template>


                  <!-- Slot campo tipo -->
                  <template v-slot:tipo="{ item }">
                    <b>{{ item.name || '' }}</b> &nbsp;  - &nbsp; <small>{{ item.tipo || '' }}</small>
                    <div v-if="item.des" class="light-blue--text text--darken-3"> {{ item.des }}</div>
                  </template>

                  <!-- Slot panel expansible -->
                  <template v-slot:expansibleMX="{}">
                    <expDocsMX
                      sync
                      syncUpdate
                      :syncAccion="syncAccionSend"
                      :storeRaiz="storeName"
                      :masterStore="storeName"
                      :recordAux="recordAux"
                      :readOnly="readOnly"
                      :viewMtoOnly="viewMtoOnly"
                      :fra="fra"
                      :tipoDocumento="tipoDocumento">
                    </expDocsMX>
                  </template>
              </baseGridMD>
            </div>
          </v-sheet>
        </div>
    </v-dialog>
  </div>
</template>



<script>

    import { mixF } from "@/mixins/mixF.js";
    import baseHeader from "@/base/baseHeader";
    import baseGridMD from "@/base/baseGridMD";
    import baseBtraMto from "@/base/baseBtraMto";
    import expDocsMX from "@/components/expDocsMX";

    export default {
      mixins: [mixF],
      components: { baseHeader, baseGridMD, baseBtraMto, expDocsMX },
      props: {
        tipoDocumento: { type: String, default: "0" },
        fra: { type: [Number, String], default: "" },
      },

      data() {
        return {
          stIni: {
            api: "expDocsF",
            titulo:"·Documentos Expedientes",
            name:"expDocsF",
            mView:'expDocsMX',
            pView:[],
            expansibleMX:'expDocsMX'
          },

          // array con los tipos de ordenación
          itemsTipoOrden:[]
        };
      },


      methods: {
        iniDataParticular() {
          console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

          this.headers = [{
            name:[],
            fields:[
              { text: "Ver", value: "acciones", sortable: false, width: "5%", slot:true },
              { text: "Tipo documento", value: "tipo", sortable: false, slot:true, width:'70%' },
              { text: "Fecha", value: "fhhr", sortable: false, filtro:'fechahr', width:'20%' }
            ]}
          ];

          this.headerGrid= this.headerSet();

          // añado botón de ver Pdf a las filas del GRID
          this.btRowCfg.btnsAccion= [{ accion:'verPdf', btn:'pdf' }];

          // le indico que el finder tiene un autoload con la función 'buscar'
          // al cargar el componente hago un recordGet
          this.autoLoad= 'buscar';

          // actualizo controles
          this.$set(this.ct.tip, 2, this.tipoDocumento);
          this.$set(this.ct.fra_id, 2, this.fra);

          // array de ordenación filas Grid
          this.itemsTipoOrden= [
            { d: "0", n: "Nombre", c: "name" },
            { d: "1", n: "Fecha Ascendente", c: "fhhr" },
            { d: "2", n: "Fecha Descendente", c: "fhhr" }
          ];
        },


        // añado a apiArgs la llamada a pdf
        iniDataApi() {
          this.apiArgs.pdf= ["", "fichero", { id:0, tip:0 }, 'documento'];
        },


        // gancho después de cargar los records
        // ordeno los registros por orden de fecha descendiente
        recordsGetAfter() {
          this.orden(2);
        },


        // ordenar registro
        // filtro:
        //    0: nombre ASCENDENTE
        //    1: fecha ASCENDENTE
        //    2: fecha DESCENDENTE
        orden(filtro) {
          let campo= filtro> 0? 'fhhr' : 'name';
          let orden= filtro== 2? 'DESC' : 'ASC';

          this.stOrdenar({ campo, orden });
        },

         // busqueda texto en tipo(name...)
         async buscar() {
          //alert('buscar');

          let txt= this.ct.txt[2];
          await this.stFiltrar(this.filtrarDocs, { txt });
          this.orden(this.ct.orden[2]);
        },

       // filtrar registros por tipo de documento
        async filtrar(id) {

          let txt=this.ct.txt[2];
          await this.stFiltrar(this.filtrarDocs, { id, txt });
          this.orden(this.ct.orden[2]);
        },

        // filtrar por tipo de documento
        // envíamos esta función desde 'filtrar', donde se ejecuta y actualiza los records del Grid
        filtrarDocs(records, params) {

          let txt= params.txt;
          let tip= params.id;

          //if (!tip && !txt) return records;

          console.log('**** R',params,records);

          return records.filter(elem=> {
            return (
              (tip>0 ? elem.tpd==tip : true) &&
              (txt>'' ? elem.name.toLowerCase().includes(txt.toLowerCase()) || elem.des.toLowerCase().includes(txt.toLowerCase()) : true)
            );
          });
        },


        // mostrar nueva pestaña del navegador con pdf del documento seleccionado
        async verPdf(evt) {
          let param = { apiArg: this.apiArgs.pdf, args: { id: evt.item.id, tip:this.tipoDocumento } };
          let apiResult = await this.$store.dispatch("apiCall", param);
          console.log('verPdf: ', apiResult);

          if (apiResult.sql.error) {
            this.$root.$alert.open(apiResult.sql.msg, 'error');
            return;
          }

          // abro documento NUEVA VERSIÓN
          window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");
        },


        // (override). Añade al record de busqueda el ID del maestro
        getMasterID(record) {
          if (Object.keys(this.recordAux).length) {
            record['masterID'] = this.recordAux[this.masterRecordField];
            return;
          }

          record['masterID'] = this.masterField;
        }

      },


      computed: {
        GESTION() {
          if (this.app==1) return `${this.storeName}${this.stIni.mView}`;
          return '';
        }
      }

  }

</script>


