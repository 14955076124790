<!-- Productos -->

<template>
  <div>
    <div
      id="secciones_pestanas"
      style="width:430px;display:flex;flex-wrap:wrap"
    >
      <button
        v-for="seccion in secciones"
        v-bind:key="seccion.titulo"
        v-bind:class="[
          'tab',
          seccionActiva.titulo == seccion.titulo ? 'tab_active' : '',
          seccion.titulo == 'A-Z' ? 'tab_AZ' : ''
        ]"
        v-on:click="change_section(seccion)"
      >
        {{ seccion.titulo }}
      </button>
    </div>
    <v-sheet v-bind="$cfg.styles.marco"> 
      <v-list
        rounded
        dense
        class="overflow-y-auto"
        height="660"
        max-width="410px"
      >
        <v-list-item-group v-model="item">
          <v-list-item
            v-for="item in productos_filtrados"
            :key="item.name + item.clave"
            :disabled="!edicion"
            @click="select_producto(item)"
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>-->
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <!-- 
      <ul>
        <li v-for="item in productos_filtrados" :key="item.name+item.clave">
          <hr class="solid" />

          {{item.name}}
        </li>
      </ul> -->
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    edicion: { type: Boolean, default: false }
  },

  data() {
    return {
      seccionActiva: { titulo: "A-Z" },

      secciones: [
        {
          titulo: "A-Z",
          claves: ["XJ", "FR", "CD", "YA", "YJ", "YB", "YC", "YW", "XP", "YH", "XY"]
        },
        {
          titulo: "Servic",
          claves: ["XJ", "FR", "CD"]
        },
        {
          titulo: "Nicho",
          claves: ["YA"]
        },
        {
          titulo: "Incinera",
          claves: ["YJ"]
        },
        {
          titulo: "Lápida",
          claves: ["YB"]
        },
        {
          titulo: "Tanator",
          claves: ["YC"]
        },
        {
          titulo: "R. judic",
          claves: ["YW"]
        },
        {
          titulo: "Traslad",
          claves: ["XP", "YH", "XY"]
        }
      ],

      item: null
    };
  },
  computed: {
    productos_filtrados() {
      if (this.seccionActiva.titulo === "A-Z") return this.get_productos();
      return this.get_productos().filter(prod => {
        return this.seccionActiva.claves.indexOf(prod.clave) >= 0;
      });
    }
  },
  methods: {
    get_productos() {
      return [...this.$store.state.datos_iniciales.productos];
    },
    change_section(sec) {
      this.seccionActiva = sec;
    },
    select_producto(producto) {
      this.$emit("eventSelectProduct", producto);
    }
  }
};
</script>
<style scoped>
.tab {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 25px;
  background-color:#B0BEC5;

  border-radius: 12px 12px 0 0;
  color: white;
  /* font-weight: bold; */
  font-size: 1rem;
  margin: 1px 1px 1px;
  outline: none;

  box-shadow: -14px -7px 5px -13px rgba(0, 0, 0, 0.5);
}
.tab_active {
  background-color: rgb(233, 57, 13);
}
.tab_AZ {
  background-color: rgb(49, 54, 58);
}
ul {
  padding-left: 0px;
}
li {
  height: 25px;
  list-style-type: none;
}
hr.solid {
  border-top: 1px solid rgb(252, 252, 252);
}
</style>
