<!-- Expansible de Comunicados de Expedientes -->

<template>
  <div class="expComunicadosMX" v-if="loaded">
    <v-sheet class="contenedor" :elevation="0"> 
 
      <!-- Botones Mto -->
      <div class="pb-2 d-flex justify-left">
        <baseBtraMto class="conflex" style="width:165px;"           
          :modulo="btMtoCfg" 
          :estado="estado"
          @onEvent="execAccion">        
        </baseBtraMto>

        <!-- extra -->
        <div class="conflex" style="width: 380px; margin-left: 50px">
          <baseBtraExtra class="conflex" 
            v-show="!noEdit"          
            :permExtra="permExtra"
            :modulo="btExtCfg"           
            @onEvent="execAccion">             
          </baseBtraExtra>        
        </div>
      </div>

      <!-- Controles del Mto -->     
      <div style="display:flex">
        <v-select         
          style="width:33%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"               
          v-bind="$select"            
          v-model="ct.emp[2]"
          :label="ct.emp[1]"
          :items="itemsEmp"            
          item-value="id"
          item-text="name"
          :disabled="noEdit"
          @change="changeEmpresa">
        </v-select>

        <v-select       
          style="width:33%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"       
          v-bind="$select"
          v-model="ct.tip[2]"
          :label="ct.tip[1]"
          :items="itemsComunicados"       
          item-value="id"
          item-text="name"
          :disabled="noEdit"           
          @change="changeComunicado">
        </v-select>

        <v-select
          style="width:33%;white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;"   
          v-bind="$select"
          v-model="ct.adjuntos[2]"
          :label="ct.adjuntos[1]"            
          :items="itemsAdjuntos"          
          item-value="id"
          item-text="name"          
          multiple
          :disabled="noEdit">
        
            <template v-slot:selection="{ index }">              
              <span  
                v-if="index=== 0"              
                class="grey--text caption">                
                  <!-- ({{ ct.adjuntos[2].split(',').length }} documentos adjuntos) -->
                  ({{ ct.adjuntos[2].length }} documentos adjuntos)
              </span>
            </template>
        </v-select>
      </div>

      <div style="display:flex">
        <uiText          
          v-bind="$input"
          v-model="ct.ate[2]"
          :label="ct.ate[1]"
          :disabled="noEdit">
        </uiText>

        <uiText          
          v-model="ct.sref[2]"
          :label="ct.sref[1]"
          :disabled="noEdit">
        </uiText>
      </div>

      <div style="display:flex">
        <uiText          
          v-bind="$input"
          v-model="ct.de[2]"
          :label="ct.de[1]"
          :disabled="noEdit">
        </uiText>

        <uiText          
          v-model="ct.nref[2]"
          :label="ct.nref[1]"
          :disabled="noEdit">
        </uiText>
      </div>

      <div style="display:flex">
        <uiText
          style="width:20%"
          v-model="ct.tlf[2]"
          :label="ct.tlf[1]"
          :disabled="noEdit">
        </uiText>

        <uiText
          style="width:20%"
          v-model="ct.fax[2]"
          :label="ct.fax[1]"
          :disabled="noEdit">
        </uiText>

        <uiText
          style="width:60%"
          v-model="ct.ema[2]"
          :label="ct.ema[1]"
          :disabled="noEdit">
        </uiText>
      </div>
     
      <div v-show="estado== 'editar' || estado== 'nuevo'" class="document-editor">
        <div id="toolbar" class="document-editor__toolbar"></div>
        <div class="document-editor__editable-container">
          <div class="document-editor__editable">
            <ckeditor toolbar-container="#toolbar" type="document" v-model="ct.txt[2]"></ckeditor>          
          </div>
        </div>
      </div>  
     
    </v-sheet>       
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js"; 
  import baseBtraMto from "@/base/baseBtraMto";  
  import baseBtraExtra from "@/base/baseBtraExtra";  
     
  export default {
    mixins: [mixM],
    components: { baseBtraMto, baseBtraExtra },
    props: {
      // Identifica la tabla asociada al documento
      // Descripcion de todas las tablas en la tabla documentos_tip
      tipoDocumento: { type: String, default: "0" },
    },

    data() {
      return {
        stIni: {
          api: "expComunicadosMX",
          name:"expComunicadosMX",          
          titulo:"",
          recordAccess:"local",
          mView:'expComunicadosMX',
          pView:[],
          relation:'tip_id'
        },  
         
        // 
        itemsEmp:[],
        itemsComunicados:[], 
        itemsAdjuntos:[], 
        selectedEmp:null,        
        selectedComunicado:null  
      }
    },
   

    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
        
        // defino botones extra de la cabecera
        this.btExtCfg= JSON.parse(JSON.stringify(this.$cfe.btra.EXTRA));     
        this.btExtCfg.btnsAccion= this.btExtCfg.btnsAccion.concat([
          { accion: "sendFax", btn: "sendFax", show: 1 },
          { accion: "sendEmail", btn: "sendEmail",  show: 1 }          
        ]);
    
        // array de Selección de Comunicados
        this.itemsEmp= JSON.parse(JSON.stringify(this.$store.state[this.masterStore].recordsRead[1]));

        // array de Tipos de Comunicados
        //this.itemsComunicado= JSON.parse(JSON.stringify(this.$store.state[this.padre].recordsDetalle[2]));
        this.itemsComunicados= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_comunicados));
   
        // array de Documentos del Expediente
        this.itemsAdjuntos= JSON.parse(JSON.stringify(this.$store.state[this.masterStore].recordsRead[2]));        
      },


      // actualizo apiArgs
      iniDataApi() {        
        this.apiArgs.set= ["mto", 'guardar', {}, this.stIni.api];
        this.apiArgs.del= ["mto", 'borrar', { id: 0, tip:0, tip_id:0 }, this.stIni.api];
        this.apiArgs.comunicado= ["", 'tipo', { tip:0, exp_id:0, emp:0 }, 'comunicado'];
      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));

        // deshabilito botón 'guardar'
        this.btnSet(this.btMtoCfg, 4,{ view:true, disabled:true });
      },


      //
      nuevoFin() {
        if (!this.ct.sref[2]) this.$set(this.ct.sref, 2, this.recordAux.callref1);
        if (!this.ct.nref[2]) this.$set(this.ct.nref, 2, this.recordAux.control);
        
      },


      // gancho final para todas las operaciones de CRUD
      crudFin() {
        this.setEmp(this.ct.emp[2]);
        this.setComunicado(this.ct.tip[2]);
      },


      // selecciono emp según el valor recibido
      setEmp(emp) {
        this.selectedEmp= JSON.parse(JSON.stringify(this.itemsEmp)).filter(elem=> elem.id==emp);
      },


      // filtro comunicado según el tipo
      setComunicado(tipo) {
        this.selectedComunicado= JSON.parse(JSON.stringify(this.itemsComunicados)).filter(elem=> elem.id== tipo);
      },


      //
      changeEmpresa(emp) {    
        // obtengo objeto seleccionado en el select de Comunicado
        this.setEmp(emp);
        
        // filtro comunicados según la empresa indicada           
        let itemsComunicados= JSON.parse(JSON.stringify(this.$store.state.datos_iniciales.tipo_comunicados));
        this.itemsComunicados= this.selectedEmp[0].c== "0"? itemsComunicados : (itemsComunicados.filter(elem=> elem.alias== this.selectedEmp[0].c || elem.alias==""));
 
        // asigno a ctrls datos de la empresa seleccionada
        this.$set(this.ct.ate, 2, this.selectedEmp[0].x);
        this.$set(this.ct.tlf, 2, this.selectedEmp[0].t);
        this.$set(this.ct.fax, 2, this.selectedEmp[0].f);
        this.$set(this.ct.ema, 2, this.selectedEmp[0].e1);
        if (!this.ct.sref[2]) this.$set(this.ct.sref, 2, this.recordAux.callref1);
        if (!this.ct.nref[2]) this.$set(this.ct.nref, 2, this.recordAux.control);

        // cambio el tipo de Comunicado al primer elemento
        this.changeComunicado(itemsComunicados[0].id);
      },


      // 
      async changeComunicado(tipo) {
        
        // obtengo objeto seleccionado en el select de Tipo Comunicado
        this.setComunicado(tipo);

        console.log('tipo comunicado: ', tipo);
        console.log('select comunicados: ', this.selectedComunicado);
        console.log('items comunicados: ', this.itemsComunicados);

        // obtengo datos del comunidado recibido 
        // llamada API
        let param= { apiArg:this.apiArgs.comunicado, args:{ 
          tip:tipo, 
          exp_id: this.recordAux? this.recordAux.id : this.masterSyncRecord.exp, 
          emp:this.itemsEmp[this.ct.emp[2]].name } 
        };

        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult Comunicado: ', apiResult);

        // Pendiente:controlar error
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       
        // actualizo datos del schema según los datos recibidos
        this.$set(this.ct.tip, 2, tipo);
        this.$set(this.ct.txt, 2, apiResult.r[0].txt);
        if (apiResult.r[0].ema>'') {
          this.$set(this.ct.ema, 2, apiResult.r[0].ema);

        }else {
          this.$set(this.ct.ema, 2, this.selectedEmp[0]["e" + apiResult.r[0].tpe]);
        }
        
      },


      // envio comunicado como fax
      sendFax() {
        if (!this.ct.fax[2]) {
          this.$root.$alert.open('Debe introducir un Nº de Fax', 'info');
          return;
        }

        this.sendComunicado(1);
      },


      // envio comunicado como email
      sendEmail() {
        if (!this.ct.ema[2]) {
          this.$root.$alert.open('Debe introducir una dirección de Email', 'info');
          return;
        }

        this.sendComunicado(2);
      },


      // envio comunicado como FAX (1) o como EMAIL (2)
      async sendComunicado(envio) {   
        // obtengo record con los campos del comunicado        
        let record= this.getRecordComunicado(envio);        

        // llamada a la API   
        let param= { apiArg:this.apiArgs.set, args:record };
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult sendComunicado: ', apiResult);
       
        if (apiResult.sql.error) {          
          this.$root.$alert.open(apiResult.sql.msg, "error");
          return false;
        }

        this.$root.$alert.open("Comunicado enviado!", "success", 1800);

        // actualizo record del master asociado si lo tuviera
        await this.masterStoreUpdate('refresh');        
      },


      // monto record con los campos del comunicado y lo devuelvo
      getRecordComunicado(envio) {
        // obtengo record con todos los controles del ct
        let record= this.ctrl2record(this.ct, {}, false);
        
        // añado datos al record
        record['exp_id']= this.recordAux? this.recordAux.id : this.masterSyncRecord.exp;
        if (Object.keys(this.selectedEmp).length && (this.selectedEmp[0].c== 'CIA' || this.selectedEmp[0].c== 'CTE')) {
          record['asu']= "Ref. " + this.recordAux.callref1;

        }else {
          record['asu']= !Object.keys(this.selectedComunicado).length? "" : (!this.selectedComunicado[0].asu? this.selectedComunicado[0].name : this.selectedComunicado[0].asu);
        }
        
        record['emp']= Object.keys(this.selectedEmp).length? this.selectedEmp[0].name : "";
        record['env']= envio;        
        return record;
      },


      // convierto string de índices de documentos seleccionados en array
      select_adjuntos({ ctrl, record }) {        
        if (typeof record[ctrl[0]]=== "undefined" || !record[ctrl[0]]) return [];
        if (record[ctrl[0]].indexOf('.')>= 0) return [];
        
        this.$set(ctrl, 2, record[ctrl[0]].split(','));
      }     
      
     
    },


    computed: {

      relationID() {
        if (!this.recordAux || !Object.keys(this.recordAux).length ) return 0;
        return this.recordAux.id;
      },

    }

};
</script>



<style>
  .document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);

    /* Set vertical boundaries for the document editor. */
    max-height: 700px;

    /* This element is a flex container for easier rendering. */
    display: flex;
    flex-flow: column nowrap;
  }

  .document-editor__toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;

    width:800px;

    /* Create the illusion of the toolbar floating over the editable. */
    box-shadow: 0 0 5px hsla( 0,0%,0%,.2 );

    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);
  }

  /* Adjust the look of the toolbar inside the container. */
  .document-editor__toolbar .ck-toolbar {
      border: 0;
      border-radius: 0;
  }

  .document-editor__editable-container {
      padding: calc( 2 * var(--ck-spacing-large) );
      background: var(--ck-color-base-foreground);

      /* Make it possible to scroll the "page" of the edited content. */
      overflow-y: scroll;
  }

  .document-editor__editable-container .ck-editor__editable {
      /* Set the dimensions of the "page". */
      width: 20.8cm;
      min-height: 21cm;

      /* Keep the "page" off the boundaries of the container. */
      padding: 1cm 2cm 2cm;

      border: 1px hsl( 0,0%,82.7% ) solid;
      border-radius: var(--ck-border-radius);
      background: white;

      /* The "page" should cast a slight shadow (3D illusion). */
      box-shadow: 0 0 5px hsla( 0,0%,0%,.1 );

      /* Center the "page". */
      margin: 0 auto;
  }

  .document-editor .ck-content,
  .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
      font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .document-editor .ck-heading-dropdown .ck-list .ck-button__label {
      line-height: calc( 1.7 * var(--ck-line-height-base) * var(--ck-font-size-base) );
      min-width: 6em;
  }

  /* Scale down all heading previews because they are way too big to be presented in the UI.
  Preserve the relative scale, though. */
  .document-editor .ck-heading-dropdown .ck-list .ck-button:not(.ck-heading_paragraph) .ck-button__label {
      transform: scale(0.8);
      transform-origin: left;
  }

  /* Set the styles for "Heading 1". */
  .document-editor .ck-content h2,
  .document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
      font-size: 2.18em;
      font-weight: normal;
  }

  .document-editor .ck-content h2 {
      line-height: 1.37em;
      padding-top: .342em;
      margin-bottom: .142em;
  }

  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3,
  .document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
      font-size: 1.75em;
      font-weight: normal;
      color: hsl( 203, 100%, 50% );
  }

  .document-editor .ck-heading-dropdown .ck-heading_heading2.ck-on .ck-button__label {
      color: var(--ck-color-list-button-on-text);
  }

  /* Set the styles for "Heading 2". */
  .document-editor .ck-content h3 {
      line-height: 1.86em;
      padding-top: .171em;
      margin-bottom: .357em;
  }

  /* Set the styles for "Heading 3". */
  .document-editor .ck-content h4,
  .document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
      font-size: 1.31em;
      font-weight: bold;
  }

  .document-editor .ck-content h4 {
      line-height: 1.24em;
      padding-top: .286em;
      margin-bottom: .952em;
  }

  /* Set the styles for "Paragraph". */
  .document-editor .ck-content p {
      font-size: 1em;
      line-height: 1.63em;
      padding-top: .5em;
      margin-bottom: 1.13em;
  }
</style>
