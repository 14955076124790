<!-- APDS - Contrato de Servicios -->
<template>
  <div class="apdsContrato">
    <v-dialog
      content-class="modal"
      v-model="modal"
      persistent>
      
        <div>

          <v-sheet :elevation="4">
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='titulo'                
                @onEvent="$emit('onEvent', { accion:6 })">
              </baseHeader>
            </div>  
            
            <v-sheet :elevation="4">              
              <div class="contenedor px-2" style="max-width:1150px;">
                <div class="columna px-2">          
            
                  <div class= "row fila">                
                      <div class="col-md-12 text">
                          Estimado colaborador:<br>
                          Debido a la adecuación de las exigencias normativas que resultan de aplicación a esta relación contractual, le ponemos a disposición por medio del siguiente proceso, 
                          que <b>A.F.S., S.A.</b> y <b>{{ APD.name }}</b>, con NIF <b>{{ APD.nif }}</b>, formalizan el presente CONTRATO de prestación de servicios. <br>
                          Este documento se compone de:
                          <ul>
                              <li>Contrato de 8 páginas</li>    
                              <li>Anexo I: Tarifa</li>    
                              <li>Anexo II: Declaración Jurada de estar al corriente en el cumplimiento de las obligaciones tributarias con la Administración Pública y con la Seguridad Social.</li>    
                              <li>Anexo III: Acuerdo para el tratamiento de datos profesionales y de carácter profesional compuesto por 24 páginas, incluidos los subanexos I, II y III.</li>    
                          </ul>
                          Este contrato puede revisarlo desde el siguiente enlace: 
                          
                          <v-btn rounded @click="borrador">Borrador</v-btn> 

                          <span class="t9">
                              * Debe habilitar en su navegador que permita mostrar ventanas emergentes o popups, deberá permitirlo siempre para esta página.
                          </span>                    
                          <p class="mt-3 negrita">Y en prueba de conformidad, ambas partes firman el presente CONTRATO por duplicado, el cual será enviado por correo electrónico.</p>
                      </div>               
                  </div>            

                  <firma_canvas tip="2" :exp="0" :apd="apd" @onEvent="$emit('onEvent', { accion: 'evtFirma' })" />

                </div>
              </div>
            </v-sheet>
          </v-sheet>
        </div>
    </v-dialog>
  </div>
</template>



<script>

  import baseHeader from "@/base/baseHeader";
  import firma_canvas from "@/components/firma_canvas";  
    
  export default {
    components: { baseHeader, firma_canvas },
    props: {    
      apd: { type:String, default: '0'}, 
      masterStore: { type: String, default: '' },
      masterRecordField: { type: String, default: 'id' },     
    },

    data() {
      return {
        titulo:'',
        modal:true,
        apiArgs:{}       
      };
    },

    

    created() {
      this.iniDataParticular();
      this.iniDataApi();
    },


    methods: {

      iniDataParticular() {
        console.log("DEV apdMContrato ********************** (iniDataParticular)");

        // título ventana
        this.titulo = "APD - CONTRATO PRESTACIÓN DE SERVICIOS";
      },


      // defino todas las API calls
      iniDataApi() {
        this.apiArgs= {
          borrador: ['', 'contratoPDF', { tpd: 1, apd:0 }, "apds"],
        }
      },


      // muestra el borrador del contrato con el APD
      async borrador() {

        // llamada API
        let param= { apiArg:this.apiArgs.borrador, args:{ apd:this.apd }};      
        let apiResult = await this.$store.dispatch("apiCall", param);
        console.log('apiResult borrador: ', apiResult);

        // 
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }      
        
        window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank");              
      },

    },



    computed: {

      // devuelvo el valor del campo masterRecordField del record sincronizado      
      masterField() {      
        if (!this.masterStore) return null;
        if (!this.masterSyncRecord) return null;
        if (typeof this.masterSyncRecord[this.masterRecordField] === 'undefined') return 0;
        return this.masterSyncRecord[this.masterRecordField];
      },

      // records seleccionados del componente maestro asociado
      masterSyncRecord() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      },

        
      APD() {
          return this.masterSyncRecord;
      },

      mapfre() {
          return true; //(this.record.callemp=='83');
      },

    },

  };
</script>


<style scoped>

/* @font-face {
	font-family: 'Century Gothic';
	src: url('fonts/century_gothic.eot');
	src: url('fonts/century_gothic.eot') format('embedded-opentype'), url('fonts/century_gothic.ttf') format('truetype');
}
*/

body {
	background: #FFFFFF;
	color: #000000;
	font-family:'Century Gothic',Geneva, Arial, Helvetica, sans-serif;
}
p {
	text-align: justify;
	font-size: 13px;
}
li {
	text-align: left;
	font-size: 13px;
}
h4 {
	text-align: left;
	font-size: 15px;
	font-weight: bold;
	text-decoration: underline;
}
h5 {
	text-align: left;
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
}
input[type="checkbox"][readonly] {
  pointer-events: none;
}
.container {
  overflow: hidden;
}
/*.input.checkbox {
  disabled: true;
}*/

.img_fondo {
  position: absolute;
  opacity: 0.3;
  height: 700px;
  top: 22%;
  left: 60%;
}

.t9{ font-size: 10px; }
.t10{ font-size: 11px; }
.t11{ font-size: 11px; }
.t12{ font-size: 12px; }
.t13{ font-size: 14px; }
.t14{ font-size: 14px; }
.t15{ font-size: 15px; }
.t16{ font-size: 16px; }
.t17{ font-size: 17px; }
.t18{ font-size: 18px; }

.verde{ color: #1b6018; }
.negrita{ font-weight: bold; }
.centrado {text-align: center; }

.opcion {
	font-size: 15px;
	font-weight: bold;
	color: #1b6018;
}
.text {
	font-size: 14px;
	color: #1b6018;
}
.dato {
	font-size: 16px;
	font-weight: bold;
	color: #000000;
}
.dato2 {
	font-size: 14px;
	font-weight: bold;
	color: #000000;
}

.img_cabecera {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.txt_cabecera {
  border: 2px solid #1b6018;
  margin-top: 0.5em;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #1b6018;
}
.p1 {
	line-height: 60px;
}

.input { background-color: rgba(0, 0, 0, 0); border:none; }

.fila{
  border: 1px solid #1b6018;
  border-left: 2px solid #1b6018;
  border-right: 2px solid #1b6018;
}

.primera_col {
  /* padding-bottom: 220px; */
  /* margin-bottom: -220px; */

  border-right: 2px solid #1b6018;
  text-align: center;
  font-size: 15px;
  color: #1b6018;
}

.primera_fila {
  border-top: 2px solid #1b6018;
}

.ultima_fila {
  border-top: 1px solid #1b6018;
  border-right: 2px solid #1b6018;
  height: 300px;
}


.tanatorio {
  border-left: 2px solid #1b6018;
}

.traslado {
  height: 100px;
  border-left: 2px solid #1b6018;
}

.fondo_input {
  background-color: #ffff00;
  background-color: rgba(255,255,0,0.2);
}
.inputs {
  border: none;
  background: none;
  width: 100%;
}

#cuadro_firma{
	display: none;
    position: absolute;
    top: 90px;
    left: 30px;
    right: 0px;
    bottom: 46px;
    overflow:hidden;
    /*background-color:#ddd;*/
	width: 800px;
	height: 400px;
	border: 3px solid #1b6018;
}
#firma{
    cursor:crosshair ;
    background-color:#eee;
}

</style>