<!-- Finder de Suplidos -->

<template>
  <div class="expMPresSup" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">
          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='stIni.titulo'                
                @onEvent="salir">
              </baseHeader>
            </div>

            <v-sheet :elevation="4">        
              <div class="contenedor" style="width:900px">           
                <div class="columna">

                  <!-- Contenido -->
                  <baseGridMD
                    :store="storeName"
                    :headers="headerGrid"
                    :items-per-page="-1"
                    :expansible="`${storeName}${stIni.mView}`"
                    fixed-header                                    
                    dense           
                    @onEvent="execAccion">

                      <!-- Slot botonera Top -->
                      <template v-slot:gridTop="{}">                
                        <baseBtraMto
                          :perm="permMto"
                          :modulo="btGridCfg"
                          @onEvent="execAccion">
                        </baseBtraMto>
                      </template>
                      

                      <!-- Slot panel expansible -->
                      <template v-slot:expansibleMX="{}">                        
                        <suplidosMX 
                          sync
                          syncUpdate
                          :syncAccion="syncAccionSend"
                          :storeRaiz="storeName"
                          :masterStore="storeName"
                          :readOnly="readOnly"
                          :viewMtoOnly="viewMtoOnly"
                          :recordAux='masterSyncRecord'>                          
                        </suplidosMX>
                      </template>     
                  </baseGridMD> 
                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import dualTemplate from "@/components/dualTemplate";  
  import suplidosMX from "@/components/suplidosMX";
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, dualTemplate, suplidosMX },
    props: {},

    data() {
      return { 
        stIni: {
          api: "suplidosF",
          titulo:"", 
          name:"suplidosF",
          mView:'suplidosMX',
          pView:[],
          expansibleMX:'suplidosMX'
        },

      };
    },

    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[
            { text: "Factura", value: "fra_doc" },
            { text: "Fecha", value: "fra_fh", filtro:"fecha" },
            { text: "Cuenta", value: "name_cta_id", width:'50%' },                    
            { text: "Importe", value: "tot", filtro:'num', arg:2 }           
          ]}
        ];

        this.headerGrid= this.headerSet();

        // título de la cabecera
        this.stIni.titulo= `${this.recordAux.control}  -  Suplidos. Fra: ${this.masterSyncRecord.fra_doc} - ${this.masterSyncRecord.name_cta_id}`;

        //si expediente cerrado no nuevo        
        this.btnSet(this.btGridCfg, 1,{ disabled: this.recordAux.chkconfadmon=='1' });

        //
        this.autoLoad="buscar";
      },


      //
      iniDataApi() {
        this.apiArgs.sch= null;
      },


      // actualiza el maestro asociado y  cierra ventana
      salir(evt) {
        this.masterStoreUpdate('refresh');
        this.eventHeader(evt);
      },
    
    }
  };
</script>
