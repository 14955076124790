
export var mixOperacion = {  
    
  data() {
    return {    
      // objeto operación inicial     
      defaultOperacion: {
        accion:'',          // acción de origen
        op:'',              // COMPRA, VENTA
        suplido:0,          // SUPLIDO 1/0      
        fusionRecords:[],   // array de records de presupuestos seleccionados (tratar suplidos en caso de compra a venta o suplido)        
        cuentaId: 0,        // funeraria1, funeraria2, compañia, nada. Se selecciona en el presupuesto cuando se cree
        cuentaTipo: 0,      // funeraria, compañia, seleccionable
        iva:0               // ver como meter?
      },

      // objeto que pasamos como propiedad desde un F
      newOperacion: {},

      // acciones predeterminadas de nuevas operaciones
      acciones: {
        compra: { op:'C', accion:'compra' },
        compraS: { op:'C', suplido:1, accion:'compraS' },
        venta: { op:'V', accion:'venta' },        
        fusionC: { op:'C', fusion:true, accion:'fusionC' },
        fusionV: { op:'V', fusion:true, accion:'fusionV' },
        fusionS: { op:'C', suplido:1, fusion:true, accion:'fusionS' },        
        compraFune1: { op:'C', funeN:1, accion:'compraFune' },
        compraFune2: { op:'C', funeN:2, accion:'compraFune' },
      }
      
    };
  },
   
  

/*   rTribut= [    
    {
      id:"0",
      name:"IVA",
      tipos:[
        {
          text:"21%",
          value:"21.0"
        }
      ]
    }
  ] */
    




  //
  methods: {  

    // crea objeto operacion
    operar({ accion, cuentaId, cuentaTipo, iva, fusionRecords }) {
      console.log('Nueva operacion: ', accion, cuentaId, cuentaTipo, iva, fusionRecords);

      // inicializo objeto operación con la configuración de la acción recibida
      let operacion= JSON.parse(JSON.stringify(this.defaultOperacion));
     
      // completo datos de operacion
      operacion= { 
        ...operacion, 
        ...this.acciones[accion], 
        ...{ cuentaId, cuentaTipo, iva, fusionRecords }
      };

      return operacion;
    },


    // recolección de datos para una nueva operación desde un FINDER
    async operacionFromFinder(accion) {                  
      // datos de la cuenta      
      let cuentaTipo= 0;
      let cuentaId= 0;
      let itemAccion= this.acciones[accion];

      switch (itemAccion.accion) {
      case 'venta':
      case 'fusionV':        
        cuentaTipo= this.expediente && this.expediente.callemp_tp? this.expediente.callemp_tp : 0;
        cuentaId= this.expediente && this.expediente.callemp? this.expediente.callemp : 0;
        break;

      case 'compraFune':
        cuentaTipo= '1';
        cuentaId= (this.expediente && this.expediente["fune" + itemAccion.funeN])? this.expediente["fune" + itemAccion.funeN] : 0   
        
        // valido si hay funeraria
        if (itemAccion.funeN> 0 && (this.expediente && Number(this.expediente["fune" + itemAccion.funeN])==0)) {
          this.$root.$alert.open("No hay funeraria: " + itemAccion.funeN, "error");
          return false;
        }
        break;

      case 'compra':
      case 'fusionC':
        cuentaTipo= '1';
        break;
      case 'compraS':      
      case 'fusionS':
        cuentaTipo= '3'; 
        break;
      }

      
      // array de lineas MD
      let lins= [];
     
      // valido presupuestos si es una fusión y obtenemos lineas MD          
      if (this.acciones[accion].fusion) {
        // obtengo los presupuestos seleccionados en el FINDER     
        // fuerzo a que syncRecord sea siempre un array, ya que
        // si solo hay seleccionada una linea me devuelve un objeto
        let recordsPrestos= JSON.parse(JSON.stringify(Array.isArray(this.syncRecord)? this.syncRecord : [this.syncRecord]));
     
        // compruebo que los presupuestos sean correctos
        if (!this.validatePresupuestos(accion, recordsPrestos)) return false;
     
        // obtengo lineas MD según los presupuestos seleccionados
        lins= await this.getLineasFusion(recordsPrestos);
        if (!lins.length) return false;    
      }      

      // monto objeto operación y lo devuelvo
      return this.operar({ accion, cuentaId:cuentaId, cuentaTipo:cuentaTipo, fusionRecords:lins });      
    },


    // recolección de datos para una nueva operación desde un MD
    operacionFromMD(accion) {
      // datos de la cuenta
      let cuentaTipo= 0;
      let cuentaId= 0;
      let itemAccion= this.acciones[accion];

      switch (itemAccion.accion) {
      case 'venta':
      case 'fusionV':
        cuentaTipo= this.expediente && this.expediente.callemp_tp? this.expediente.callemp_tp : 0;
        cuentaId= this.expediente && this.expediente.callemp? this.expediente.callemp : 0;        
        break;

      case 'compraFune':
        cuentaTipo= '1';
        cuentaId= (this.expediente && this.expediente["fune" + itemAccion.funeN])? this.expediente["fune" + itemAccion.funeN] : 0;

        // valido si hay funeraria
        if (itemAccion.funeN> 0 && (this.expediente && Number(this.expediente["fune" + itemAccion.funeN])==0)) {
          this.$root.$alert.open("No hay funeraria: " + itemAccion.funeN, "error");
          return false;
        }
        break;

      case 'compra':
      case 'compraS':
      case 'fusionC':
      case 'fusionS':
        cuentaTipo= '3';
        break;
      }
            
      // obtengo las lineas del detalle del MD      
      let fusionRecords= JSON.parse(JSON.stringify(this.records1));
            
      // monto objeto operación y lo devuelvo
      return this.operar({ accion, cuentaId:cuentaId, cuentaTipo:cuentaTipo, fusionRecords });      
    },

   
    //
    async getLineasFusion(records) {
      if (!records.length) {
        this.$root.$alert.open("No ha seleccionado ninguna factura para fusionar", "error");
        return [];
      }
      
      // obtengo array con los id's de las lineas
      let lins= records.map(item=>item.id);
      
      // API call
      let param= { apiArg:this.apiArgs.fusion, args:{ id:lins }};      
      let apiResult = await this.$store.dispatch("apiCall", param);
      console.log("fusion apiResult:",apiResult);
      
      //
      if (apiResult.sql.error) {
        this.$root.$alert.open("Error al fusionar lineas", "error");
        return [];
      }
      
      // guardo lineas fusionadas
      lins= apiResult.r[0];
    
      if (!Array.isArray(lins)) lins=[];
      return lins;
    },


    //
    validatePresupuestos(accion, records) {

      switch (accion) {        
        case 'fusionC':
          if (records.filter(item=>item.tipo_ope!="C" || (item.tipo_ope=="C" && item.aceptada==2)).length>0){
            this.$root.$alert.open("Ha seleccionado algún presupuesto no aceptable", "error");
            return false;
          }
          break;

        case 'fusionV':
          if (records.filter(item=>item.tipo_ope!="C" || (item.tipo_ope=="C" && item.aceptada==2)).length> 0) {
            this.$root.$alert.open("Ha seleccionado algún presupuesto no aceptable", "error");
            return false;
          }
          break;    
        
        case 'fusionS':
          if (records.filter(item=>item.tipo_ope!="C" || item.suplido!=1).length>0){
            this.$root.$alert.open("Ha seleccionado algun presupuesto que no es suplido", "error");
            return false;
          }
          break;    
      }

      return true;
    }

  },


}
